import React, {useState} from 'react';// eslint-disable-line @typescript-eslint/no-unused-vars
import {useDispatch, useSelector} from 'react-redux';
import Entry from './Entry';
import {MESSAGE_IFRAME_INITIALIZE, MESSAGE_SET_HEIGHT} from '@Consts/message';
import pubsub from '@Utils/pubsub';
import {getFacility, getFacilityStatus} from '@Features/facility/facilitySelectors';
import locale from '@Utils/locale';
import {EVENT_INVALID_SCRIPT_ERROR} from '@Utils/events';
import {FAILURE, IN_PROGRESS, SUCCESS} from '@Consts/status';
import {isFailure, isSuccess} from '@Utils/status';
import {
  getFacilityId,
  getParentUrl,
  getScriptInitialized,
  getIsModal,
  getOnlineGroupId
} from '@Features/configuration/configurationSelectors';
import {fetchFacility} from '@Features/facility/facilityActions';
import {prepareScriptData} from '@Features/configuration/configurationActions';
import PageError from '@Components/error/PageError';
import {setStorageItemsToState} from '@Features/storage/storageActions';
import {LanguageContext} from '@Utils/LanguageProvider';
import EntrySkeleton from './EntrySkeleton';
import ResponseError from '@Utils/api/errors/responseError';
import {getDocumentReffererQueryStringParameters, getParam} from '@Utils/query';
import {SCRIPT_CHECKING_INTERVAL, SCRIPT_MAX_CHECKING_TIME} from '@Consts/timeDurations';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import dayjs from 'dayjs';
import {useAmplitude} from '@Hooks/useAmplitude';
import useFrameMessage from '@Hooks/useFrameMessage';

const queryClient = new QueryClient();

const App = () => {
  const rootRef = React.useRef(null);
  const dispatch = useDispatch();
  const status = useSelector(getFacilityStatus);
  const facility = useSelector(getFacility);
  const facilityId = useSelector(getFacilityId);
  const parentUrl = useSelector(getParentUrl);
  const scriptInitialized = useSelector(getScriptInitialized);
  const onlineGroupId = useSelector(getOnlineGroupId);
  const isModal = useSelector(getIsModal);
  const {language, isFallbackLanguage, validateLanguage, handleFallbackStatus} = React.useContext(LanguageContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(status);
  const {handleAmplitudeInitialize} = useAmplitude();
  const frameMessage = useFrameMessage();

  function handleScriptChecker() {
    if (scriptInitialized) {
      return null;
    }
    const intervalId = setInterval(() => {
      frameMessage({name: 'iframeRequestScript'});
    }, SCRIPT_CHECKING_INTERVAL);
    const timerId = setTimeout(() => {
      setLoadingStatus(FAILURE);
      setErrorMessage(locale.translate('errorScriptNotFound'));
      pubsub.trigger(EVENT_INVALID_SCRIPT_ERROR, {
        facilityId,
        facilityName: facility?.name,
        url: parentUrl
      });
      clearInterval(intervalId);
    }, SCRIPT_MAX_CHECKING_TIME);

    return () => {
      clearTimeout(timerId);
      clearInterval(intervalId);
    };
  }

  async function handleFetchFacility() {
    if (!facilityId) {
      return null;
    }

    setLoadingStatus(IN_PROGRESS);

    try {
      const facility = await dispatch(fetchFacility());

      if (facility) {
        validateLanguage(facility);
      }

      setLoadingStatus(SUCCESS);
    } catch (error) {
      setLoadingStatus(FAILURE);
      if (error instanceof ResponseError) {
        setErrorMessage(error.message);
      }
    }
  }

  function handleMessageReceive(event) {
    const eventData = event.data;

    if (eventData?.name === 'scriptInitialized') {
      const {parentUrl, storage, nsStorage, cookieConsents} = eventData;

      handleAmplitudeInitialize(cookieConsents);

      frameMessage({name: MESSAGE_IFRAME_INITIALIZE});

      const urlAffiliationHash = getParam('dah', getDocumentReffererQueryStringParameters(parentUrl));
      const daid = getParam('daid', getDocumentReffererQueryStringParameters(parentUrl));
      const indicatedActivityId = daid ? parseInt(daid, 10) : null;

      dispatch(prepareScriptData({eventData, urlAffiliationHash, indicatedActivityId, onlineGroupId}));
      dispatch(setStorageItemsToState(storage, nsStorage));
    }
  }

  function getWidgetHeight() {
    return rootRef?.current?.offsetHeight || 0;
  }

  function updateWidgetHeight() {
    frameMessage({name: MESSAGE_SET_HEIGHT, height: getWidgetHeight()});
  }

  function appInitializationEffect() {
    updateWidgetHeight();
    window.addEventListener('resize', updateWidgetHeight);
    window.addEventListener('message', handleMessageReceive);

    return function() {
      window.removeEventListener('resize', updateWidgetHeight);
      window.removeEventListener('message', handleMessageReceive);
    };
  }

  function domObserverInitializationEffect() {
    const config = {childList: true, subtree: true};
    const mutationCallback = function(mutationsList) {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          setTimeout(updateWidgetHeight, 30);
        }
      }
    };
    const observer = new MutationObserver(mutationCallback);

    observer.observe(rootRef.current, config);

    return function() {
      observer.disconnect();
    };
  }

  React.useEffect(appInitializationEffect, []);
  React.useEffect(domObserverInitializationEffect, []);
  React.useEffect(() => {
    if (language && !isFallbackLanguage) {
      handleFetchFacility();
      dayjs.locale(language);
    }

    // fallback status is set for prevent fetch facility if api return facility
    // for unavailable language
    if (language && isFallbackLanguage) {
      handleFallbackStatus(false);
    }
  }, [language]);
  React.useEffect(handleScriptChecker, [scriptInitialized]);

  function handleRenderContent() {
    if (!facilityId) {
      return <PageError message={locale.translate('missingFacilityId')} />;
    }

    if (facility && language && scriptInitialized && isSuccess(status)) {
      return (
        <QueryClientProvider client={queryClient} >
          <Entry facility={facility} />
        </QueryClientProvider>
      );
    }

    if (isFailure(loadingStatus)) {
      return <PageError message={errorMessage} />;
    }

    return <EntrySkeleton />;
  }

  const modalSummaryStyles = isModal ? {background: '#f9f9fb', height: '100%'} : {};

  return (
    <div ref={rootRef} style={{minHeight: 150, ...modalSummaryStyles}}>
      {handleRenderContent()}
    </div>
  );
};

export default App;
