import {Action} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {IStore} from 'src/js/store/types';
import {removeStorage} from '@Features/storage/storageActions';
import {invoiceFormFieldsNames} from '@Consts/storageConsts';

export const clearInvoiceData = () => async (dispatch: ThunkDispatch<IStore, void, Action>) => {
  invoiceFormFieldsNames.forEach(fieldName => {
    dispatch(removeStorage(fieldName));
  });
};
