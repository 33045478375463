import React from 'react';
import locale from '@Utils/locale';
import Box from '@mui/material/Box';
import {TextAvailableSeats, TextPrice, TextSoldOut, TimeText} from './TimePickerComponents';
import {getFormattedDate} from '@Utils/dayjs/dayjsUtils';
import {COLON_HOUR_FORMAT} from '@Utils/dayjs/const';
import {Dayjs} from 'dayjs';
import {getFormattedPrice} from '@Utils/format';
import {Option} from './types';

type Props = {
    dateWithTimezone: Dayjs;
    isSoldOut: boolean,
    showPrices: boolean,
    option: Option,
    canShowItemsLeft: boolean,
    isWithAllAdditionalInformation: boolean
}

const TimePickerCellDetails = ({
  dateWithTimezone, isSoldOut, showPrices, option, canShowItemsLeft, isWithAllAdditionalInformation}: Props) => {
  const {
    currency,
    isOutOfLimit,
    totalPrice,
    numberOfSeats,
    isBargainPrice
  } = option;

  return (
    <Box>
      <TimeText
        isDisabled={isOutOfLimit}
        isPrices={showPrices}
      >
        {getFormattedDate(dateWithTimezone, COLON_HOUR_FORMAT)}
      </TimeText>
      {
        showPrices && !isSoldOut && (
          <TextPrice isBargainPrice={isBargainPrice} isDisabled={isOutOfLimit}>
            {getFormattedPrice(totalPrice)} {locale.translate(currency)}
          </TextPrice>
        )
      }
      {
        canShowItemsLeft && !isSoldOut && (
          <TextAvailableSeats isDisabled={isOutOfLimit}>
            {numberOfSeats} {locale.translate('seats')}
          </TextAvailableSeats>
        )
      }
      { isSoldOut && (
        <TextSoldOut isWithAllAdditionalInformation={isWithAllAdditionalInformation}>
          {locale.translate('isSoldOut')}
        </TextSoldOut>
      )}
    </Box>
  );
};

export default TimePickerCellDetails;
