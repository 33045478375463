/* eslint-disable max-len */

export default {
  'multidayRangeInvalid': 'Vybraný rozsah zahŕňa nedostupné výrazy',
  'PLN': 'PLN',
  'EUR': 'EUR',
  'PLNcalendar': 'zł',
  'EURcalendar': '€',
  'validPostalCodeLength': 'Zadajte správne poštové smerovacie číslo (np. 00-000)',
  'minLength': 'Minimálna dĺžka znaku je: %{length}',
  'maxLength': 'Maximálna dĺžka znaku je: %{length}',
  'parcelLockerSelected': 'Vybraný stroj na spracovanie balíkov:',
  'selectParcelLocker': 'Výber balíkomatu',
  'selectAnotherParcelLocker': 'Vyberte si iný stroj na balíky',
  'parcelLockerPointError': 'Nepodarilo sa získať správnu adresu balíkomatu',
  'enterDeliveryPhoneNumber': 'Zadajte telefónne číslo pre doručenie',
  'onlineMethodsDescription': 'V prípade ostatných služieb elektronické doručovanie.',
  'shippingMethodRequired': 'Predtým, ako pristúpite k platbe, musíte vybrať spôsob doručenia.',
  'chooseShippingMethod': 'Vyberte spôsob dopravy',
  'generateWithAmount': 'Generovať (%{amountToPay})',
  'additionsHeader': 'Doplnky: %{activityName}',
  'additionsLimitReached': 'Limit kvót bol dosiahnutý',
  'perPersonNumber': '%{number} os. x',
  'selectedDateIsNotAvailable': 'Deň %{date} nie je k dispozícii.',
  'errorOccurredWhileFetchingOrder': 'Vašu objednávku sa nám nepodarilo načítať. Ak ste zaň zaplatili, skontrolujte si, prosím, svoju poštovú schránku.',
  'priceTooHigh': 'Hodnota vybraných služieb je príliš vysoká. Nie je možné pristúpiť k platbe.',
  'invalidNip': 'Číslo DPH je nesprávne',
  'orderCreationError': 'Pri vytváraní objednávky došlo k chybe, skúste to prosím znova',
  'activitiesFetchError': 'Pri načítavaní služieb došlo k chybe',
  'chooseLimitReached': 'Dosiahnutý limit vybraných kusov',
  'localTours': 'Miestne výlety',
  'clauseAcceptanceRequired': 'Požadované prijatie doložky',
  'invalidPostalCode': 'Nesprávne poštové smerovacie číslo',
  'invalidNIPLength': 'Číslo TIN musí mať 10 číslic',
  'invalidPhone': 'Nesprávne telefónne číslo',
  'invalidEmail': 'Neplatná e-mailová adresa',
  'invalidFormatError': 'Neplatný formát',
  'toLongError': 'Maximálna dĺžka je 30 znakov',
  'toShortError': 'Minimálna dĺžka je 3 znaky',
  'invalidTypeError': 'Neplatná hodnota',
  'missingFieldError': 'Hodnota pre toto pole nebola získaná',
  'unknownError': 'Neznáma chyba',
  'errorScriptNotFound': 'Chyba - Váš prehliadač nie je podporovaný alebo obchod bol vložený nesprávne',
  'addressRequired': 'Požadované údaje o adrese',
  'regulationAcceptRequired': 'Vyžaduje sa súhlas s podmienkami služby a zásadami ochrany osobných údajov',
  'regulationsAcceptRequired': 'Vyžaduje sa súhlas s podmienkami služby a zásadami ochrany osobných údajov',
  'phoneRequired': 'Požadované telefónne číslo',
  'lastNameRequired': 'Požadované priezvisko',
  'firstNameRequired': 'Vyžaduje sa krstné meno',
  'emailRequired': 'Vyžaduje sa e-mailová adresa',
  'answerRequired': 'Po vyzvaní uveďte svoju odpoveď',
  'skippTheLine': 'Preskočte rad k pokladni',
  'downloadInPreviewIsDisabled': 'V režime náhľadu sa objednávka odošle do e-mailu, nie je možné ju získať priamo z tejto obrazovky.',
  'noValidDatesRange': 'Rozsah dátumov nie je k dispozícii',
  'chooseNumberOfDays': 'Vyberte počet dní',
  'day': 'deň',
  'perPersonAbbr': 'os.',
  'maxNumberOfParticipants': 'Maximálny počet účastníkov je %{number}',
  'minNumberOfParticipants': 'Minimálny počet účastníkov je %{number}',
  'enterNumberOfParticipants': 'Uveďte počet účastníkov',
  'numberOfParticipants': 'Počet účastníkov',
  'numberOfParticipantsBetween': 'Počet účastníkov: od %{min} do %{max}',
  'generate': 'Generovať',
  'seats': 'ks.',
  'attention': 'Pozor!',
  'forSinglePerson': 'na 1 os.',
  'chooseAdditions': 'Vyberte si doplnky',
  'pleaseChooseOneOption': 'Vyžaduje sa výber jednej z dostupných možností',
  'or': 'alebo',
  'addToAppleWallet': 'Pridať do Apple Wallet',
  'realiseOrder': 'Dokončenie objednávky',
  'generatingPdf': 'Prebiehajúca generácia PDF...',
  'transactionCompletedSuccessfully': 'Transakcia bola úspešne dokončená!',
  'immediatelyProceedThroughPayment': 'Po prijatí potvrdenia o platbe budú vstupenky nahrané nižšie a odoslané na adresu %p',
  'optionally': 'voliteľné',
  'shopOnline': 'Internetový obchod',
  'orderOnline': 'Objednajte si online',
  'discountsAreDisabledInPreview': 'Zľavové kódy sú v režime náhľadu vypnuté',
  'contactNumberToClient': 'Kontaktné číslo na osobu, ktorá bude službu poskytovať - zbiera sa len na účely poskytovania služby, napr. potreba kontaktovať v prípade núdze.',
  'numberOnlyForRealisation': 'Číslo len na poskytovanie služieb',
  'unsuccessfullyOrderedSending': 'Potvrdenie objednávky nebolo možné odoslať na adresu %{email}. Skúste to znova alebo napíšte na pomoc@droplabs.pl',
  'successfullyOrderedSending': 'Správne nariadené odoslanie na adresu %{email}',
  'requiredField': 'Povinné pole',
  'forSendingAcceptTos': 'Na účely prepravy zákazník akceptuje',
  'agreementFormWithClause': 'Formulár súhlasu a informačná doložka',
  'send': 'Odoslať',
  'downloadToPrint': 'Stiahnite si na vytlačenie',
  'clientEmail': 'E-mailová adresa zákazníka',
  'chooseSendingConfirmationMethod': 'Hotovo! Vyberte spôsob odoslania potvrdenia zákazníkovi',
  'orderPreviewInfo': 'Na e-mailovú adresu bola zaslaná vzorová objednávka: %p. Ak sa vám správa do niekoľkých minút nezobrazí, skontrolujte priečinok so spamom. Ak to nepomôže, zadajte do vyhľadávača svojho e-mailového programu "Droplabs".',
  'exceededTimeout': 'Prekročenie čakacej doby na platbu',
  'buyerWillSettleWithYou': 'Osoba, ktorá bude služby využívať %p, účty s vami pre nich.',
  'youCanPrintTickets': 'Po zakúpení si môžete ihneď vytlačiť vstupenky pripravené na cestu. Na vstupenkách nebude uvedená žiadna cena.',
  'onlinePaymentForOrder': 'Za objednávku zaplatíte online. Faktúru vystaví %p za vašu spoločnosť za určité obdobie, zvyčajne súhrnne za celý mesiac.',
  'youWillPayForOrderBasedOnInvoice': 'Za svoju objednávku zaplatíte prostredníctvom faktúry vystavenej %p pre vašu spoločnosť za určité časové obdobie, zvyčajne súhrnne za celý mesiac.',
  'wholeAmountWillBeChargedForUSed': 'Celá suma bude účtovaná %p len na použité vstupenky. %p môže uložiť príplatok, a to aj za nevyužité letenky.',
  'youCanPrintReservationConfirmation': 'Po vykonaní rezervácie si môžete hneď vytlačiť potvrdenie.',
  'paymentWillBeChargedOnSite': 'Celú sumu objednávky je potrebné uhradiť na mieste počas služby.',
  'missingFacilityId': 'Obchod bol vložený nesprávne',
  'cannotLoadFacility': 'Načítanie obchodu zlyhalo, skúste to prosím neskôr.',
  'add': 'Pridať',
  'choose': 'Vyberte',
  'choosing': 'Výber',
  'hide': 'Zrútenie',
  'expand': 'Rozšíriť',
  'menuDetails': 'Podrobnosti',
  'menuSummary': 'Zaplatiť',
  'menuSummaryThanks': 'Ďakujeme za odoslanie objednávky!',
  'noActivitiesInSales': 'Žiadne služby v predaji. Čoskoro vás pozývame!',
  'hours': 'hodinu',
  'minutes': 'min',
  'goToTheNextStep': 'Pokračujte',
  'sellerOfActivities': 'Predávajúci je %{name}.',
  'ifYouNeedHelpAssociatedWithOffer': 'Máte otázky týkajúce sa ponuky? Napíšte na %p.',
  'saleSupportedByDroplabs': 'Droplabs - Online obchod, predajný a distribučný systém pre služby, vstupenky, poukazy a permanentky.',
  'ifYouNeedTechnicalSupport': 'Potrebujete technickú pomoc? Napíšte na adresu %p',
  'activityAdmissionDateLabel': 'Termín realizácie: %{name}',
  'chooseDayOfUseActivities': 'Vyberte deň služby',
  'chooseTimeOfUseActivities': 'Vyberte si čas dodania služby',
  'chooseNarrowedTimeOfUseActivities': 'Vyberte zúženie času realizácie služby',
  'chooseTime': 'Vyberte čas',
  'chooseEmailForSendingTickets': 'Na akú adresu mám poslať potvrdenie o kúpe?',
  'email': 'E-mailová adresa',
  'name': 'Meno',
  'buyersName': 'Krstné meno kupujúceho',
  'surname': 'Priezvisko',
  'buyersSurname': 'Priezvisko kupujúceho',
  'goToThePayment': 'Prejdite na platbu',
  'orderFreeTickets': 'objednávam',
  'buyWithoutPayment': 'Nakupujem s povinnosťou platby',
  'businessTicketsHaveBeenSentIntoEmail':
    'Vstupenka bola odoslaná na e-mailovú adresu: %p |||| Vstupenky boli odoslané na e-mailovú adresu: %p',
  'checkSpamInCaseOfNotReceivingEmail':
    `Ak správu nevidíte do niekoľkých minút od uskutočnenia platby, skontrolujte priečinok so spamom.
    Ak to nepomôže, zadajte do vyhľadávača vášho e-mailového programu „Droplabs“.`,
  'buyAnotherActivities': 'Objednajte si ďalšie služby',
  'noFixedDatesForChosenActivities': 'Na vybrané vstupenky bohužiaľ nie sú dostupné žiadne termíny.',
  'forChosen': 'Odkazuje na:',
  'chooseFixedDateBeforePayment': 'Pred pokračovaním je potrebné vybrať dátum prijatia.',
  'chooseFixedDatetimeBeforePayment': 'Pred pokračovaním potrebné vybrať čas pre vybraný dátum.',
  'noFixedDatesForChosenPeriod': 'V tomto období nie sú dostupné žiadne termíny.',
  'noFixedDatesOnDay': 'V daný deň nie sú dostupné žiadne termíny %{date}.',
  'noFixedDatesBetweenDates': 'Nie sú dostupné žiadne dátumy v dňoch %{since} - %{until}.',
  'noFixedDatesBeforeDate': 'Predtým nie sú dostupné žiadne termíny %{date}.',
  'noFixedDatesAfterDate': 'Potom už nie sú žiadne voľné termíny %{date}.',
  'next': 'Ďalšie',
  'back': 'Návrat',
  'onWebsite': 'na webovej stránke www',
  'january': 'januára',
  'february': 'februára',
  'march': 'marca',
  'april': 'apríla',
  'may': 'Smieť',
  'june': 'Júna',
  'july': 'Júla',
  'august': 'Augusta',
  'september': 'Septembra',
  'october': 'Októbra',
  'november': 'Novembra',
  'december': 'December',
  'sundayShort': 'Nd',
  'sunday': 'Nedeľa',
  'mondayShort': 'Po',
  'monday': 'Pondelok',
  'mondayTwoPart': 'Po',
  'tuesdayTwoPart': 'Ut',
  'wednesdayTwoPart': 'St',
  'thursdayTwoPart': 'Št',
  'fridayTwoPart': 'Pi',
  'saturdayTwoPart': 'So',
  'sundayTwoPart': 'Ne',
  'tuesdayShort': 'Ut.',
  'tuesday': 'Utorok',
  'wednesdayShort': 'St',
  'wednesday': 'Streda',
  'thursdayShort': 'Štv',
  'thursday': 'Štvrtok',
  'fridayShort': 'Pia',
  'friday': 'Piatok',
  'saturdayShort': 'So',
  'saturday': 'Sobota',
  'canNotBuyMoreTickets': 'Nie je možné zakúpiť ďalšie vstupenky. Nie sú k dispozícii žiadne miesta',
  'willEndSoon': 'Čoskoro vyprší!',
  'itemsLeft': 'To zostalo %{limit}!',
  'willEndBefore': 'Ponuka do %{date}!',
  'soldOut': 'Vypredané!',
  'bookingRequired': 'Po zakúpení je potrebná rezervácia',
  'bookingAfterBuy': 'Po zakúpení dostanete %p na rezerváciu',
  'phone': 'telefónne číslo.',
  'website': 'webová adresa',
  'bookingEmail': 'emailová adresa',
  'more': 'viac',
  'less': 'menej',
  'from': 'Od',
  'durationUnlimitedSentence': 'Bez časového obmedzenia',
  'durationUnlimited': 'bez časového obmedzenia',
  'useNumber': 'Počet vchodov',
  'useDatetime': 'Implementácia',
  'betweenDates': 'zo %{since} na %{until}',
  'onDate': 'deň %{date}',
  'validBeforeDate': 'do %{date}',
  'validInDays': 'do %{number}',
  'today': 'dnes',
  'numberOfDays': 'deň |||| dní',
  'activeInDays': 'Dôležitosť: %{number} dní od aktivácie',
  'activationInDays': 'Aktivácia na mieste %{days} po zakúpení',
  'activationDates': 'Aktivácia na mieste %{dates}',
  'pickFixedDateInTheNextStep': 'V ďalšom kroku skontrolujte všetky dostupné termíny',
  'dateOfUse': 'Dátum implementácie',
  'withDiscountLabel': 'Máte zľavový kód?',
  'optional': 'voliteľné',
  'discountCode': 'Zľavový kód',
  'useDiscountCode': 'Použite kód',
  'discountUsed': 'Aktívny zľavový kód (%{code}):',
  'invalidDiscountCode': 'Poskytnutý kód (%{code}) neznižuje ceny vybraných služieb.',
  'discountCodeNotFound': 'Poskytnutý kód (%{code}) vypršala, je neplatná alebo už bola použitá.',
  'discountAdditionsNotIncluded': 'Extra nie sú zľavnené.',
  'notEligibleDiscountTickets': 'Zostávajúce vstupenky nepodliehajú tomuto kódu.',
  'close': 'Zavrieť',
  'tryAgain': 'skúste to znova',
  'phoneNumber': 'Telefónne číslo',
  'contactPhoneNumber': 'Kontaktné telefónne číslo',
  'clientPhoneNumber': 'Telefónne číslo kupujúceho',
  'restCountries': 'Ostatné krajiny',
  'sellerIs': 'Predávajúcim a',
  'administratorOfThePersonalData': 'Správcom vašich osobných údajov je',
  'sellerCopy':
    `Vaše údaje sa spracúvajú najmä na účely plnenia zmluvy, t. J. Aby ste si mohli kúpiť lístok, na základe článku 6 ods. 1 písm. B) VNOÚ.
    Máte právo na prístup k obsahu svojich údajov a právo na opravu, vymazanie, obmedzenie spracovania, právo na prenosnosť údajov, právo namietať a podať sťažnosť dozornému orgánu.
    Vaše údaje nepodliehajú automatizovanému rozhodovaniu vrátane profilovania.`,
  'infoAboutPrivacyPolicy': 'Celý obsah informačnej doložky je k dispozícii tu',
  'businessSellerCopy': 'Predávajúci je',
  'privacyPolicy': 'zásady ochrany osobných údajov',
  'privacyPolicyAccept': 'Zásady ochrany osobných údajov',
  'termsOfServiceAgreement': 'Prečítal som a prijal som',
  'termsOfService': 'Predpisy o predaji',
  'inTermsOfService': 'Nariadenia',
  'inPrivacyPolicy': 'Zásady ochrany osobných údajov',
  'and': 'a',
  'acceptedTermsOfServiceIn': 'Prijaté podmienky spolupráce nájdete v',
  'facilityTermsOfService': 'Štatút %{facilityName}',
  'activitiesLimitExceeded':
    'Niekto vás porazil, medzitým sa počet dostupných lístkov znížil. Vyberte si iný.',
  'invalidDataOrCharacters': 'Údaje sú nesprávne alebo obsahujú nepovolené znaky. Opravte ich a skúste to znova.',
  'invalidAdmissionDate': 'Dátum je nesprávny.',
  'internalErrorMessage': 'Vyskytla sa chyba, skúste to znova o chvíľu',
  'widgetDisabled': 'Online predaj je momentálne zakázaný.',
  'bubbleDisabled': 'Výpredaj vylúčený!',
  'buyOnline': 'Kúp online!',
  'buyVoucher': 'Kúpte si voucher',
  'buyTicket': 'kúpiť lístok!',
  'bookingOnline': 'Rezervujte online',
  'buySkipass': 'Kúpte si skipas',
  'buyCourse': 'Kúpte si kurz',
  'iWantInvoice': 'Chcem dostať faktúru',
  'address': 'Ulica a číslo domu / bytu',
  'city': 'Mesto',
  'postalCode': 'PSČ',
  'companyName': 'Meno spoločnosti',
  'requiredOnlyForInvoices': 'Povinné len pre firemné faktúry',
  'companyNIP': 'DIČ',
  'optionalSentence': 'Voliteľné',
  'invalidPrice':
    `Platbu nemožno vykonať, pretože zobrazená cena sa líši od skutočnej ceny - obnovte stránku,
    aby ste si stiahli aktuálne údaje`,
  'paymentDelayedInfo':
    'Platby za objednávku je potrebné uhradiť na základe faktúry s DPH, ktorá bude vystavená do %{companyName}',
  'oncePerDay': 'Jeden vstup denne',
  'withoutLimit': 'Žiadny denný limit vstupu',
  'ticketsReady': 'Vstupenka je pripravená! |||| Lístky sú pripravené!',
  'pleaseWait': 'Prosím čakajte...',
  'downloadTickets': 'Získajte priepustku |||| Získajte lístky',
  'download': 'Stiahnuť',
  'loadingTickets': 'Vaše vstupenky sa pripravujú!',
  'doOrder': 'Objednávam',
  'doReservation': 'Rezervujem',
  'generateExampleOrder': 'Vytvorte vzorovú objednávku',
  'hotelNight': 'deň',
  'chooseHotelNight': 'Vyberte si obdobie prenocovania',
  'insertHotelNight': 'Zadajte dobu prenocovania',
  'chooseHotelNightDescription': 'Vstupenky sú hosťom k dispozícii len na dobu prenocovania.',
  'setNarrowedDates': 'Musíte vybrať dátum začiatku a konca svojho pobytu',
  'endDateCantBeTheSameAsStart': 'Koniec pobytu musí byť minimálne o jeden deň neskorší ako začiatok pobytu',
  'save': 'Uložiť',
  'insertYear': 'Zadajte rok',
  'hour': 'čas.',
  'realiseInDay': 'Realizácia dňa %{date}',
  'realiseInDateWithTime': 'Realizácia dňa %{date}, čas si zvolíte v ďalšom kroku',
  'missingPaymentProvider': 'Predávajúci zatiaľ neaktivoval prijímanie platieb. Skúste to znova čoskoro.',
  'seeOtherActivities': 'Pozrite si ďalšie návrhy',
  'hideOtherActivities': 'Skryť zvyšok návrhov',
  'givenDateIsSoldOut': 'Miesta pre %{date} boli vypredané',
  'isOutOfLimit': 'Nedostatok miest za hodinu %{time}',
  'givenTimeIsSoldOut': 'Miesta na %{time} sú vypredané',
  'hasNoSeatsForGivenDate': 'Nedostatok miest na %{date}',
  'seatsSoldOut': 'vypredané',
  'hasNoSeats': 'žiadne miesta',
  'chooseDifferentDate': 'Vyberte iný dátum',
  'actuallySoldOut': 'Vypredané!',
  'noDates': 'Momentálne žiadne termíny!',
  'customerData': 'Údaje o zákazníkovi',
  'optionalText': 'voliteľné',
  'numberOfParticipantsLabel': 'Počet účastníkov: %{name}',
  'avaliableNumber': 'Zostávajúce miesta: %{number}',
  'filter': 'Filter',
  'clear': 'Čisté',
  'checkItOut': 'Pozrite si to',
  'remove': 'Odstrániť',
  'thisMayInterestYou': 'Toto by vás mohlo zaujímať',
  'requiredActivityVariantsNotPresentInOrder': 'Na zakúpenie je potrebný iný variant. Vráťte sa na zoznam služieb a vyberte príslušný variant.',
  'requiredActivityVariants': 'Vo vašej objednávke niečo chýba.',
  'backTo': 'Návrat na kartu',
  'addSmall': 'pridať',
  'requiredActivity': 'požadovanú službu',
  'oneOfRequiredActivity': 'aspoň jedna požadovaná služba',
  'oneOfRequiredActivityVariants': 'aspoň jedna z požadovaných možností služby',
  'to': 'Do',
  'marketingConsentsAgreement': 'Chcem sa prihlásiť na odber noviniek (nepovinné)',
  'marketingConsentsPromo': 'Zostaňte informovaní o všetkých propagačných akciách a informáciách, posielame len tie najdôležitejšie veci, sľubujeme, že nebudeme zasielať spam!',
  'setAllConsents': 'Označte všetky',
  'InvalidNipFormat': 'Neplatné IČ DPH',
  'numberOfPeopleInGroup': 'Počet ľudí v skupine',
  'paymentFormOutside': 'Forma platby mimo Droplabs',
  'other': 'Iné',
  'paymentTerminal': 'Platobný terminál',
  'cash': 'Hotovosť',
  'bankTransfer': 'Prevod na účet',
  'prefix': 'Predpona',
  'paymentCompleteUntilBeforeMinimumDateTime': 'Doba splatnosti je príliš krátka',
  'paymentCompleteUntilDaysLimitExceeded': 'Doba splatnosti je príliš dlhá',
  'setPaymentDate': 'Nastavenie termínu platby',
  'setPaymentDateDesc': 'Ak zákazník nezaplatí za objednávku do stanoveného dátumu, miesta sa vrátia do fondu voľných miest.',
  'newOrder': 'Vytvorenie novej objednávky',
  'LastStep': 'Máte pred sebou posledný krok...',
  'copyLinkAndSendToCustomer': 'Skopírujte odkaz a odošlite ho zákazníkovi na zaplatenie objednávky.',
  'orClick': 'alebo kliknite na',
  'CopyLink': 'Kopírovať odkaz',
  'paymentOutside': 'Platba mimo Droplabs',
  'paymentOnline': 'Online platba',
  'cancel': 'Zrušiť',
  'setDateAndTime': 'Výber dátumu a času',
  'privatePerson': 'Súkromná osoba',
  'company': 'Firma',
  'invoiceOptionalNote': 'Poznámka k faktúre (voliteľne)',
  'youWillReceiveInvoiceSimplified': 'Dostanete paragón s DIČ, ktorý je zjednodušenou faktúrou.',
  'nameOptional': 'Krstné meno (nepovinné)',
  'surnameOptional': 'Priezvisko (nepovinné)',
  'emailOptional': 'E-mailová adresa (nepovinné)',
  'isExpired': 'stratil platnosť',
  'isAdmissionDateInvalid': 'sa týka iného času realizácie',
  'isInvalid': 'je neplatný',
  'isUsed': 'je použitý',
  'buyerData': 'Údaje kupujúceho',
  'requiredEmailAlert': 'Nezabudnite uviesť e-mailovú adresu klienta, inak nedostane faktúru.',
  'issueAnInvoice': 'Vystaviť faktúru',
  'areYouLeaving': 'Už idete von?',
  'exitConfirmationComment': 'Pri kúpe teraz máte zaručenú nezmenenú cenu. Ak odídete, stratíte doteraz vykonané kroky.',
  'abandonPurchase': 'Opustenie nákupu',
  'continueShopping': 'Pokračovať v nákupe',
  'thisFeatureIsNotEnabled': 'Táto funkcia nie je k dispozícii',
  'thisFeatureIsNotEnabledSolution': 'Nemáte čo stratiť! Napíšte nám na adresu %p, pomôžeme vám vybrať správny plán.',
  'droplabsHelpMail': 'pomoc@droplabs.pl',
  'pleaseWaitSummary': 'Prosím čakajte',
  'waitingForPaymentConfirmation': 'Čakáme na potvrdenie o uskutočnení platby.',
  'paymentProcessInterrupted': 'Ak bola platobná transakcia prerušená, môžete zaplatiť objednávku',
  'clickHere': 'kliknutím sem',
  'ticketsLoadedAndSentToEmail': 'Vstupenky sa načítajú nižšie a budú odoslané na váš email:',
  'paymentAccepted': 'Platba prijatá',
  'orderNumber': 'Číslo objednávky:',
  'orderSendOnEmail': 'Potvrdenie objednávky zaslané e-mailom:',
  'emailNotGetThrough': 'Správa sa nezobrazuje?',
  'emailNotGetThroughTooltip': 'Ak v priebehu niekoľkých minút od platby nevidíte správu, skontrolujte si zložku so spamom. Ak to nepomôže, napíšte \'Droplabs\' do vyhľadávania vo svojom e-mailovom klientovi.',
  'orderCreated': 'Objednávka vytvorená',
  'generatingTickets': 'Prebieha generovanie vstupeniek',
  'generatingTicketsDescription': 'Čoskoro budete môcť stiahnuť svoj lístok a pridať ho do virtuálnej peňaženky. Taktiež sme zaslali kópiu toho istého lístka na váš uvedený e-mail.',
  'everythingDone': 'Všetko je pripravené!',
  'ticketsReadyToDownload': 'Vstupenky sú pripravené na stiahnutie. Kliknite na tlačidlo pre stiahnutie.',
  'showTickets': 'Zobraziť vstupenky',
  'hideTickets': 'Skryť vstupenky',
  'pdfDocumentIsReady': 'Úspech, PDF je pripravené!',
  'clickForPdfDocument': 'PDF je pripravené na stiahnutie. Kliknite na tlačidlo pre stiahnutie.',
  'pdfDocumentIsGenerating': 'Prebieha generovanie PDF',
  'pdfDocumentIsGeneratingDescription': 'Generovanie PDF môže chvíľu trvať, nemusíte čakať.',
  'generating': 'Generovanie...',
  'sendOrder': 'Odoslať objednávku',
  'sendOrderDescription': 'Zadajte e-mailovú adresu, na ktorú má byť objednávka spolu s vstupenkou zaslaná.',
  'orderSendedOnEmail': 'Objednávka bola odoslaná na zadanú e-mailovú adresu',
  'orderSomethingElse': 'Chcete si ešte niečo objednať?',
  'actionDisabledInPreviewMode': 'Táto akcia nie je povolená v režime náhľadu.',
  'anotherRecipient': 'Chcem zadať iného príjemcu',
  'recipientName': 'Názov príjemcu',
  'digitalShippingMethod': 'Elektronické odoslanie na určenú e-mailovú adresu',
  'somethingIsWrong': 'Niečo sa pokazilo...',
  'waitingForPaymentError': 'Vaša objednávka bola zrušená alebo vypršal čas na zaplatenie. Vytvorte novú objednávku alebo obnovte platbu.',
  'waitingForPayment': 'Čakanie na platbu',
  'waitingForPaymentDesc': 'Suma objednávky bola odoslaná na terminál Viva Wallet. Platbu prijmite hneď, ako sa suma zobrazí na obrazovke terminálu. Ak si neželáte platbu prijať, transakciu zrušte.',
  'sendAgain': 'Opätovné odoslanie',
  'paymentRepeatError': 'Platbu nebolo možné opätovne odoslať',
  'paymentRepeatSucces': 'Platba bola opätovne odoslaná',
  'rePayment': 'Opakovaná platba',
  'setTicketAsUsed': 'Označiť ako použitý',
  'setTicketAsUsedDescription': 'Prihlásiť využitie všetkých práve vygenerovaných vstupeniek, platných dnes.',
  'useTickets': 'Použiť vstupenky',
  'useTicketsSuccess': 'Vstupenky boli úspešne označené ako použité.',
  'useTicketsError': 'Nie je možné označiť vstupenky ako použité.',
  'mismatchedTicketDate': 'Objednávka sa týka iného termínu realizácie.',
  'ticketInactive': 'Objednávka nie je aktívna.',
  'useTicketsErrorMoreDetails': 'Podrobnosti o objednávke môžete nájsť v sekcii \'Zákaznícka podpora\' alebo \'Kontrola prístupu\'.',
  'country': 'Krajina',
  'notSavedData': 'Údaje sa neuložia',
  'notSavedDataDesc': 'Ak ste zadali ďalšie údaje, upozorňujeme, že pri výbere služieb sa tieto informácie stratia.',
  'changeActivities': 'Zmeniť položky',
  'AD': 'Andorra',
  'AE': 'Spojené arabské emiráty',
  'AF': 'Afganistan',
  'AG': 'Antigua a Barbuda',
  'AI': 'Anguilla',
  'AL': 'Albánsko',
  'AM': 'Arménsko',
  'AO': 'Angola',
  'AQ': 'Antarktída',
  'AR': 'Argentína',
  'AS': 'Americká Samoa',
  'AT': 'Rakúsko',
  'AU': 'Austrália',
  'AW': 'Aruba',
  'AX': 'Ålandy',
  'AZ': 'Azerbajdžan',
  'BA': 'Bosna a Hercegovina',
  'BB': 'Barbados',
  'BD': 'Bangladéš',
  'BE': 'Belgicko',
  'BF': 'Burkina Faso',
  'BG': 'Bulharsko',
  'BH': 'Bahrajn',
  'BI': 'Burundi',
  'BJ': 'Benin',
  'BL': 'Svätý Bartolomej',
  'BM': 'Bermudy',
  'BN': 'Brunej',
  'BO': 'Bolívia (Mnichov štát)',
  'BQ': 'Bonaire, Sint Eustatius a Saba',
  'BR': 'Brazília',
  'BS': 'Bahamy',
  'BT': 'Bhután',
  'BV': 'Bouvetov ostrov',
  'BW': 'Botswana',
  'BY': 'Bielorusko',
  'BZ': 'Belize',
  'CA': 'Kanada',
  'CC': 'Kokosové ostrovy',
  'CD': 'Konžská demokratická republika',
  'CF': 'Stredoafrická republika',
  'CG': 'Kongo',
  'CH': 'Švajčiarsko',
  'CI': 'Pobrežie Slonoviny',
  'CK': 'Cookove ostrovy',
  'CL': 'Čile',
  'CM': 'Kamerun',
  'CN': 'Čína',
  'CO': 'Kolumbia',
  'CR': 'Kostarika',
  'CU': 'Kuba',
  'CV': 'Kapverdy',
  'CW': 'Curaçao',
  'CX': 'Vianočný ostrov',
  'CY': 'Cyprus',
  'CZ': 'Česká republika',
  'DE': 'Nemecko',
  'DJ': 'Džibutsko',
  'DK': 'Dánsko',
  'DM': 'Dominika',
  'DO': 'Dominikánska republika',
  'DZ': 'Alžírsko',
  'EC': 'Ekvádor',
  'EE': 'Estónsko',
  'EG': 'Egypt',
  'EH': 'Západná Sahara',
  'ER': 'Eritrea',
  'ES': 'Španielsko',
  'ET': 'Etiópia',
  'FI': 'Fínsko',
  'FJ': 'Fidži',
  'FK': 'Falklandy (Malvíny)',
  'FM': 'Mikronézia (spojené štáty)',
  'FO': 'Faerské ostrovy',
  'FR': 'Francúzsko',
  'GA': 'Gabon',
  'GB': 'Spojené kráľovstvo Veľkej Británie a Severného Írska',
  'GD': 'Grenada',
  'GE': 'Gruzínsko',
  'GF': 'Francúzska Guyana',
  'GG': 'Guernsey',
  'GH': 'Ghana',
  'GI': 'Gibraltár',
  'GL': 'Grónsko',
  'GM': 'Gambia',
  'GN': 'Guinea',
  'GP': 'Guadeloupe',
  'GQ': 'Rovníková Guinea',
  'GR': 'Grécko',
  'GS': 'Južná Georgia a Južné Sandwichove ostrovy',
  'GT': 'Guatemala',
  'GU': 'Guam',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HK': 'Hongkong',
  'HM': 'Heardov ostrov a McDonaldove ostrovy',
  'HN': 'Honduras',
  'HR': 'Chorvátsko',
  'HT': 'Haiti',
  'HU': 'Maďarsko',
  'ID': 'Indonézia',
  'IE': 'Írsko',
  'IL': 'Izrael',
  'IM': 'Ostrov Man',
  'IN': 'India',
  'IO': 'Britské indickooceánske územie',
  'IQ': 'Irak',
  'IR': 'Irán (Islamská republika)',
  'IS': 'Island',
  'IT': 'Taliansko',
  'JE': 'Jersey',
  'JM': 'Jamajka',
  'JO': 'Jordánsko',
  'JP': 'Japonsko',
  'KE': 'Keňa',
  'KG': 'Kirgizsko',
  'KH': 'Kambodža',
  'KI': 'Kiribati',
  'KM': 'Komory',
  'KN': 'Svätý Krištof a Nevis',
  'KP': 'Kórejská ľudovodemokratická republika',
  'KR': 'Kórejská republika',
  'KW': 'Kuvajt',
  'KY': 'Kajmanie ostrovy',
  'KZ': 'Kazachstan',
  'LA': 'Laoská ľudovodemokratická republika',
  'LB': 'Libanon',
  'LC': 'Svätá Lucia',
  'LI': 'Lichtenštajnsko',
  'LK': 'Srí Lanka',
  'LR': 'Libéria',
  'LS': 'Lesotho',
  'LT': 'Litva',
  'LU': 'Luxembursko',
  'LV': 'Lotyšsko',
  'LY': 'Líbya',
  'MA': 'Maroko',
  'MC': 'Monako',
  'MD': 'Moldavsko',
  'ME': 'Čierna Hora',
  'MF': 'Svätý Martin (francúzsky)',
  'MG': 'Madagaskar',
  'MH': 'Marshallove ostrovy',
  'MK': 'Severné Makedónia',
  'ML': 'Mali',
  'MM': 'Mjanmarsko',
  'MN': 'Mongolsko',
  'MO': 'Macao',
  'MP': 'Severné Mariány',
  'MQ': 'Martinik',
  'MR': 'Mauritánia',
  'MS': 'Montserrat',
  'MT': 'Malta',
  'MU': 'Maurícius',
  'MV': 'Maldivy',
  'MW': 'Malawi',
  'MX': 'Mexiko',
  'MY': 'Malajzia',
  'MZ': 'Mozambik',
  'NA': 'Namíbia',
  'NC': 'Nová Kaledónia',
  'NE': 'Niger',
  'NF': 'Norfolk',
  'NG': 'Nigéria',
  'NI': 'Nikaragua',
  'NL': 'Holandsko',
  'NO': 'Nórsko',
  'NP': 'Nepál',
  'NR': 'Nauru',
  'NU': 'Niue',
  'NZ': 'Nový Zéland',
  'OM': 'Omán',
  'PA': 'Panama',
  'PE': 'Peru',
  'PF': 'Francúzska Polynézia',
  'PG': 'Papua-Nová Guinea',
  'PH': 'Filipíny',
  'PK': 'Pakistan',
  'PL': 'Poľsko',
  'PM': 'Saint Pierre a Miquelon',
  'PN': 'Pitcairnove ostrovy',
  'PR': 'Portoriko',
  'PS': 'Palestína, štát',
  'PT': 'Portugalsko',
  'PW': 'Palau',
  'PY': 'Paraguaj',
  'QA': 'Katar',
  'RE': 'Réunion',
  'RO': 'Rumunsko',
  'RS': 'Srbsko',
  'RU': 'Ruská federácia',
  'RW': 'Rwanda',
  'SA': 'Saudská Arábia',
  'SB': 'Šalamúnove ostrovy',
  'SC': 'Seychely',
  'SD': 'Sudán',
  'SE': 'Švédsko',
  'SG': 'Singapur',
  'SH': 'Svätá Helena, Ascension a Tristan da Cunha',
  'SI': 'Slovinsko',
  'SJ': 'Svalbard a Jan Mayen',
  'SK': 'Slovensko',
  'SL': 'Sierra Leone',
  'SM': 'San Maríno',
  'SN': 'Senegal',
  'SO': 'Somálsko',
  'SR': 'Surinam',
  'SS': 'Južný Sudán',
  'ST': 'Svätý Tomáš a Princov ostrov',
  'SV': 'Salvádor',
  'SX': 'Sint Maarten (holandská časť)',
  'SY': 'Sýria',
  'SZ': 'Eswatini',
  'TC': 'Turks a Caicos',
  'TD': 'Čad',
  'TF': 'Francúzske južné a antarktické územia',
  'TG': 'Togo',
  'TH': 'Thajsko',
  'TJ': 'Tadžikistan',
  'TK': 'Tokelau',
  'TL': 'Východný Timor',
  'TM': 'Turkménsko',
  'TN': 'Tunisko',
  'TO': 'Tonga',
  'TR': 'Turecko',
  'TT': 'Trinidad a Tobago',
  'TV': 'Tuvalu',
  'TW': 'Taiwan, provincie Číny',
  'TZ': 'Tanzánia, Spojená republika',
  'UA': 'Ukrajina',
  'UG': 'Uganda',
  'UM': 'Menšie odľahlé ostrovy Spojených štátov',
  'US': 'Spojené štáty americké',
  'UY': 'Uruguaj',
  'UZ': 'Uzbekistan',
  'VA': 'Vatikán',
  'VC': 'Svätý Vincent a Grenadíny',
  'VE': 'Venezuela (Bolívarovská republika)',
  'VG': 'Britské Panenské ostrovy',
  'VI': 'Americké Panenské ostrovy',
  'VN': 'Vietnam',
  'VU': 'Vanuatu',
  'WF': 'Wallis a Futuna',
  'WS': 'Samoa',
  'YE': 'Jemen',
  'YT': 'Mayotte',
  'ZA': 'Južná Afrika',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe',
  'nearestDate': 'Najbližší dostupný dátum',
  'nearestDateWithHour': '%{day} o %{hour}',
  'tomorrow': 'zajtra',
  'paymentMethod': 'Spôsob platby',
  'secureFormDescription': 'Zaplaťte rýchlym prevodom / platobnou kartou',
  'typeGeneratedBlikCode': 'Zadajte jednorazový kód vygenerovaný vo vašej bankovej aplikácii',
  'blik': 'BLIK',
  'typeBlikCode': 'Zadajte kód BLIK',
  'blikCodePatternError': 'Kód musí obsahovať 6 číslic',
  'blikCode': 'Kód BLIK',
  'simplifiedInvoice': 'Zjednodušené',
  'fullInvoice': 'Úplný',
  'privateInvoice': 'Názov',
  'simplifiedInvoiceInformation': 'Zákazník dostane potvrdenie s číslom DPH ako zjednodušenú faktúru. Môže byť vystavená len pre spoločnosti registrované v Poľsku. V prípade zahraničných spoločností si vyberte úplnú faktúru.',
  'isNotPolishNip': 'Uvedené číslo NIP nie je poľské.',
  'polishNip': 'Poľské číslo DPH',
  'checkTheBankingApp': 'Skontrolujte bankovú aplikáciu',
  'confirmPaymentWithPin': 'Teraz potvrďte platbu PINom pomocou aplikácie vo vašom telefóne.',
  'ticketsLoadedAfterConfirm': 'Vstupenky sa načítajú po potvrdení platby a budú odoslané na e-mail:',
  'paymentWithBlik': 'Platba s BLIKom',
  'cannontLoadPage': 'Nepodarilo sa načítať túto stránku',
  'refreshPage': 'Obnoviť stránku',
  'isSoldOut': 'Vypredané',
  'removeSelectedActivities': 'Odstránenie vybraných služieb',
  'removeSelectedServicesQuestion': 'Odstrániť vybrané služby?',
  'allServicesWillBeRemoved': 'Všetky služby budú odstránené a vrátite sa k opätovnému výberu'
};

/* eslint-enable max-len */
