import {UrlParamsValues, useUrlParams} from './useUrlParams';

export enum Features {
  NEW_CALENDAR = 'newCalendar',
}

const useFeatureFlag = (feature: Features) => {
  if (feature === Features.NEW_CALENDAR) {
    const [addmisionCalendarUrlParam] = useUrlParams([Features.NEW_CALENDAR] as Features[]);

    if (addmisionCalendarUrlParam === UrlParamsValues.TRUE) {
      return {isEnabled: true};
    }
  }

  return {isEnabled: false};
};

export default useFeatureFlag;
