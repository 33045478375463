import React, {useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import CalendarPanel from './CalendarPanel';
import {getMonthDates, LAST_MONTH_INDEX} from '@Utils/date';
import CalendarHeader from './CalendarHeader';
import CalendarRow from './CalendarRow';
import CalendarCell from './CalendarCell';
import dayjs from 'dayjs';
import Text from '@Components/common/text/Text';
import {DL_COLOR_TYPE} from '@Consts/color';
import locale from '@Utils/locale';
import './calendar.scss';
import {ETagType} from '@Components/common/text/types';
import {getFormattedDate, getTimezoneDate, getTimezoneDateOrNull, isAfter, isBefore} from '@Utils/dayjs/dayjsUtils';
import {DASH_DAY_FORMAT} from '@Utils/dayjs/const';
import {useSelector} from 'react-redux';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';

const DlCalendar = ({value, minDate, maxDate, onChange}) => {
  const timezone = useSelector(getFacilityTimezone);
  const year = value.getFullYear();
  const month = value.getMonth();
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedMonth, setSelectedMonth] = useState(month);
  const datesGroupedByWeeks = getMonthDates(selectedYear, selectedMonth, timezone);
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, setError] = useState(null);

  function onChangeDate(date) {
    setError(null);
    const dateWithTimezone = getTimezoneDate(date, timezone, true);
    const isMinRangeExceded = isBefore(dateWithTimezone, minDate, 'second');
    const isMaxRangeExceded = isAfter(dateWithTimezone, maxDate, 'second');
    const isAvaliable = !isMinRangeExceded && !isMaxRangeExceded;

    if (!isAvaliable) {
      return setError(locale.translate('selectedDateIsNotAvailable', {date}));
    }

    onChange(date);

    setSelectedDate({
      date,
      selectionRangeStart: true,
      selectionRangeEnd: true
    });
  }

  function handleSelectPrevMonth() {
    if (selectedMonth === 0) {
      setSelectedYear(selectedYear - 1);
      return setSelectedMonth(LAST_MONTH_INDEX);
    }

    return setSelectedMonth(selectedMonth - 1);
  }

  function handleSelectNextMonth() {
    if (selectedMonth === LAST_MONTH_INDEX) {
      setSelectedYear(selectedYear + 1);
      return setSelectedMonth(0);
    }

    return setSelectedMonth(selectedMonth + 1);
  }

  return (
    <div className={'dl-calendar dl-calendar--without-mt'}>
      <CalendarPanel
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        onPrevMonthClick={handleSelectPrevMonth}
        onNextMonthClick={handleSelectNextMonth}
        isLower
      />

      <div className="dl-calendar__days">
        <CalendarHeader isLower />
        {
          datesGroupedByWeeks.map((week, weekIndex) => (
            <CalendarRow key={`week-${weekIndex}`}>
              {
                week.map((day, dayIndex) => {
                  const key = `day-${dayIndex}`;
                  const isAvaliable = dayjs(day).isAfter(minDate) && dayjs(day).isBefore(maxDate);
                  const isSelected = dayjs(day).isSame(selectedDate?.date);
                  const dateData = {
                    ...selectedDate,
                    isSelected,
                    isAvaliable,
                    isHotelNarrowDate: true,
                    date: getFormattedDate(dayjs(day), DASH_DAY_FORMAT)
                  };

                  return (
                    <CalendarCell
                      calendarDay={getTimezoneDateOrNull(day, timezone)}
                      cellData={dateData}
                      onClick={onChangeDate}
                      key={key}
                    />
                  );
                })
              }
            </CalendarRow>
          ))
        }
      </div>

      {
        error &&
          <Text
            className="dl-calendar__error"
            tagType={ETagType.DIV}
            color={DL_COLOR_TYPE.ERROR}
            dl-test="error-text"
          >
            {error}
          </Text>
      }
    </div>
  );
};

DlCalendar.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func
};

export default DlCalendar;
