export enum EStorageKeys {
  LATEST_ORDERS = 'latestOrders',
  LANGUAGE_PICKED = 'languagePicked',
  EMAIL = 'email',
  PHONE_PREFIX = 'phonePrefix',
  PHONE_NUMBER = 'phoneNumber',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  INVOICE_ADDRESS_CITY = 'invoiceAddressCity',
  INVOICE_ADDRESS_STREET = 'invoiceAddressStreet',
  INVOICE_ADDRESS_POSTAL_CODE = 'invoiceAddressPostalCode',
  INVOICE_ADDRESS_COUNTRY = 'invoiceAddressCountry',
  INVOICE_COMPANY_NAME = 'invoiceCompanyName',
  INVOICE_COMPANY_NIP = 'invoiceCompanyNip',
  INVOICE_FIRST_NAME = 'invoiceFirstName',
  INVOICE_LAST_NAME = 'invoiceLastName',
  INVOICE_FORM_TYPE = 'invoiceFormType',
  SEND_INVOICE = 'sendInvoice',
  IS_INVOICE_RECIPIENT = 'isInvoiceRecipient',
  INVOICE_RECIPIENT_NAME = 'invoiceRecipientName',
  INVOICE_RECIPIENT_ADDRESS_CITY = 'invoiceRecipientAddressCity',
  INVOICE_RECIPIENT_ADDRESS_STREET = 'invoiceRecipientAddressStreet',
  INVOICE_RECIPIENT_ADDRESS_POSTAL_CODE = 'invoiceRecipientAddressPostalCode',
  INVOICE_RECIPIENT_ADDRESS_COUNTRY = 'invoiceRecipientAddressCountry',
  AB_TEST = 'abTest',
  BASKET_ITEMS = 'basketItems',
  AFFILIATION_HASHES = 'affiliationHashes',
  INVOICE_NOTE = 'invoiceNote',
  AB_TEST_TIMESTAMP = 'abTestTimestamp'
}

export const invoiceFormFieldsNames = [
  EStorageKeys.INVOICE_ADDRESS_CITY,
  EStorageKeys.INVOICE_ADDRESS_STREET,
  EStorageKeys.INVOICE_ADDRESS_POSTAL_CODE,
  EStorageKeys.INVOICE_ADDRESS_COUNTRY,
  EStorageKeys.INVOICE_COMPANY_NAME,
  EStorageKeys.INVOICE_COMPANY_NIP,
  EStorageKeys.INVOICE_FIRST_NAME,
  EStorageKeys.INVOICE_LAST_NAME,
  EStorageKeys.INVOICE_FORM_TYPE,
  EStorageKeys.SEND_INVOICE,
  EStorageKeys.IS_INVOICE_RECIPIENT,
  EStorageKeys.INVOICE_RECIPIENT_NAME,
  EStorageKeys.INVOICE_RECIPIENT_ADDRESS_CITY,
  EStorageKeys.INVOICE_RECIPIENT_ADDRESS_STREET,
  EStorageKeys.INVOICE_RECIPIENT_ADDRESS_POSTAL_CODE,
  EStorageKeys.INVOICE_RECIPIENT_ADDRESS_COUNTRY,
  EStorageKeys.INVOICE_NOTE
];
