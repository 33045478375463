import React, {useRef} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  areActivityDatesInvalid,
  getActivityLabelText,
  hasActivityValidPrice,
  isActivitySingleGroupEvent,
  isActivityWithDates
} from '@Utils/activity/activity';
import uuidv4 from 'uuid/v4';
import ActivityCoverPhoto from './ActivityCoverPhoto';
import ActivityHeader from './ActivityHeader';
import ActivityEventInfo from './ActivityEventInfo';
import ActivityContent, {SPACING_TYPE} from './ActivityContent';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Text from '@Components/common/text/Text';
import ActivityAdmission from './activityAdmission/ActivityAdmission';
import ActivityBooking from './ActivityBooking';
import ActivityPrice from './ActivityPrice';
import ActivityDescription from './ActivityDescription';
import {ETextColor} from '@Components/common/text/types';
import ActivityAdmissionSkipLine from '@Components/activities/activity/activityAdmission/ActivityAdmissionSkipLine';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {useMediaQuery, useTheme} from '@mui/material';
import {useMatch} from 'react-router-dom';
import {CHECKOUT_PATH} from '@Utils/routerUtils';

const ActivityItemContent = ({activity, indicated, picker, additionalContent, numberOfActivities}) => {
  const {
    name,
    admissionPolicy,
    coverPhoto,
    addressDescription,
    variants,
    passType,
    bookingInformation,
    description,
    saleInformations,
    salePolicy,
    id
  } = activity;
  const datesAreInvalid = areActivityDatesInvalid(activity);
  const activityHaveValidPrices = hasActivityValidPrice(activity.variants);
  const disabled = salePolicy.isSoldOut || datesAreInvalid || !activityHaveValidPrices;
  const activityLabelText = getActivityLabelText(
    activity,
    {invalidDates: datesAreInvalid, activityHaveValidPrices}
  );
  const numberOfVariants = variants.length;
  const variantName = numberOfVariants === 1 && variants[0].name;
  const {canSkipTheLine, firstAdmissionDate, isOneAdmissionDateAvailable} = admissionPolicy;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isUpsell = useMatch(CHECKOUT_PATH);
  const activityClass = isUpsell ? 'dl-activity-upsell' : 'dl-activity';
  const activityWrapperRef = useRef(null);

  return (
    <div
      role="listitem"
      className={classNames(
        `${activityClass}`,
        {
          [`${activityClass}--indicated`]: indicated
        }
      )}
    >
      {
        disabled && <div className={`${activityClass}__overlay`} />
      }

      {
        coverPhoto && (
          <ActivityCoverPhoto
            url={coverPhoto}
            className={`${activityClass}__cover-photo-wrapper`}
            freezeHeight
            isUpsell={isUpsell}
          />
        )
      }

      <div className={`${activityClass}__wrapper`} ref={activityWrapperRef}>
        <div className={`${activityClass}__main`}>
          {
            coverPhoto && (
              <ActivityCoverPhoto
                url={coverPhoto}
                className={`${activityClass}__cover-photo`}
                isUpsell={isUpsell}
              />
            )
          }

          <ActivityContent spacing={SPACING_TYPE.BOTTOM}>
            <ActivityHeader
              name={name}
              variantName={variantName}
              activityLabelText={activityLabelText}
              isDesktop={isDesktop}
              isUpsell={isUpsell}
              firstAdmissionDate={firstAdmissionDate}
              passType={passType}
              isOneAdmissionDateAvailable={isOneAdmissionDateAvailable}
            />
          </ActivityContent>

          {
            isActivitySingleGroupEvent(passType) &&
              <ActivityEventInfo admissionPolicy={admissionPolicy} />
          }

          <ActivityAdmission activity={activity} />

          {
            addressDescription && (
              <ActivityContent dl-test="address-description">
                <LocationOnOutlinedIcon sx={{color: 'background.text', mr: 1}} fontSize="small" />

                <Text color={ETextColor.LIGHT}>
                  {addressDescription}
                </Text>
              </ActivityContent>
            )
          }

          {
            !isActivityWithDates(passType) &&
            <ActivityBooking bookingInformation={bookingInformation} />
          }

          {
            canSkipTheLine && <ActivityAdmissionSkipLine />
          }

          {
            saleInformations?.length > 0 && saleInformations.map(({text}) => (
              <ActivityContent key={uuidv4()} dl-test="sale-informations">
                <CheckCircleOutlineOutlinedIcon sx={{color: 'background.text', mr: 1}} fontSize="small" />
                <Text color={ETextColor.LIGHT}>
                  {text}
                </Text>
              </ActivityContent>
            ))
          }
        </div>

        <div className={`${activityClass}__aside`}>
          <ActivityContent pullToCorner>
            {
              activityLabelText && isDesktop && !isUpsell && (
                <div
                  dl-test="activity-label"
                  className={`${activityClass}__label`}
                >
                  <div className={`${activityClass}__label--text`}>
                    {activityLabelText}
                  </div>
                </div>
              )
            }

            {
              <ActivityPrice activity={activity} activityHaveValidPrices={activityHaveValidPrices} />
            }

            {!disabled && picker}
          </ActivityContent>
        </div>
      </div>

      {
        (additionalContent || description) && (
          <div className={`${activityClass}__bottom`}>
            {additionalContent}

            {description &&
            <ActivityDescription
              description={description}
              disabled={disabled}
              isUpsell={isUpsell}
              activityName={name}
              activityId={id}
              activityWrapperRef={activityWrapperRef?.current}
              indicated={indicated}
              numberOfActivities={numberOfActivities}
            />}
          </div>
        )
      }
    </div>
  );
};

ActivityItemContent.propTypes = {
  activity: PropTypes.object.isRequired,
  indicated: PropTypes.bool,
  picker: PropTypes.node,
  additionalContent: PropTypes.node,
  numberOfActivities: PropTypes.number.isRequired
};

export default ActivityItemContent;
