import {getCountries} from '@Utils/invoiceCountries';
import {EStorageKeys} from '@Consts/storageConsts';

export const getStorageState = state => state.storage || null;

export const getIsStorageInitializated = state => {
  const storage = getStorageState(state);

  return storage.isStorageInitializated;
};

export const getStorageItemFromState = (state, key) => {
  const storage = getStorageState(state);

  return storage?.[key] || null;
};

export const getAbTestType = state =>
  getStorageItemFromState(state, EStorageKeys.AB_TEST);

export const getStorageLanguagePicked = state =>
  getStorageItemFromState(state, EStorageKeys.LANGUAGE_PICKED);

export const getStorageEmail = state =>
  getStorageItemFromState(state, EStorageKeys.EMAIL);

export const getStoragePhonePrefix = state =>
  getStorageItemFromState(state, EStorageKeys.PHONE_PREFIX);

export const getStoragePhoneNumber = state =>
  getStorageItemFromState(state, EStorageKeys.PHONE_NUMBER);

export const getStorageFirstName = state =>
  getStorageItemFromState(state, EStorageKeys.FIRST_NAME);

export const getStorageLastName = state =>
  getStorageItemFromState(state, EStorageKeys.LAST_NAME);

export const getStorageInvoiceAddressCity = state =>
  getStorageItemFromState(state, EStorageKeys.INVOICE_ADDRESS_CITY);

export const getStorageInvoiceAddressStreet = state =>
  getStorageItemFromState(state, EStorageKeys.INVOICE_ADDRESS_STREET);

export const getStorageInvoiceAddressPostalCode = state =>
  getStorageItemFromState(state, EStorageKeys.INVOICE_ADDRESS_POSTAL_CODE);

export const getStorageInvoiceAddressCountry = state => {
  const storageItem = getStorageItemFromState(state, EStorageKeys.INVOICE_ADDRESS_COUNTRY);

  return storageItem ? getCountries().find(country => storageItem.code === country.code) : null;
};

export const getStorageInvoiceCompanyName = state =>
  getStorageItemFromState(state, EStorageKeys.INVOICE_COMPANY_NAME);

export const getStorageInvoiceCompanyNip = state =>
  getStorageItemFromState(state, EStorageKeys.INVOICE_COMPANY_NIP);

export const getStorageInvoiceFirstName = state =>
  getStorageItemFromState(state, EStorageKeys.INVOICE_FIRST_NAME);

export const getStorageInvoiceLastName = state =>
  getStorageItemFromState(state, EStorageKeys.INVOICE_LAST_NAME);

export const getStorageInvoiceFormType = state =>
  getStorageItemFromState(state, EStorageKeys.INVOICE_FORM_TYPE);

export const getStorageSendInvoice = state =>
  getStorageItemFromState(state, EStorageKeys.SEND_INVOICE);

export const getStorageIsInvoiceRecipient = state =>
  getStorageItemFromState(state, EStorageKeys.IS_INVOICE_RECIPIENT);

export const getStorageInvoiceRecipientName = state =>
  getStorageItemFromState(state, EStorageKeys.INVOICE_RECIPIENT_NAME);

export const getStorageInvoiceRecipientAddressStreet = state =>
  getStorageItemFromState(state, EStorageKeys.INVOICE_RECIPIENT_ADDRESS_STREET);

export const getStorageInvoiceRecipientAddressCity = state =>
  getStorageItemFromState(state, EStorageKeys.INVOICE_RECIPIENT_ADDRESS_CITY);

export const getStorageInvoiceRecipientAddressPostalCode = state =>
  getStorageItemFromState(state, EStorageKeys.INVOICE_RECIPIENT_ADDRESS_POSTAL_CODE);

export const getStorageInvoiceRecipientAddressCountry = state => {
  const storageItem = getStorageItemFromState(state, EStorageKeys.INVOICE_RECIPIENT_ADDRESS_COUNTRY);

  return storageItem ? getCountries().find(country => storageItem.code === country.code) : null;
};

export const getStorageLatestOrders = state =>
  getStorageItemFromState(state, EStorageKeys.LATEST_ORDERS);

export const getStorageBasketItems = state =>
  getStorageItemFromState(state, EStorageKeys.BASKET_ITEMS);
