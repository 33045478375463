type StylesOptions = {
    bottom: string,
    left: string,
    right: string,
    zIndex: string,
    bgColor: string,
    textColorType: string
  }

export const TEXT_COLOR_DARK = 'DARK';
export const COLOR_DARK = '#2D2D2D';
export const COLOR_LIGHT = '#FFFFFF';

const buildStyles = ($scriptNode: Element | null, {
  bottom,
  left,
  right,
  zIndex,
  bgColor,
  textColorType
}: StylesOptions) => {
  const isRight = right !== 'initial';
  const leftTab = isRight ? left : 0;
  const rightTab = isRight ? 0 : right;
  const tabMargins = isRight ? '10% 0% 0% 10%' : '0% 10% 10% 0%';
  const textIsDark = textColorType === TEXT_COLOR_DARK;
  const textColor = textIsDark ? COLOR_DARK : COLOR_LIGHT;

  const createTabModifiers = () => {
    const buildedTabStyles = [
      {name: 'buy-online'},
      {name: 'buy-ticket'},
      {name: 'buy-voucher'},
      {name: 'booking-online'},
      {name: 'local-tours'},
      {name: 'buy-skipass'},
      {name: 'buy-course'},
      {name: 'shop-online'},
      {name: 'order-online'},
      {name: 'plan-visit'},
      {name: 'buy-online-cheaper'}
    ].reduce((generatedClasses, {name}) => {
      const newClass = `
        .dl-tab--${name} {
          padding: 0 10px;
        }`;

      const newMobileClass = `
        .dl-tab--${name}-mobile {
          padding: 0 5px;
          font-size: 12px;
        }`;

      return `${generatedClasses} ${newClass} ${newMobileClass}`;
    }, '');

    return buildedTabStyles;
  };

  const createBubbleModifiers = () => {
    const buildedTabStyles = [
      {name: 'buy-online', padding: 0, fontSize: 16},
      {name: 'buy-ticket', padding: 6, fontSize: 18},
      {name: 'buy-voucher', padding: 0, fontSize: 15},
      {name: 'booking-online', padding: 4, fontSize: 14},
      {name: 'local-tours', padding: 4, fontSize: 14},
      {name: 'buy-skipass', padding: 0, fontSize: 18},
      {name: 'buy-course', padding: 6, fontSize: 20},
      {name: 'shop-online', padding: 0, fontSize: 16},
      {name: 'order-online', padding: 0, fontSize: 16},
      {name: 'plan-visit', padding: 0, fontSize: 16},
      {name: 'buy-online-cheaper', padding: 4, fontSize: 15, lineHeight: 1.4}
    ].reduce((generatedClasses, {name, padding, fontSize, lineHeight}) => {
      const newClass = `
        .dl--${name} {
          padding: ${padding}px;
          font-size: ${fontSize}px;
          line-height: ${lineHeight || 1.5};
        }`;

      return `${generatedClasses} ${newClass}`;
    }, '');

    return buildedTabStyles;
  };

  return `
      body a.dl-bubble-mobile {
        display: none;
      }
  
      #dl-bubble-text {
        font-family: 'Arial Black', Gadget, sans-serif;
        color: ${textColor};
        font-weight: bold;
      }
  
      #dl-bubble-text__tab {
        font-family: 'Rubik', sans-serif;
        line-height: 1.2;
        font-weight: normal;
        font-size: 15px;
        color: ${textColor};
      }
  
      #dl-bubble-text__tab-mobile {
        font-family: 'Rubik', sans-serif;
        font-weight: normal;
        line-height: 1.2;
        color: ${textColor};
      }
  
      @media (max-width: 767px) {
        body a.dl-bubble-mobile {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          bottom: ${bottom};
          left: 50%;
          position: fixed;
          z-index: ${zIndex};
          background: ${bgColor};
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          text-transform: uppercase;
          text-decoration: none;
          text-align: center;
          margin-left: -100px;
          width: 200px;
          -webkit-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
          padding: 16px 32px;
          border-radius: 8px;
        }
      }
  
      body a.dl-bubble:hover {
        color: ${textColor};
        text-decoration: none;
      }
  
      @media (max-width: 768px) {
        body a.dl-bubble {
          display: none;
        }
      }
  
      @media (min-width: 768px) {
        body a.dl-bubble {
          position: fixed;
          z-index: ${zIndex};
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          text-transform: uppercase;
          text-decoration: none;
          text-align: center;
          background: ${bgColor};
          -webkit-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
          margin-left: auto;
          left: ${left};
          right: ${right};
          bottom: ${bottom};
          width: 112px;
          height: 112px;
          border-radius: 50%;
        }
  
        .dl-bubble__basket {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          -webkit-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
          background: ${bgColor};
          background-size: 50%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
  
      .dl-bubble-tab__basket-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        -webkit-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
        background: #fff;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center center;
      }
  
      body a.dl-bubble-tab-mobile {
        position: fixed;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: space-evenly;
        text-transform: none;
        z-index: ${zIndex};
        text-decoration: none;
        text-align: center;
        background: ${bgColor};
        -webkit-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        margin-left: auto;
        left: ${leftTab};
        right: ${rightTab};
        bottom: ${bottom};
        width: 80px;
        height: 80px;
        border-radius: ${tabMargins};
      }
  
      body a.dl-bubble-tab {
        display: none;
      }
  
      .dl-bubble-tab__basket {
        display: none;
      }
  
      @media (min-width: 767px) {
        .dl-bubble-tab__basket-mobile {
          display: none;
        }
  
        body a.dl-bubble-tab-mobile {
          display: none;
        }
  
        body a.dl-bubble-tab {
          position: fixed;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: space-evenly;
          text-transform: none;
          z-index: ${zIndex};
          text-decoration: none;
          text-align: center;
          background: ${bgColor};
          -webkit-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
          margin-left: auto;
          left: ${leftTab};
          right: ${rightTab};
          bottom: ${bottom};
          width: 112px;
          height: 112px;
          border-radius: ${tabMargins};
        }
  
        .dl-bubble-tab__basket {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          -webkit-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
          background: #fff;
          background-size: 50%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
      ${createBubbleModifiers()}
  
      ${createTabModifiers()}
    `;
};

export default buildStyles;
