import React from 'react';
import AdmissionComment from './AdmissionComment';
import AdmissionCalendar from './AdmissionCalendar';
import AdmissionTimePicker from './AdmissionTimePicker';
import PropTypes from 'prop-types';
import {isSameMonth} from '@Utils/date';
import {isActivitySinglePickDate, isActivityWithDateTimes} from '@Utils/activity/activity';
import {useSelector} from 'react-redux';
import {getAdmissionDatesStatus} from '@Features/activityAdmissionDates/activityAdmissionDatesSelectors';
import {isProgress} from '@Utils/status';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const AdmissionDatePicker = ({
  datesGroupedByDay,
  activeView,
  setError,
  loading,
  calendarMonth,
  calendarYear,
  onMonthChange,
  sendAmplitudeEvent,
  dayOffers,
  selections,
  showCalendarPrices,
  showAvailableSeatsForDay,
  isTestB
}) => {
  const {items: activeViewBasketItems} = activeView;
  const basketItem = activeViewBasketItems[0];
  const {activity} = basketItem;
  const {passType, admissionPolicy} = activity;
  const {dates: selectedDates, time: selectedTime, dayOffer} = selections;
  const selectedDate = dayOffer ? dayOffer && datesGroupedByDay?.[0] :
    selectedDates?.length && selectedDates[0] || null;
  const areMonthsMatch = isSameMonth(calendarMonth, calendarYear, selectedDate);
  const showAdmissionComment = isActivitySinglePickDate(passType) && !!selectedDate?.group[0]?.description;
  const admissionDatesStatus = useSelector(getAdmissionDatesStatus);
  const showAdmissionTimePicker = isActivityWithDateTimes(passType) && !!selectedDate &&
    areMonthsMatch && !isProgress(admissionDatesStatus);

  return (
    <>
      <AdmissionCalendar
        datesGroupedByDay={datesGroupedByDay}
        activeViewBasketItems={activeViewBasketItems}
        setError={setError}
        loading={loading}
        onMonthChange={onMonthChange}
        sendAmplitudeEvent={sendAmplitudeEvent}
        dayOffers={dayOffers}
        showPrices={showCalendarPrices}
        showAvailableSeatsForDay={showAvailableSeatsForDay}
        isTestB={isTestB}
      />
      {
        showAdmissionComment && (
          <AdmissionComment
            description={selectedDate?.group[0]?.description}
            className="dl-mt-3"
          />
        )
      }

      {
        showAdmissionTimePicker && (
          <>
            <AdmissionTimePicker
              selectedTime={selectedTime}
              dayOffer={dayOffer}
              selectedDayDates={selectedDates ?? [selectedDate]}
              activeViewBasketItems={activeViewBasketItems}
              setError={setError}
              sendAmplitudeEvent={sendAmplitudeEvent}
              admissionPolicy={admissionPolicy}
              showPrices={showCalendarPrices}
              isTestB={isTestB}
            />

            {
              selectedTime?.description && (
                <AdmissionComment
                  description={selectedTime.description}
                  className="dl-mt-3"
                />
              )
            }
          </>
        )
      }
      {
        isProgress(admissionDatesStatus) && dayOffer && (
          <>
            {isTestB ? (
              <Box sx={loaderStyles}>
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <Box sx={{my: 4, display: 'flex', width: '100%', justifyContent: 'center'}}>
                <CircularProgress color="inherit" />
              </Box>
            )}
          </>
        )
      }
    </>
  );
};

AdmissionDatePicker.propTypes = {
  datesGroupedByDay: PropTypes.array,
  setError: PropTypes.func.isRequired,
  activeView: PropTypes.object.isRequired,
  dayOffers: PropTypes.array,
  selections: PropTypes.object,
  isTestB: PropTypes.bool
};

const loaderStyles = {
  marginTop: 6,
  marginBottom: 4,
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  color: 'grey.400'
};

export default AdmissionDatePicker;
