import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {useSelector} from 'react-redux';

export enum UrlParamsValues {
  TRUE = 'true',
  FALSE = 'false',
}

export const useUrlParams = (params: string[]) => {
  const {parentUrl} = useSelector(getConfiguration);
  const urlParams = new URLSearchParams(parentUrl ? parentUrl.split('?')[1] : '');

  return params.map(param => urlParams.get(param));
};
