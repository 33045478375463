import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import dayjs from 'dayjs';
import {addToDate, getStartOfYesterday, getTimezoneDate, isAfter, isSameDate} from './dayjs/dayjsUtils';

/**
 * @typedef NarrowedDateRange
 * @property {Date} minDate - start date
 * @property {Date} maxDate - end date
 */

/**
 * Return narrowed date range based on admission
 * @param {AdmissionPolicyProcessed} admissionPolicy - admission object
 * @param {string} timezone - facility timezone
 * @return {NarrowedDateRange} narrowed date range based on admission
 */
export const getNarrowedDateRangeBasedOnAdmission = ({endInDays, startDatetime, endDatetime}, timezone) => {
  const startOfYesterday = getStartOfYesterday(timezone);

  if (endInDays) {
    return {minDate: startOfYesterday, maxDate: addToDate(startOfYesterday, endInDays, 'day')};
  }

  if (endDatetime) {
    const maxDate = getTimezoneDate(endDatetime, timezone, true);

    if (startDatetime) {
      const startDateTimeWithTimezone = getTimezoneDate(startDatetime, timezone, true);

      if (
        isSameDate(startDateTimeWithTimezone, startOfYesterday) ||
        isAfter(startDateTimeWithTimezone, startOfYesterday)
      ) {
        return {minDate: startDateTimeWithTimezone, maxDate};
      }

      return {minDate: startOfYesterday, maxDate};
    }

    return {minDate: startOfYesterday, maxDate};
  }

  return {minDate: startOfYesterday, maxDate: addToDate(startOfYesterday, 1, 'month')};
};

/**
 * Checks if narrowed date range is valid
 * @param {Date} validSince - range start
 * @param {Date} validUntil - range end
 * @return {boolean} if date range is valid (can contain at least single day)
 */
export const isNarrowedDateRangeValid = (validSince, validUntil) =>
  dayjs(validUntil).isAfter(dayjs(validSince), 'day');
