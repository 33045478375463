import ResponseError from './responseError';
import RequestValidationError from './requestValidationErrror';
import {aggregateValidationErrors} from './apiError';
import {
  ERROR_TYPE_INVALID_COUNTRY_NIP,
  ERROR_TYPE_INVALID_COUNTRY_POSTAL_CODE,
  ERROR_TYPE_INVALID_RECIPIENT_COUNTRY_POSTAL_CODE,
  ERROR_TYPE_PAYMENT_COMPLETE_BEFORE_MINIMUM_DAYS,
  ERROR_TYPE_PAYMENT_COMPLETE_UNTIL_DAYS_LIMIT,
  REQUEST_VALIDATION_ERROR
} from '@Consts/apiError';
import locale from '@Utils/locale';

export const getErrorMessage = (status, type) => {
  if (status === 400 && type === 'missingPaymentProvider') {
    return locale.translate('missingPaymentProvider');
  }

  if (status === 400 && type === 'activitiesLimitExceeded') {
    return locale.translate('activitiesLimitExceeded');
  }

  if (status === 400 && (type === 'requestValidationError' || type === 'invalidPaymentData')) {
    return locale.translate('invalidDataOrCharacters');
  }

  if (status === 400 && type === 'invalidAdmissionDate') {
    return locale.translate('invalidAdmissionDate');
  }

  if (status === 400 && type === 'requiredActivityVariantsNotPresentInOrder') {
    return locale.translate('requiredActivityVariantsNotPresentInOrder');
  }

  if (status === 404 && type === 'orderNotFound') {
    return 'orderNotFound';
  }

  if (type === 'invalidTotalPrice') {
    return locale.translate('invalidPrice');
  }

  if (status === 400 && type === 'mismatchedTicketDate') {
    return locale.translate('mismatchedTicketDate');
  }

  if (status === 400 && type === 'ticketInactive') {
    return locale.translate('ticketInactive');
  }

  if (status === 400 && type === 'ticketAlreadyUsedToday') {
    return locale.translate('useTicketsErrorMoreDetails');
  }

  if (status === 400 && type === 'ticketAlreadyUsedException') {
    return locale.translate('useTicketsErrorMoreDetails');
  }

  if (status === 400 && type === 'ticketReturned') {
    return locale.translate('useTicketsErrorMoreDetails');
  }

  if (type === 'exceededTimeout') {
    return locale.translate('exceededTimeout');
  }

  return locale.translate('internalErrorMessage');
};

export const handleResponseError = (responseStatus, responseData) => {
  if (
    responseStatus === 400 &&
    responseData.type === REQUEST_VALIDATION_ERROR &&
    responseData.validation_errors.some(error => error.error === 'tooHigh')
  ) {
    throw new ResponseError(locale.translate('priceTooHigh'), responseStatus, responseData);
  }

  if (responseStatus === 400 && responseData.type === REQUEST_VALIDATION_ERROR) {
    throw new RequestValidationError(
      responseData.title,
      aggregateValidationErrors(responseData.validation_errors)
    );
  }

  if (responseStatus === 400 && responseData.type === ERROR_TYPE_PAYMENT_COMPLETE_UNTIL_DAYS_LIMIT) {
    throw new RequestValidationError(
      responseData.type,
      {paymentCompleteUntil: locale.translate('paymentCompleteUntilDaysLimitExceeded')}
    );
  }

  if (responseStatus === 400 && responseData.type === ERROR_TYPE_PAYMENT_COMPLETE_BEFORE_MINIMUM_DAYS) {
    throw new RequestValidationError(
      responseData.type,
      {paymentCompleteUntil: locale.translate('paymentCompleteUntilBeforeMinimumDateTime')}
    );
  }

  if (responseStatus === 400 && responseData.type === ERROR_TYPE_INVALID_COUNTRY_NIP) {
    throw new RequestValidationError(
      responseData.type,
      {invoiceCompanyNip: locale.translate('InvalidNipFormat')}
    );
  }

  if (responseStatus === 400 && responseData.type === ERROR_TYPE_INVALID_COUNTRY_POSTAL_CODE) {
    throw new RequestValidationError(
      responseData.type,
      {invoiceAddressPostalCode: locale.translate('invalidPostalCode')}
    );
  }

  if (responseStatus === 400 && responseData.type === ERROR_TYPE_INVALID_RECIPIENT_COUNTRY_POSTAL_CODE) {
    throw new RequestValidationError(
      responseData.type,
      {invoiceRecipientAddressPostalCode: locale.translate('invalidPostalCode')}
    );
  }

  throw new ResponseError(getErrorMessage(responseStatus, responseData.type), responseStatus, responseData);
};
