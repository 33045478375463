/* eslint-disable max-len */

export default {
  'multidayRangeInvalid': 'Wybrany zakres zawiera niedostępne terminy',
  'PLN': 'zł',
  'EUR': 'EUR',
  'PLNcalendar': 'zł',
  'EURcalendar': '€',
  'validPostalCodeLength': 'Podaj poprawny kod pocztowy (np. 00-000)',
  'minLength': 'Minimalna liczba znaków to: %{length}',
  'maxLength': 'Maksymalna liczba znaków to: %{length}',
  'parcelLockerSelected': 'Wybrany paczkomat:',
  'selectParcelLocker': 'Wybierz paczkomat',
  'selectAnotherParcelLocker': 'Wybierz inny paczkomat',
  'parcelLockerPointError': 'Nie udało się uzyskać poprawnego adresu paczkomatu',
  'enterDeliveryPhoneNumber': 'Podaj numer telefonu do dostawy',
  'onlineMethodsDescription': 'Dla pozostałych usług dostawa elektroniczna.',
  'shippingMethodRequired': 'Przed przejściem do płatności musisz wybrać sposób dostawy.',
  'chooseShippingMethod': 'Wybierz sposób dostawy',
  'generateWithAmount': 'Generuj (%{amountToPay})',
  'additionsHeader': 'Dodatki: %{activityName}',
  'additionsLimitReached': 'Osiągnięto limit dla dodatków',
  'perPersonNumber': '%{number} os. x',
  'selectedDateIsNotAvailable': 'Dzień %{date} jest niedostępny.',
  'errorOccurredWhileFetchingOrder': 'Nie udało się załadować zamówienia. Jeżeli je opłaciłeś, sprawdź swoją skrzynkę mailową.',
  'priceTooHigh': 'Wartość wybranych usług jest za wysoka. Nie można przejść do płatności.',
  'invalidNip': 'NIP jest niepoprawny',
  'orderCreationError': 'Wystąpił błąd podczas tworzenia zamówienia, spróbuj ponownie.',
  'activitiesFetchError': 'Wystąpił błąd podczas ładowania usług',
  'chooseLimitReached': 'Osiągnięto limit wybranych usług',
  'localTours': 'Wycieczki lokalne',
  'clauseAcceptanceRequired': 'Wymagana akceptacja klauzuli',
  'invalidPostalCode': 'Nieprawidłowy kod pocztowy',
  'invalidNIPLength': 'NIP musi mieć 10 cyfr',
  'invalidPhone': 'Niepoprawny numer telefonu',
  'invalidEmail': 'Niepoprawny adres e-mail',
  'invalidFormatError': 'Niepoprawny format',
  'toLongError': 'Maksymalna długość to 30 znaków',
  'toShortError': 'Minimalna długość to 3 znaki',
  'invalidTypeError': 'Nieprawidłowa wartość',
  'missingFieldError': 'Nie uzyskano wartości tego pola',
  'unknownError': 'Nieznany błąd',
  'errorScriptNotFound': 'Błąd - Twoja przeglądarka nie jest wspierana lub sklep został osadzony niepoprawnie',
  'addressRequired': 'Wymagane podanie danych adresowych',
  'regulationAcceptRequired': 'Wymagana akceptacja Regulaminu i Polityki prywatności',
  'regulationsAcceptRequired': 'Wymagana akceptacja Regulaminów i Polityki prywatności',
  'phoneRequired': 'Wymagane podanie numeru telefonu',
  'lastNameRequired': 'Wymagane podanie nazwiska',
  'firstNameRequired': 'Wymagane podanie imienia',
  'emailRequired': 'Wymagane podanie adresu e-mail',
  'answerRequired': 'Wymagane podanie odpowiedzi',
  'skippTheLine': 'Omiń kolejkę do kasy biletowej',
  'downloadInPreviewIsDisabled': 'W trybie podglądu zamówienie jest wysyłane na maila, nie można go pobierać bezpośrednio z tego ekranu',
  'noValidDatesRange': 'Brak dostępnego zakresu dat',
  'chooseNumberOfDays': 'Wybierz liczbę dni',
  'day': 'dzień',
  'perPersonAbbr': 'os.',
  'maxNumberOfParticipants': 'Maksymalna liczba uczestników to %{number}',
  'minNumberOfParticipants': 'Minimalna liczba uczestników to %{number}',
  'enterNumberOfParticipants': 'Podaj liczbę uczestników',
  'numberOfParticipants': 'Liczba uczestników',
  'numberOfParticipantsBetween': 'Liczba uczestników: od %{min} do %{max}',
  'generate': 'Generuj',
  'seats': 'szt.',
  'attention': 'Uwaga!',
  'forSinglePerson': 'za 1 os.',
  'chooseAdditions': 'Wybierz dodatki',
  'pleaseChooseOneOption': 'Wymagane zaznaczenie jednej z dostępnych opcji',
  'or': 'lub',
  'addToAppleWallet': 'Dodaj do Apple Wallet',
  'realiseOrder': 'Zrealizuj zamówienie',
  'generatingPdf': 'Trwa generowanie PDF...',
  'transactionCompletedSuccessfully': 'Transakcja zakończona pomyślnie!',
  'immediatelyProceedThroughPayment': 'Po uzyskaniu potwierdzenia dokonania płatności, wejściówki załadują się poniżej oraz zostaną wysłane na adres %p',
  'optionally': 'opcjonalne',
  'shopOnline': 'Sklep online',
  'orderOnline': 'Zamów online',
  'discountsAreDisabledInPreview': 'Kody rabatowe są wyłączone w trybie podglądu',
  'contactNumberToClient': 'Numer kontaktowy do osoby, która będzie realizować usługę - zbierany tylko i wyłącznie na potrzeby jej realizacji, np. konieczności kontaktu w wypadku sytuacji losowej.',
  'numberOnlyForRealisation': 'Na potrzeby realizacji usługi',
  'unsuccessfullyOrderedSending': 'Nie udało się wysłać potwierdzenia zamówienia na adres %{email}. Spróbuj ponownie lub napisz na pomoc@droplabs.pl',
  'successfullyOrderedSending': 'Poprawnie zlecono wysyłkę na adres %{email}',
  'requiredField': 'Pole wymagane',
  'forSendingAcceptTos': 'Na potrzebę wysyłki, klient akceptuje',
  'agreementFormWithClause': 'Formularz zgody wraz z Klauzulą informacyjną',
  'send': 'Wyślij',
  'downloadToPrint': 'Pobierz do druku',
  'clientEmail': 'Adres e-mail klienta',
  'chooseSendingConfirmationMethod': 'Gotowe! Wybierz sposób przekazania potwierdzenia klientowi',
  'orderPreviewInfo': 'Przykładowe zamówienie zostało wysłane na adres email: %p. Jeśli nie zobaczysz wiadomości w ciągu kilku minut, sprawdź folder ze spamem. Jeżeli to nie pomoże, wpisz "Droplabs" w wyszukiwarce w programie pocztowym.',
  'exceededTimeout': 'Przekroczono czas oczekiwania na płatność',
  'buyerWillSettleWithYou': 'Osoba, która będzie korzystać z usług %p, rozlicza się za nie z Tobą.',
  'youCanPrintTickets': 'Po zakupie od razu możesz wydrukować gotowe do realizacji wejściówki. Na wejściówkach nie będzie ceny.',
  'onlinePaymentForOrder': 'Należności za zamówienie regulujesz online. Faktura będzie wystawiona przez %p dla Twojej firmy za ustalony okres, najczęściej zbiorczo za cały miesiąc.',
  'youWillPayForOrderBasedOnInvoice': 'Należności za zamówienie uregulujesz na podstawie faktury, która będzie wystawiona przez %p dla Twojej firmy za ustalony okres, najczęściej zbiorczo za cały miesiąc.',
  'wholeAmountWillBeChargedForUSed': 'Pełna kwota zostanie naliczona przez %p tylko za wejściówki użyte. %p może nałożyć opłatę dodatkową, również za wejściówki nieużyte.',
  'youCanPrintReservationConfirmation': 'Po dokonaniu rezerwacji od razu możesz wydrukować jej potwierdzenie.',
  'paymentWillBeChargedOnSite': 'Pełna kwota zamówienia będzie musiała być uiszczona na miejscu, podczas realizacji usługi.',
  'missingFacilityId': 'Sklep został osadzony niepoprawnie',
  'cannotLoadFacility': 'Ładowanie sklepu nie powiodło się, spróbuj ponownie później.',
  'add': 'Dodaj',
  'choose': 'Wybierz',
  'choosing': 'Wybór',
  'hide': 'Zwiń',
  'expand': 'Rozwiń',
  'menuDetails': 'Szczegóły',
  'menuSummary': 'Opłać',
  'menuSummaryThanks': 'Dziękujemy za złożenie zamówienia!',
  'noActivitiesInSales': 'Brak usług w sprzedaży. Zapraszamy wkrótce!',
  'hours': 'godz.',
  'minutes': 'min',
  'goToTheNextStep': 'Przejdź dalej',
  'sellerOfActivities': 'Sprzedawcą jest %{name}.',
  'ifYouNeedHelpAssociatedWithOffer': 'Masz pytania związane z ofertą? Pisz na %p.',
  'saleSupportedByDroplabs': 'Droplabs - Sklep online, system sprzedaży i dystrybucji dla usług, biletów, voucherów i karnetów.',
  'ifYouNeedTechnicalSupport': 'Potrzebujesz pomocy technicznej? Pisz na %p',
  'activityAdmissionDateLabel': 'Termin realizacji: %{name}',
  'chooseDayOfUseActivities': 'Wybierz dzień realizacji usługi',
  'chooseTimeOfUseActivities': 'Wybierz czas realizacji usługi',
  'chooseNarrowedTimeOfUseActivities': 'Wybierz zawężenie czasu realizacji usługi',
  'chooseTime': 'Wybierz godzinę',
  'chooseEmailForSendingTickets': 'Na jaki adres wysłać potwierdzenie zakupu?',
  'email': 'Adres e-mail',
  'name': 'Imię',
  'buyersName': 'Imię kupującego',
  'surname': 'Nazwisko',
  'buyersSurname': 'Nazwisko kupującego',
  'goToThePayment': 'Przejdź do płatności',
  'orderFreeTickets': 'Zamawiam',
  'buyWithoutPayment': 'Kupuję z obowiązkiem zapłaty',
  'businessTicketsHaveBeenSentIntoEmail':
    'Wejściówka została wysłana na adres email: %p |||| Wejściówki zostały wysłane na adres email: %p',
  'checkSpamInCaseOfNotReceivingEmail':
    `Jeśli nie zobaczysz wiadomości w ciągu kilku minut od dokonania płatności, sprawdź folder ze spamem.
    Jeżeli to nie pomoże, wpisz "Droplabs" w wyszukiwarce w programie pocztowym.`,
  'buyAnotherActivities': 'Zamów kolejne usługi',
  'noFixedDatesForChosenActivities': 'Niestety, nie ma dostępnych dat dla wybranych wejściówek.',
  'forChosen': 'Dotyczy:',
  'chooseFixedDateBeforePayment': 'Przed przejściem do kolejnego kroku musisz wybrać datę dla wybranych wejściówek.',
  'chooseFixedDatetimeBeforePayment': 'Przed przejściem do kolejnego kroku musisz wybrać godzinę dla wybranej daty.',
  'noFixedDatesForChosenPeriod': 'Brak dostępnych dat w tym terminie.',
  'noFixedDatesOnDay': 'Brak dostępnych terminów w dniu %{date}.',
  'noFixedDatesBetweenDates': 'Brak dostępnych terminów w dniach %{since} - %{until}.',
  'noFixedDatesBeforeDate': 'Brak dostępnych terminów przed %{date}.',
  'noFixedDatesAfterDate': 'Brak dostępnych terminów po %{date}.',
  'next': 'Dalej',
  'back': 'Powrót',
  'onWebsite': 'na stronie www',
  'january': 'Styczeń',
  'february': 'Luty',
  'march': 'Marzec',
  'april': 'Kwiecień',
  'may': 'Maj',
  'june': 'Czerwiec',
  'july': 'Lipiec',
  'august': 'Sierpień',
  'september': 'Wrzesień',
  'october': 'Październik',
  'november': 'Listopad',
  'december': 'Grudzień',
  'sundayShort': 'Nd',
  'sunday': 'Niedziela',
  'mondayShort': 'Pn',
  'monday': 'Poniedziałek',
  'mondayTwoPart': 'Pn',
  'tuesdayTwoPart': 'Wt',
  'wednesdayTwoPart': 'Śr',
  'thursdayTwoPart': 'Cz',
  'fridayTwoPart': 'Pt',
  'saturdayTwoPart': 'So',
  'sundayTwoPart': 'Nd',
  'tuesdayShort': 'Wt',
  'tuesday': 'Wtorek',
  'wednesdayShort': 'Śr',
  'wednesday': 'Środa',
  'thursdayShort': 'Czw',
  'thursday': 'Czwartek',
  'fridayShort': 'Pt',
  'friday': 'Piątek',
  'saturdayShort': 'So',
  'saturday': 'Sobota',
  'canNotBuyMoreTickets': 'Nie można kupić więcej biletów. Brak dostępnych miejsc.',
  'willEndSoon': 'Niedługo wygasa!',
  'itemsLeft': 'Zostało %{limit}!',
  'willEndBefore': 'Oferta do %{date}!',
  'soldOut': 'Wyprzedane!',
  'bookingRequired': 'Po zakupie konieczna jest rezerwacja',
  'bookingAfterBuy': 'Po zakupie otrzymasz %p do rezerwacji',
  'phone': 'numer tel.',
  'website': 'adres www',
  'bookingEmail': 'adres e-mail',
  'more': 'więcej',
  'less': 'mniej',
  'from': 'Od',
  'durationUnlimitedSentence': 'Bez limitu czasu',
  'durationUnlimited': 'bez limitu czasu',
  'useNumber': 'Liczba wejść',
  'useDatetime': 'Realizacja',
  'betweenDates': 'od %{since} do %{until}',
  'onDate': 'dnia %{date}',
  'validBeforeDate': 'do %{date}',
  'validInDays': 'do %{number}',
  'today': 'dzisiaj',
  'numberOfDays': 'dzień |||| dni',
  'activeInDays': 'Ważność: %{number} dni od aktywacji',
  'activationInDays': 'Aktywacja w obiekcie %{days} po zakupie',
  'activationDates': 'Aktywacja w obiekcie %{dates}',
  'pickFixedDateInTheNextStep': 'Sprawdź wszystkie dostępne terminy w kolejnym kroku',
  'dateOfUse': 'Data realizacji',
  'withDiscountLabel': 'Masz kod rabatowy?',
  'optional': 'opcjonalnie',
  'discountCode': 'Kod rabatowy',
  'useDiscountCode': 'Użyj kod',
  'discountUsed': 'Kod rabatowy aktywny (%{code}):',
  'invalidDiscountCode': 'Podany kod (%{code}) nie obniża cen wybranych usług.',
  'discountCodeNotFound': 'Podany kod (%{code}) stracił ważność, jest niepoprawny lub został już użyty.',
  'discountAdditionsNotIncluded': 'Dodatki nie podlegają pod rabat.',
  'notEligibleDiscountTickets': 'Pozostałe wejściówki nie podlegają pod ten kod.',
  'close': 'zamknij',
  'tryAgain': 'spróbuj ponownie',
  'phoneNumber': 'Numer telefonu',
  'contactPhoneNumber': 'Kontaktowy numer telefonu',
  'clientPhoneNumber': 'Numer telefonu kupującego',
  'restCountries': 'Pozostałe kraje',
  'sellerIs': 'Sprzedawcą i',
  'administratorOfThePersonalData': 'Administratorem danych osobowych jest',
  'sellerCopy':
    `Twoje dane będą przetwarzane w szczególności w celu wykonywania umowy, tj. umożliwienia Ci zakupu biletu, na podstawie art. 6 ust. 1 lit. b RODO.
    Posiadasz prawo dostępu do treści swoich danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu oraz skargi do organu nadzorczego.
    Dane nie podlegają zautomatyzowanemu podejmowaniu decyzji, w tym profilowaniu.`,
  'infoAboutPrivacyPolicy': 'Pełna treść klauzuli informacyjnej dostępna jest tutaj',
  'businessSellerCopy': 'Sprzedawcą jest',
  'privacyPolicy': 'Polityka prywatności',
  'privacyPolicyAccept': 'Politykę prywatności',
  'termsOfServiceAgreement': 'Zapoznałem się i akceptuję',
  'termsOfService': 'Regulamin sprzedaży',
  'inTermsOfService': 'Regulaminie',
  'inPrivacyPolicy': 'Polityce prywatności',
  'and': 'oraz',
  'acceptedTermsOfServiceIn': 'Zaakceptowane warunki współpracy znajdują się w',
  'facilityTermsOfService': 'Regulamin %{facilityName}',
  'activitiesLimitExceeded':
    'Ktoś Cię ubiegł, w międzyczasie zmniejszyła się liczba dostępnych wejściówek. Wybierz inne.',
  'invalidDataOrCharacters': 'Dane są niepoprawne lub zawierają niedozwolone znaki. Popraw je i spróbuj jeszcze raz.',
  'invalidAdmissionDate': 'Data jest niepoprawna.',
  'internalErrorMessage': 'Wystąpił błąd, spróbuj ponownie za chwilę',
  'widgetDisabled': 'Sprzedaż online jest obecnie wyłączona.',
  'bubbleDisabled': 'Sprzedaż wyłączona!',
  'buyOnline': 'Kup online!',
  'buyVoucher': 'Kup voucher',
  'buyTicket': 'Kup bilet!',
  'bookingOnline': 'Rezerwuj online',
  'buySkipass': 'Kup skipass',
  'buyCourse': 'Kup kurs',
  'iWantInvoice': 'Chcę otrzymać fakturę',
  'address': 'Ulica i nr domu / lokalu',
  'city': 'Miasto',
  'postalCode': 'Kod pocztowy',
  'companyName': 'Nazwa firmy',
  'requiredOnlyForInvoices': 'Obowiązkowe tylko dla faktur na firmę',
  'companyNIP': 'NIP firmy',
  'optionalSentence': 'Opcjonalnie',
  'invalidPrice': 'Nie można przejść do płatności ponieważ widoczna cena różni się od rzeczywistej - proszę odświeżyć stronę w celu pobrania aktualnych danych.',
  'paymentDelayedInfo': 'Należności za zamówienie należy uregulować na podstawie faktury VAT, która będzie wystawiona przez %{companyName}.',
  'oncePerDay': 'Jedno wejście dziennie',
  'withoutLimit': 'Bez dziennego limitu wejść',
  'ticketsReady': 'Wejściówka jest gotowa! |||| Wejściówki są gotowe!',
  'pleaseWait': 'Proszę czekać...',
  'downloadTickets': 'Pobierz wejściówkę |||| Pobierz wejściówki',
  'download': 'Pobierz',
  'loadingTickets': 'Twoje wejściówki są w trakcie przygotowywania!',
  'doOrder': 'Zamawiam',
  'doReservation': 'Rezerwuję',
  'generateExampleOrder': 'Generuj przykładowe zamówienie',
  'hotelNight': 'doba',
  'chooseHotelNight': 'Wybierz okres pobytu noclegowego',
  'insertHotelNight': 'Podaj okres pobytu noclegowego',
  'chooseHotelNightDescription': 'Wejściówki przysługują gościom tylko na czas pobytu noclegowego.',
  'setNarrowedDates': 'Musisz wybrać datę początku oraz końca pobytu.',
  'endDateCantBeTheSameAsStart': 'Koniec pobytu musi być przynajmniej jeden dzień późniejszy niż początek pobytu.',
  'save': 'Zapisz',
  'insertYear': 'Podaj rok',
  'hour': 'godz.',
  'realiseInDay': 'Realizacja dnia %{date}',
  'realiseInDateWithTime': 'Realizacja dnia %{date}, godzinę wybierzesz w kolejnym kroku.',
  'missingPaymentProvider': 'Sprzedawca nie aktywował jeszcze przyjmowania płatności. Spróbuj ponownie wkrótce.',
  'seeOtherActivities': 'Zobacz pozostałe propozycje',
  'hideOtherActivities': 'Ukryj pozostałe propozycje',
  'givenDateIsSoldOut': 'Miejsca na %{date} zostały wyprzedane.',
  'isOutOfLimit': 'Brak wystarczającej liczby miejsc na godzinę %{time}.',
  'givenTimeIsSoldOut': 'Miejsca na godzinę %{time} zostały wyprzedane.',
  'hasNoSeatsForGivenDate': 'Brak wystarczającej liczby miejsc na %{date}.',
  'seatsSoldOut': 'wyprzedane',
  'hasNoSeats': 'brak miejsc',
  'chooseDifferentDate': 'Wybierz inny termin',
  'actuallySoldOut': 'Wyprzedane!',
  'noDates': 'Aktualnie brak terminów!',
  'customerData': 'Dane klienta',
  'optionalText': 'opcjonalnie',
  'numberOfParticipantsLabel': 'Liczba uczestników: %{name}',
  'avaliableNumber': 'Pozostało miejsc: %{number}',
  'filter': 'Filtr',
  'clear': 'Wyczyść',
  'checkItOut': 'Sprawdź to',
  'remove': 'Usuń',
  'thisMayInterestYou': 'To może Cię zainteresować',
  'requiredActivityVariantsNotPresentInOrder': 'Do zakupu wymagany jest inny wariant. Wróć do listy usług i wybierz odpowiedni wariant.',
  'requiredActivityVariants': 'Brakuje Ci czegoś w zamówieniu.',
  'backTo': 'Wróć do zakładki',
  'addSmall': 'dodaj',
  'requiredActivity': 'wymaganą usługę',
  'oneOfRequiredActivity': 'przynajmniej jedną z wymaganych usług',
  'oneOfRequiredActivityVariants': 'przynajmniej jeden z wymaganych wariantów usługi',
  'to': 'Do',
  'marketingConsentsAgreement': 'Chcę zapisać się do newslettera (Opcjonalnie)',
  'marketingConsentsPromo': 'Bądź na bieżąco ze wszelkimi promocjami i informacjami, wysyłamy tylko najważniejsze rzeczy, obiecujemy zero spamu!',
  'setAllConsents': 'Zaznacz wszystkie',
  'InvalidNipFormat': 'Niepoprawny NIP',
  'numberOfPeopleInGroup': 'Liczba osób w grupie',
  'paymentFormOutside': 'Forma płatności poza Droplabs',
  'other': 'Inna',
  'paymentTerminal': 'Terminal płatniczy',
  'cash': 'Gotówka',
  'bankTransfer': 'Przelew na konto',
  'prefix': 'Prefiks',
  'paymentCompleteUntilBeforeMinimumDateTime': 'Termin płatności jest za krótki',
  'paymentCompleteUntilDaysLimitExceeded': 'Termin płatności jest za długi',
  'setPaymentDate': 'Wyznacz termin płatności',
  'setPaymentDateDesc': 'Jeśli klient nie opłaci zamówienia we wskazanym terminie, miejsca wrócą do puli dostępnych miejsc.',
  'newOrder': 'Utwórz nowe zamówienie',
  'LastStep': 'Przed Tobą ostatni krok...',
  'copyLinkAndSendToCustomer': 'Skopiuj link i prześlij go klientowi, aby mógł opłacić zamówienie.',
  'orClick': 'lub kliknij',
  'CopyLink': 'Skopiuj link',
  'paymentOutside': 'Płatność poza Droplabs',
  'paymentOnline': 'Płatność online',
  'cancel': 'Anuluj',
  'setDateAndTime': 'Wybierz datę i czas',
  'privatePerson': 'Osoba prywatna',
  'company': 'Firma',
  'invoiceOptionalNote': 'Notatka do faktury (opcjonalne)',
  'youWillReceiveInvoiceSimplified': 'Otrzymasz paragon z NIP będący fakturą uproszczoną.',
  'nameOptional': 'Imię (opcjonalnie)',
  'surnameOptional': 'Nazwisko (opcjonalnie)',
  'emailOptional': 'Adres e-mail (opcjonalne)',
  'isExpired': 'stracił ważność',
  'isAdmissionDateInvalid': 'dotyczy innego terminu realizacji',
  'isInvalid': 'jest niepoprawny',
  'isUsed': 'jest użyty',
  'buyerData': 'Dane kupującego',
  'requiredEmailAlert': 'Pamiętaj, aby podać adres e-mail klienta, inaczej nie otrzyma on faktury.',
  'issueAnInvoice': 'Wystaw fakturę',
  'areYouLeaving': 'Wychodzisz już?',
  'exitConfirmationComment': 'Dokonując zakupu teraz, masz gwarancję niezmienności ceny. Jeśli wyjdziesz, utracisz dotychczas zrealizowane kroki.',
  'abandonPurchase': 'Porzuć zakup',
  'continueShopping': 'Kontynuuj zakupy',
  'thisFeatureIsNotEnabled': 'Ta funkcja jest niedostępna',
  'thisFeatureIsNotEnabledSolution': 'Nic straconego! Napisz do nas na %p, pomożemy Ci dobrać odpowiedni plan.',
  'droplabsHelpMail': 'pomoc@droplabs.pl',
  'pleaseWaitSummary': 'Proszę czekać',
  'waitingForPaymentConfirmation': 'Oczekujemy na potwierdzenie dokonania płatności.',
  'paymentProcessInterrupted': 'Jeśli proces płatności został przerwany, możesz opłacić zamówienie',
  'clickHere': 'klikając tutaj',
  'ticketsLoadedAndSentToEmail': 'Wejściówki załadują się poniżej oraz zostaną przesłane na email:',
  'paymentAccepted': 'Płatność przyjęta',
  'orderNumber': 'Numer zamówienia:',
  'orderSendOnEmail': 'Potwierdzenie zamówienia wysłano na email:',
  'emailNotGetThrough': 'Wiadomość nie dotarła?',
  'emailNotGetThroughTooltip': 'Jeśli nie zobaczysz wiadomości w ciągu kilku minut od dokonania płatności, sprawdź folder ze spamem. Jeżeli to nie pomoże, wpisz "Droplabs" w wyszukiwarce w programie pocztowym.',
  'orderCreated': 'Zamówienie stworzone',
  'generatingTickets': 'Trwa generowanie wejściówek',
  'generatingTicketsDescription': 'Wkrótce będziesz mógł pobrać swój bilet i dodać go do wirtualnego portfela. Wysłaliśmy także kopię tego samego biletu na podany adres e-mail.',
  'everythingDone': 'Wszystko gotowe!',
  'ticketsReadyToDownload': 'Wejściówki są gotowe do pobrania. Kliknij przycisk, by pobrać.',
  'showTickets': 'Pokaż wejściówki',
  'hideTickets': 'Ukryj wejściówki',
  'pdfDocumentIsReady': 'Sukces, PDF gotowy!',
  'clickForPdfDocument': 'PDF gotowy do pobrania. Kliknij przycisk, by pobrać.',
  'pdfDocumentIsGenerating': 'Trwa generowanie PDF',
  'pdfDocumentIsGeneratingDescription': 'Generowanie PDF może chwilę potrwać, nie musisz czekać.',
  'generating': 'Generowanie...',
  'sendOrder': 'Prześlij zamówienie',
  'sendOrderDescription': 'Wskaż adres e-mail na który ma zostać przesłane zamówienie wraz z wejściówką.',
  'orderSendedOnEmail': 'Zamówienie zostało przesłane na wskazany adres e-mail',
  'orderSomethingElse': 'Chcesz jeszcze coś zamówić?',
  'actionDisabledInPreviewMode': 'Nie można tego zrobić, ten widok jest tylko poglądowy.',
  'anotherRecipient': 'Chcę podać innego odbiorcę',
  'recipientName': 'Nazwa odbiorcy',
  'digitalShippingMethod': 'Wysyłka elektroniczna na wskazany adres mailowy',
  'somethingIsWrong': 'Coś poszło nie tak...',
  'waitingForPaymentError': 'Zamówienie zostało anulowane lub upłynął czas na opłacenie zamówienia. Wygeneruj nowe zamówienie lub ponów płatność.',
  'waitingForPayment': 'Oczekiwanie na płatność',
  'waitingForPaymentDesc': 'Na terminal Viva Wallet została wysłana kwota zamówienia. Przyjmij płatność jak tylko kwota pojawi się na ekranie terminala. Jeśli nie chcesz przyjąć płatności, anuluj transakcję.',
  'sendAgain': 'Wyślij ponownie',
  'paymentRepeatError': 'Nie udało się ponowić płatności',
  'paymentRepeatSucces': 'Płatność została ponowiona',
  'rePayment': 'Ponów płatność',
  'setTicketAsUsed': 'Oznaczenie wykorzystania',
  'setTicketAsUsedDescription': 'Zaloguj wykorzystanie wszystkich właśnie wygenerowanych wejściówek, ważnych w dniu dzisiejszym.',
  'useTickets': 'Zaloguj wykorzystanie',
  'useTicketsSuccess': 'Poprawnie zalogowano wejściówki.',
  'useTicketsError': 'Nie można zalogować wykorzystania.',
  'mismatchedTicketDate': 'Zamówienie dotyczy innego terminu realizacji.',
  'ticketInactive': 'Zamówienie jest nie aktywne.',
  'useTicketsErrorMoreDetails': 'Szczegóły zamówienia możesz zobaczyć przechodząc do sekcji "Obsługa klienta" lub "Kontrola dostępu".',
  'country': 'Kraj',
  'notSavedData': 'Dane nie zostaną zapisane',
  'notSavedDataDesc': 'Jeśli wprowadziłeś dodatkowe dane, pamiętaj, że przechodząc do wyboru usług, utracisz te informacje.',
  'changeActivities': 'Zmień usługi',
  'AD': 'Andora',
  'AE': 'Zjednoczone Emiraty Arabskie',
  'AF': 'Afganistan',
  'AG': 'Antigua i Barbuda',
  'AI': 'Anguilla',
  'AL': 'Albania',
  'AM': 'Armenia',
  'AO': 'Angola',
  'AQ': 'Antarktyda',
  'AR': 'Argentyna',
  'AS': 'Samoa Amerykańskie',
  'AT': 'Austria',
  'AU': 'Australia',
  'AW': 'Aruba',
  'AX': 'Wyspy Alandzkie',
  'AZ': 'Azerbejdżan',
  'BA': 'Bośnia i Hercegowina',
  'BB': 'Barbados',
  'BD': 'Bangladesz',
  'BE': 'Belgia',
  'BF': 'Burkina Faso',
  'BG': 'Bułgaria',
  'BH': 'Bahrajn',
  'BI': 'Burundi',
  'BJ': 'Benin',
  'BL': 'Saint-Barthélemy',
  'BM': 'Bermuda',
  'BN': 'Brunei',
  'BO': 'Boliwia',
  'BQ': 'Bonaire, Sint Eustatius i Saba',
  'BR': 'Brazylia',
  'BS': 'Bahamy',
  'BT': 'Bhutan',
  'BV': 'Wyspa Bouveta',
  'BW': 'Botswana',
  'BY': 'Białoruś',
  'BZ': 'Belize',
  'CA': 'Kanada',
  'CC': 'Wyspy Kokosowe',
  'CD': 'Demokratyczna Republika Konga',
  'CF': 'Republika Środkowoafrykańska',
  'CG': 'Kongo',
  'CH': 'Szwajcaria',
  'CI': 'Wybrzeże Kości Słoniowej',
  'CK': 'Wyspy Cooka',
  'CL': 'Chile',
  'CM': 'Kamerun',
  'CN': 'Chiny',
  'CO': 'Kolumbia',
  'CR': 'Kostaryka',
  'CU': 'Kuba',
  'CV': 'Republika Zielonego Przylądka',
  'CW': 'Curaçao',
  'CX': 'Wyspa Bożego Narodzenia',
  'CY': 'Cypr',
  'CZ': 'Czechy',
  'DE': 'Niemcy',
  'DJ': 'Dżibuti',
  'DK': 'Dania',
  'DM': 'Dominika',
  'DO': 'Dominikana',
  'DZ': 'Algieria',
  'EC': 'Ekwador',
  'EE': 'Estonia',
  'EG': 'Egipt',
  'EH': 'Sahara Zachodnia',
  'ER': 'Erytrea',
  'ES': 'Hiszpania',
  'ET': 'Etiopia',
  'FI': 'Finlandia',
  'FJ': 'Fidżi',
  'FK': 'Falklandy',
  'FM': 'Mikronezja',
  'FO': 'Wyspy Owcze',
  'FR': 'Francja',
  'GA': 'Gabon',
  'GB': 'Wielka Brytania',
  'GD': 'Grenada',
  'GE': 'Gruzja',
  'GF': 'Gujana Francuska',
  'GG': 'Guernsey',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GL': 'Grenlandia',
  'GM': 'Gambia',
  'GN': 'Gwinea',
  'GP': 'Gwadelupa',
  'GQ': 'Gwinea Równikowa',
  'GR': 'Grecja',
  'GS': 'Georgia Południowa i Sandwich Południowy',
  'GT': 'Gwatemala',
  'GU': 'Guam',
  'GW': 'Gwinea Bissau',
  'GY': 'Gujana',
  'HK': 'Hongkong',
  'HM': 'Wyspy Heard i McDonalda',
  'HN': 'Honduras',
  'HR': 'Chorwacja',
  'HT': 'Haiti',
  'HU': 'Węgry',
  'ID': 'Indonezja',
  'IE': 'Irlandia',
  'IL': 'Izrael',
  'IM': 'Man',
  'IN': 'Indie',
  'IO': 'Brytyjskie Terytorium Oceanu Indyjskiego',
  'IQ': 'Irak',
  'IR': 'Iran',
  'IS': 'Islandia',
  'IT': 'Włochy',
  'JE': 'Jersey',
  'JM': 'Jamajka',
  'JO': 'Jordania',
  'JP': 'Japonia',
  'KE': 'Kenia',
  'KG': 'Kirgistan',
  'KH': 'Kambodża',
  'KI': 'Kiribati',
  'KM': 'Komory',
  'KN': 'Saint Kitts i Nevis',
  'KP': 'Korea Północna',
  'KR': 'Korea Południowa',
  'KW': 'Kuwejt',
  'KY': 'Kajmany',
  'KZ': 'Kazachstan',
  'LA': 'Laos',
  'LB': 'Liban',
  'LC': 'Saint Lucia',
  'LI': 'Liechtenstein',
  'LK': 'Sri Lanka',
  'LR': 'Liberia',
  'LS': 'Lesotho',
  'LT': 'Litwa',
  'LU': 'Luksemburg',
  'LV': 'Łotwa',
  'LY': 'Libia',
  'MA': 'Maroko',
  'MC': 'Monako',
  'MD': 'Mołdawia',
  'ME': 'Czarnogóra',
  'MF': 'Saint-Martin',
  'MG': 'Madagaskar',
  'MH': 'Wyspy Marshalla',
  'MK': 'Macedonia Północna',
  'ML': 'Mali',
  'MM': 'Birma',
  'MN': 'Mongolia',
  'MO': 'Makau',
  'MP': 'Mariany Północne',
  'MQ': 'Martynika',
  'MR': 'Mauretania',
  'MS': 'Montserrat',
  'MT': 'Malta',
  'MU': 'Mauritius',
  'MV': 'Malediwy',
  'MW': 'Malawi',
  'MX': 'Meksyk',
  'MY': 'Malezja',
  'MZ': 'Mozambik',
  'NA': 'Namibia',
  'NC': 'Nowa Kaledonia',
  'NE': 'Niger',
  'NF': 'Wyspa Norfolk',
  'NG': 'Nigeria',
  'NI': 'Nikaragua',
  'NL': 'Holandia',
  'NO': 'Norwegia',
  'NP': 'Nepal',
  'NR': 'Nauru',
  'NU': 'Niue',
  'NZ': 'Nowa Zelandia',
  'OM': 'Oman',
  'PA': 'Panama',
  'PE': 'Peru',
  'PF': 'Polinezja Francuska',
  'PG': 'Papua-Nowa Gwinea',
  'PH': 'Filipiny',
  'PK': 'Pakistan',
  'PL': 'Polska',
  'PM': 'Saint-Pierre i Miquelon',
  'PN': 'Pitcairn',
  'PR': 'Portoryko',
  'PS': 'Palestyna',
  'PT': 'Portugalia',
  'PW': 'Palau',
  'PY': 'Paragwaj',
  'QA': 'Katar',
  'RE': 'Reunion',
  'RO': 'Rumunia',
  'RS': 'Serbia',
  'RU': 'Rosja',
  'RW': 'Rwanda',
  'SA': 'Arabia Saudyjska',
  'SB': 'Wyspy Salomona',
  'SC': 'Seszele',
  'SD': 'Sudan',
  'SE': 'Szwecja',
  'SG': 'Singapur',
  'SH': 'Wyspa Świętej Heleny',
  'SI': 'Słowenia',
  'SJ': 'Svalbard i Jan Mayen',
  'SK': 'Słowacja',
  'SL': 'Sierra Leone',
  'SM': 'San Marino',
  'SN': 'Senegal',
  'SO': 'Somalia',
  'SR': 'Surinam',
  'SS': 'Sudan Południowy',
  'ST': 'Wyspy Świętego Tomasza i Książęca',
  'SV': 'Salwador',
  'SX': 'Sint Maarten',
  'SY': 'Syria',
  'SZ': 'Suazi',
  'TC': 'Turks i Caicos',
  'TD': 'Czad',
  'TF': 'Francuskie Terytoria Południowe i Antarktyczne',
  'TG': 'Togo',
  'TH': 'Tajlandia',
  'TJ': 'Tadżykistan',
  'TK': 'Tokelau',
  'TL': 'Timor Wschodni',
  'TM': 'Turkmenistan',
  'TN': 'Tunezja',
  'TO': 'Tonga',
  'TR': 'Turcja',
  'TT': 'Trynidad i Tobago',
  'TV': 'Tuvalu',
  'TW': 'Tajwan',
  'TZ': 'Tanzania',
  'UA': 'Ukraina',
  'UG': 'Uganda',
  'UM': 'Dziewicze Wyspy Stanów Zjednoczonych',
  'US': 'Stany Zjednoczone',
  'UY': 'Urugwaj',
  'UZ': 'Uzbekistan',
  'VA': 'Watykan',
  'VC': 'Saint Vincent i Grenadyny',
  'VE': 'Wenezuela',
  'VG': 'Brytyjskie Wyspy Dziewicze',
  'VI': 'Wyspy Dziewicze Stanów Zjednoczonych',
  'VN': 'Wietnam',
  'VU': 'Vanuatu',
  'WF': 'Wallis i Futuna',
  'WS': 'Samoa',
  'YE': 'Jemen',
  'YT': 'Majotta',
  'ZA': 'Republika Południowej Afryki',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe',
  'nearestDate': 'Najbliższy dostępny termin',
  'nearestDateWithHour': '%{day} o godz. %{hour}',
  'tomorrow': 'jutro',
  'paymentMethod': 'Metoda płatności',
  'secureFormDescription': 'Zapłać szybkim przelewem / kartą płatniczą',
  'typeGeneratedBlikCode': 'Wpisz jednorazowy kod wygenerowany w\u00A0aplikacji bankowej',
  'blik': 'BLIK',
  'typeBlikCode': 'Wpisz kod BLIK',
  'blikCodePatternError': 'Kod musi zawierać 6 cyfr',
  'blikCode': 'Kod BLIK',
  'simplifiedInvoice': 'Uproszczona',
  'fullInvoice': 'Pełna',
  'privateInvoice': 'Imienna',
  'simplifiedInvoiceInformation': 'Klient otrzyma paragon z NIP będący fakturą uproszczoną. Można wystawić ją tylko dla firm zarejestrowanych w Polsce. Dla firm zagranicznych wybierz fakturę pełną.',
  'isNotPolishNip': 'Podany NIP nie jest polski.',
  'polishNip': 'Polski NIP',
  'checkTheBankingApp': 'Sprawdź aplikację bankową',
  'confirmPaymentWithPin': 'Teraz potwierdź płatność PINem za pomocą aplikacji w swoim telefonie.',
  'ticketsLoadedAfterConfirm': 'Wejściówki załadują się po zatwierdzeniu płatności oraz zostaną przesłane na e-mail:',
  'paymentWithBlik': 'Płacę BLIKIEM',
  'cannontLoadPage': 'Nie udało się załadować tej strony',
  'refreshPage': 'Odśwież stronę',
  'isSoldOut': 'Wyprzedane',
  'removeSelectedActivities': 'Usuń wybrane usługi',
  'removeSelectedServicesQuestion': 'Usunąć wybrane usługi?',
  'allServicesWillBeRemoved': 'Wszystkie usługi zostaną usunięte i wrócisz do ponownego wyboru'
};

/* eslint-enable max-len */
