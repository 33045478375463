import {Dayjs} from 'dayjs';
import locale from '@Utils/locale';
import {getTimezoneDate, isToday, isTomorrow} from '@Utils/dayjs/dayjsUtils';

const getDayOfWeek = (isToday: boolean, isTomorrow: boolean, date: Dayjs) => {
  if (isToday) {
    return locale.translate('today');
  }

  if (isTomorrow) {
    return locale.translate('tomorrow');
  }

  return `${date.format('dddd')}, ${date.format('DD.MM')}`;
};

export const getActivityNearestDate = (
  firstAdmissionDate: string,
  timezone: string,
  isActivityWithDateTime: boolean
) => {
  const comparedDate = getTimezoneDate(firstAdmissionDate, timezone);
  const hour = isActivityWithDateTime ? comparedDate.format('HH:mm') : null;
  const day = getDayOfWeek(isToday(comparedDate, timezone), isTomorrow(comparedDate), comparedDate);

  if (hour) {
    return locale.translate('nearestDateWithHour', {day, hour});
  }

  return day;
};

export const formatRealizationDate = (date: string, timezone: string, withHour: boolean) => {
  const day = getTimezoneDate(date, timezone).format('DD.MM.YYYY');

  if (withHour) {
    const hour = getTimezoneDate(date, timezone).format('HH:mm');

    return locale.translate('nearestDateWithHour', {day, hour});
  }

  return day;
};
