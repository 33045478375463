import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {
  getActivityLowestPriceVariant,
  isActivityWithDifferentPrices
} from '@Utils/activity/activity';
import locale from '@Utils/locale';
import PropTypes from 'prop-types';
import {
  getVariantDefaultCost,
  getVariantPriceWithCurrency,
  isVariantPricePerDay
} from '@Utils/activity/variant';
import Text from '@Components/common/text/Text';
import {ETextSize} from '@Components/common/text/types';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {useSelector} from 'react-redux';
import {getCatalogPrice} from '@Utils/variant';

const ActivityPrice = ({activity, activityHaveValidPrices}) => {
  const {
    variants,
    variantsDescription,
    passType
  } = activity;
  const lowestPriceVariant = getActivityLowestPriceVariant(variants);

  if (!activityHaveValidPrices) {
    return null;
  }

  if (lowestPriceVariant) {
    const withDifferentPrices = isActivityWithDifferentPrices(variants);
    const pricePerDay = isVariantPricePerDay(lowestPriceVariant);
    const cost = getVariantDefaultCost(lowestPriceVariant);
    const {currency} = {...cost};
    const {type: variantType} = lowestPriceVariant;
    const {widgetType} = useSelector(getConfiguration);
    const {catalogPrice, catalogPriceDescription} = getCatalogPrice({
      cost, variant: lowestPriceVariant, widgetType, variantsDescription
    });
    const {priceText, abbr} = !variantsDescription && cost ?
      getVariantPriceWithCurrency({cost: cost?.price, passType, pricePerDay, currency, variantType}) : {};

    return (
      <div className="dl-price-wrapper">
        <div className="dl-price dl-activity__price">
          {
            (!variantsDescription && withDifferentPrices) && (
              <Text size={ETextSize.MEDIUM} className="dl-price__label" dl-test="from-price">
                {`${locale.translate('from')}`.toLowerCase()}&nbsp;
              </Text>
            )
          }

          {
            variantsDescription ?
              (
                <Text
                  dl-test="variants-description"
                  size={ETextSize.MEDIUM}
                  className="dl-price__deal"
                >
                  {variantsDescription}
                </Text>
              ) : (
                <>
                  {
                    cost && (
                      <Text
                        dl-test="price"
                        size={ETextSize.LARGE}
                        className="dl-price__deal dl-price__deal--nowrap"
                      >
                        {priceText}
                        {
                          catalogPrice && (
                            <Text
                              dl-test="catalog-price"
                              size={ETextSize.MEDIUM}
                              className="dl-price__catalog-price"
                            >
                              {getVariantPriceWithCurrency({cost: cost?.catalogPrice, currency}).priceText}
                            </Text>
                          )
                        }
                        {
                          abbr &&
                          <Text
                            dl-test="abbr"
                            size={ETextSize.MEDIUM}
                            className="dl-price__label"
                          > / {abbr}
                          </Text>
                        }
                      </Text>
                    )
                  }
                </>
              )
          }
        </div>
        {
          catalogPriceDescription ?
            <div className="dl-catalog-price-description">
              <Text
                size={ETextSize.SMALL}
                dl-test="catalog-price-description"
              >
                {catalogPriceDescription}
              </Text>
            </div> : null

        }
      </div>
    );
  }

  return null;
};

ActivityPrice.propyTypes = {
  activity: PropTypes.shape({
    variants: PropTypes.array.isRequired,
    passType: PropTypes.string.isRequired,
    variantsDescription: PropTypes.string
  }).isRequired
};

export default ActivityPrice;
