import {IAddition, IQuestion} from '@Consts/apiGlobals';
import {IActivityProcessed} from '@Consts/globals';
import {EStatus} from '@Consts/status';

export enum EUpsellActionsTypes {
  SET_UPSELL_STATUS = 'SET_UPSELL_STATUS',
  SET_UPSELL_ACTIVITIES = 'SET_UPSELL_ACTIVITIES',
  SET_PICKED_UPSELL_ACTIVITY = 'SET_PICKED_UPSELL_ACTIVITY',
  SET_UPSELL_ACTIVITIES_ADDITONS = 'SET_UPSELL_ACTIVITIES_ADDITONS',
  SET_UPSELL_ACTIVITIES_QUESTIONS = 'SET_UPSELL_ACTIVITIES_QUESTIONS',
  SET_PICKED_UPSELL_ACTIVITIES_ADDITONS = 'SET_PICKED_UPSELL_ACTIVITIES_ADDITONS',
  SET_PICKED_UPSELL_ACTIVITIES_QUESTIONS = 'SET_PICKED_UPSELL_ACTIVITIES_QUESTIONS'
}

export type TUpsellActions = {
  type: EUpsellActionsTypes.SET_UPSELL_STATUS,
  payload: EStatus
} | {
  type: EUpsellActionsTypes.SET_UPSELL_ACTIVITIES,
  payload: IActivityProcessed[]
}| {
  type: EUpsellActionsTypes.SET_PICKED_UPSELL_ACTIVITY,
  payload: IActivityProcessed
} | {
  type: EUpsellActionsTypes.SET_UPSELL_ACTIVITIES_ADDITONS,
  payload: {
    activityId: number
    additions: IAddition[]
  }
} | {
  type: EUpsellActionsTypes.SET_UPSELL_ACTIVITIES_QUESTIONS,
  payload: {
    activityId: number
    questions: IQuestion[]
  }
} | {
  type: EUpsellActionsTypes.SET_PICKED_UPSELL_ACTIVITIES_ADDITONS,
  payload: {
    activityId: number
    additions: IAddition[]
  }
} | {
  type: EUpsellActionsTypes.SET_PICKED_UPSELL_ACTIVITIES_QUESTIONS,
  payload: {
    activityId: number
    questions: IQuestion[]
  }
}
