import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import {
  areActivityDatesInvalid,
  isActivityLimitReached,
  isActivitySingleGroupEvent
} from '@Utils/activity/activity';
import locale from '@Utils/locale';
import {useDispatch, useSelector} from 'react-redux';
import {prepareBasketItem, removeBasketItem, prepareBasketItemsSelections} from '@Features/basket/basketActions';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {getActivityBasketItems, getVariantBasketItems} from '@Utils/basket';
import {getVariantById} from '@Utils/activity/variant';
import {getFacility} from '@Features/facility/facilitySelectors';
import {isVariantGroup} from '@Utils/variantType';
import {removeBasketItemFromStorage} from '@Features/basket/basketUtils';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {getStorageBasketItems} from '@Features/storage/storageSelectors';
import {setPickedUpsellActivity} from '@Features/upsell/upsellActions';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import gtm from '@Utils/gtm/core';
import {useMatch} from 'react-router-dom';
import {CHECKOUT_PATH} from '@Utils/routerUtils';

const getPickerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
};

const getAmountPickerStyles = theme => ({
  height: '46px',
  width: '40%',
  minWidth: '46px',

  [theme.breakpoints.up('md')]: {
    width: '45%'
  }
});

const getAmountValueStyles = {
  height: '46px',
  padding: '10px 0',
  width: '10%',
  minWidth: '40px'
};

const getRemoveButtonStyles = {
  width: '100%',
  height: '46px',
  minWidth: '135px',
  fontWeight: '700'
};

const ActivityVariantPicker = ({activity, variantId, orderNumber}) => {
  const dispatch = useDispatch();
  const isCheckout = useMatch(CHECKOUT_PATH);
  const {id: activityId, passType, variants, isUpsellActivity, admissionPolicy} = activity;
  const variant = getVariantById(variants, variantId);
  const facility = useSelector(getFacility);
  const basketItems = useSelector(getBasketItems);
  const activityBasketItems = getActivityBasketItems(basketItems, activityId);
  const variantBasketItems = getVariantBasketItems(activityBasketItems, activityId, variantId);
  const numberOfItems = getNumberOfItems();
  const datesAreInvalid = areActivityDatesInvalid(activity);
  const {maximumTicketsInOrder} = facility.company;
  const disableReason = getPickerDisableReason();
  const {onlineGroupId} = useSelector(getConfiguration);
  const storageBasketItems = useSelector(getStorageBasketItems) || [];

  function getNumberOfItems() {
    if (variantBasketItems.length > 0) {
      if (isVariantGroup(variant?.type)) {
        return variantBasketItems[0].selections.numberOfSeats;
      }

      if (isActivitySingleGroupEvent(passType)) {
        return variantBasketItems[0].selections.numberOfParticipants;
      }
    }

    return variantBasketItems.length;
  }

  function getPickerDisableReason() {
    if (datesAreInvalid) {
      return locale.translate('noValidDatesRange');
    }

    if (basketItems?.length >= maximumTicketsInOrder) {
      return locale.translate('chooseLimitReached');
    }

    if (variant) {
      const selectedActivityBasketItemAmount = activityBasketItems
        .reduce(amount => amount + 1, 0);

      if (isActivityLimitReached(activity, selectedActivityBasketItemAmount)) {
        return locale.translate('canNotBuyMoreTickets');
      }

      if (isVariantGroup(variant?.type)) {
        return variantBasketItems[0]?.selections?.numberOfSeats >= variant.maxNumberOfSeats ?
          locale.translate('canNotBuyMoreTickets') : null;
      }

      if (isActivitySingleGroupEvent(passType)) {
        return variantBasketItems[0]?.selections?.numberOfParticipants >= admissionPolicy.maxNumberOfParticipants ?
          locale.translate('canNotBuyMoreTickets') : null;
      }
    }

    return null;
  }

  async function handleAddToBasket() {
    if (!disableReason) {
      if ((isVariantGroup(variant?.type) || isActivitySingleGroupEvent(passType)) && numberOfItems > 0) {
        if (variantBasketItems[0].selections?.numberOfSeats) {
          dispatch(prepareBasketItemsSelections(
            [variantBasketItems[0]],
            {numberOfSeats: variantBasketItems[0].selections.numberOfSeats + 1}
          ));
        } else {
          dispatch(prepareBasketItemsSelections(
            [variantBasketItems[0]],
            {numberOfParticipants: variantBasketItems[0].selections.numberOfParticipants + 1}
          ));
        }
      } else {
        dispatch(prepareBasketItem({activity, variantId, orderNumber}, false, isCheckout));
      }
    }

    if (isUpsellActivity) {
      dispatch(setPickedUpsellActivity(activity));
    }
    return null;
  }

  function handleRemoveFromBasket() {
    const basketItem = getVariantBasketItems(basketItems, activityId, variantId)[0];

    if (isVariantGroup(variant?.type) && numberOfItems > variant.minNumberOfSeats) {
      return dispatch(prepareBasketItemsSelections(
        [variantBasketItems[0]],
        {numberOfSeats: variantBasketItems[0].selections.numberOfSeats - 1}
      ));
    }

    if (isActivitySingleGroupEvent(passType) && numberOfItems > admissionPolicy.minNumberOfParticipants) {
      return dispatch(prepareBasketItemsSelections(
        [variantBasketItems[0]],
        {numberOfParticipants: variantBasketItems[0].selections.numberOfParticipants - 1}
      ));
    }

    dispatch(removeBasketItemFromStorage(activityId, variantId, onlineGroupId, storageBasketItems));
    gtm.removeFromCart(facility, basketItem);

    return dispatch(removeBasketItem(activityId, variantId));
  }

  return (
    <Box sx={{maxWidth: '134px'}}>
      {
        numberOfItems ? (
          <Box sx={getPickerStyles}>
            <Button
              data-dl-scroll="true"
              dl-test="remove-btn"
              onClick={handleRemoveFromBasket}
              variant="contained"
              sx={getAmountPickerStyles}
            >
              <RemoveRoundedIcon />
            </Button>
            <Typography
              align="center"
              sx={getAmountValueStyles}
            >
              {numberOfItems}
            </Typography>
            <Box
              sx={getAmountPickerStyles}
            >
              <Tooltip
                title={disableReason}
                enterTouchDelay={50}
                placement="top"
                arrow
              >
                <span className="dl-display-block">
                  <Button
                    dl-test="plus-btn"
                    variant="contained"
                    onClick={handleAddToBasket}
                    disabled={!!disableReason}
                    sx={{
                      minWidth: '46px', width: '100%', height: '100%'
                    }}
                  >
                    <AddOutlinedIcon />
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        ) : (
          <Tooltip
            title={disableReason}
            placement="top"
            enterTouchDelay={50}
            arrow
          >
            <span className="dl-display-block dl-w-100">
              <Button
                dl-test="add-btn"
                variant="contained"
                onClick={handleAddToBasket}
                disabled={!!disableReason}
                sx={getRemoveButtonStyles}
              >
                {locale.translate('add')}
              </Button>
            </span>
          </Tooltip>
        )
      }
    </Box>
  );
};

ActivityVariantPicker.propTypes = {
  activity: PropTypes.object.isRequired,
  variantId: PropTypes.number.isRequired,
  orderNumber: PropTypes.number.isRequired
};

export default ActivityVariantPicker;
