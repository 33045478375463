
import {IActivityVariant, ICost} from '@Consts/apiGlobals';
import {EWidgetType} from '@Consts/globals';
import {
  isWidgetBusiness,
  isWidgetBusinessPreview,
  isWidgetOnlineClient,
  isWidgetOnlineClientPreview
} from './widgetType';

interface IGetCatalogPriceProps {
  cost: ICost,
  variant: IActivityVariant,
  widgetType: EWidgetType,
  variantsDescription?: string | null
}

export const getCatalogPrice = ({
  cost,
  variant,
  widgetType,
  variantsDescription
}: IGetCatalogPriceProps) => {
  const catalogPriceDescription = variant?.catalogPriceDescription || null;
  const catalogPriceCouldBeShown = !variantsDescription && cost?.catalogPrice;

  if (!catalogPriceCouldBeShown) {
    return {catalogPrice: null, catalogPriceDescription: null};
  }

  if (isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType)) {
    return {
      catalogPrice: catalogPriceDescription ? cost?.catalogPrice : null,
      catalogPriceDescription: catalogPriceDescription ?? null
    };
  }

  if (isWidgetBusiness(widgetType) || isWidgetBusinessPreview(widgetType)) {
    return {
      catalogPrice: cost?.catalogPrice,
      catalogPriceDescription
    };
  }

  return {
    catalogPrice: null,
    catalogPriceDescription: null
  };
};
