import * as Sentry from '@sentry/react';
import {v4 as uuidv4} from 'uuid';

export const initSentry = () => {
  if (__SENTRY_ENABLED__) {
    Sentry.init({
      debug: __SENTRY_ENVIRONMENT__ === 'dev',
      dsn: __SENTRY_DSN__,
      environment: __SENTRY_ENVIRONMENT__,
      integrations: []
    });
  }
};

export const trackEvent = (event: string, data: Record<string, any> = {}) => {
  if (__SENTRY_ENABLED__) {
    Sentry.captureEvent({
      event_id: uuidv4(),
      message: event,
      extra: data
    });
  }
};
