import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '../../utils/locale';
import Text from '../common/text/Text';
import ActivityContent, {SPACING_TYPE} from '../activities/activity/ActivityContent';
import {getAdmissionSuitableDateDescription} from '@Utils/activity/activity';
import {ETextColor} from '@Components/common/text/types';
import {isVariantGroup} from '@Utils/variantType';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const ActivityMultipleActivation = ({basketItem}) => {
  const {
    activity: {admissionPolicy},
    selections: {numberOfSeats},
    variant
  } = basketItem;
  const {endInDays, activeDescription, activeInDays, endDescription} = admissionPolicy;
  const suitableDate = getAdmissionSuitableDateDescription(admissionPolicy);
  const activationText = endInDays ?
    locale.translate('activationInDays', {days: suitableDate}) :
    locale.translate('activationDates', {dates: suitableDate});

  return (
    <div>
      <ActivityContent spacing={SPACING_TYPE.TOP}>
        <CalendarMonthOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
        <Text color={ETextColor.LIGHT}>
          {
            activeDescription ?
              activeDescription : locale.translate('activeInDays', {number: activeInDays})
          }
        </Text>
      </ActivityContent>

      {isVariantGroup(variant?.type) && (
        <ActivityContent spacing={SPACING_TYPE.TOP}>
          <PeopleAltOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
          <Text color={ETextColor.LIGHT}>
            {locale.translate('numberOfPeopleInGroup')}: {numberOfSeats}
          </Text>
        </ActivityContent>
      )}

      <ActivityContent spacing={SPACING_TYPE.TOP}>
        {
          <CalendarMonthOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
        }

        <Text color={ETextColor.LIGHT}>
          {endDescription || activationText}
        </Text>
      </ActivityContent>
    </div>
  );
};

export default ActivityMultipleActivation;
