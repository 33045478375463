import {useTheme} from '@mui/material/styles';
import React from 'react';
import Typography from '@mui/material/Typography';
import {useSelector} from 'react-redux';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import locale from '@Utils/locale';
import {EPassType} from '@Consts/apiGlobals';
import {getActivityNearestDate} from '@Utils/activity/nearestDate';
import {isActivityWithDateTimes} from '@Utils/activity/activity';

type Props = {
  firstAdmissionDate: string
  passType: EPassType;
}

const ActivityNearestDate = ({firstAdmissionDate, passType}: Props) => {
  const theme = useTheme();
  const timezone = useSelector(getFacilityTimezone)!;
  const isActivityWithDateTime = isActivityWithDateTimes(passType);
  const nearestDate = getActivityNearestDate(firstAdmissionDate, timezone, isActivityWithDateTime);

  return (
    <Typography variant="body2" sx={{color: theme.palette.success.main, fontWeight: 500, mb: 1}} >
      {locale.translate('nearestDate')}: {nearestDate}
    </Typography>
  );
};

export default ActivityNearestDate;
