import useFrameMessage from '@Hooks/useFrameMessage';
import {useMediaQuery, useTheme} from '@mui/material';

export enum VerticalAlignment {
  TOP = 'top',
  CENTER = 'center',
}

export const useScroll = () => {
  const theme = useTheme();
  const frameMessage = useFrameMessage();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function scrollToElement(element: Element) {
    if (!element) {
      return;
    }
    const elementRect = element.getBoundingClientRect();
    let iframeElementYCord;
    let verticalAlignment;

    if (!isMobile && elementRect.height > 1000) {
      iframeElementYCord = elementRect.top;
      verticalAlignment = VerticalAlignment.TOP;
    } else if (isMobile && elementRect.height > 650) {
      iframeElementYCord = elementRect.top;
      verticalAlignment = VerticalAlignment.TOP;
    } else {
      iframeElementYCord = elementRect.height / 2 + elementRect.top;
      verticalAlignment = VerticalAlignment.CENTER;
    }

    frameMessage({
      name: 'scrollRef',
      iframeElementYCord,
      verticalAlignment
    });
  }

  function scrollToElementCenter(element: Element | null) {
    if (!element) {
      return;
    }
    const elementRect = element.getBoundingClientRect();
    const iframeElementYCord = elementRect.height / 2 + elementRect.top;
    const verticalAlignment = VerticalAlignment.CENTER;

    frameMessage({
      name: 'scrollRef',
      iframeElementYCord,
      verticalAlignment
    });
  }

  return {scrollToElement, scrollToElementCenter};
};
