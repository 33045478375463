import {EPaymentMethod} from '@Components/checkout/types';
import {EWidgetType} from '@Consts/globals';
import {getTimezoneDate, getFormattedDate} from './dayjs/dayjsUtils';
import {isWidgetInternal, isWidgetInternalPreview, isWidgetOnlineClient} from './widgetType';

export const getBlikCodeMutable = (blikCode?: string, paymentMethod?: EPaymentMethod) => {
  if (paymentMethod === EPaymentMethod.BLIK && blikCode) {
    return blikCode.replace(' ', '');
  }
};

export const getPaymentMethodMutable = (
  widgetType: EWidgetType,
  basketValue: number,
  paymentMethod?: string,
  isPaymentOnline?: boolean
) => {
  if (isWidgetOnlineClient(widgetType) && paymentMethod) {
    return paymentMethod;
  }

  const isWidgetInternalType = isWidgetInternal(widgetType) || isWidgetInternalPreview(widgetType);

  if (isWidgetInternalType && paymentMethod !== EPaymentMethod.OTHER && basketValue && !isPaymentOnline) {
    return paymentMethod;
  }
};

export const getPaymentCompleteUntil = (
  timezone: string,
  isPaymentOnline?: boolean,
  paymentCompleteUntil?: string
) => {
  if (isPaymentOnline && paymentCompleteUntil) {
    return getFormattedDate(getTimezoneDate(paymentCompleteUntil, timezone));
  }
};
