import React, {useEffect, useRef, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';
import ActivityPreviewTop from './ActivityPreviewTop';
import ActivityPreviewBottom from './ActivityPreviewBottom';
import ActivityPreviewAdditions from './ActivityPreviewAdditions';
import {getBasketItemAdditionsPrice} from '@Utils/basket';
import Ticket from '@Components/ticket/Ticket';
import PriceCount from '@Components/price/PriceCount';
import Text from '@Components/common/text/Text';
import PriceDeal from '@Components/price/PriceDeal';
import './activityPreview.scss';
import {getFormattedPrice} from '@Utils/format';
import {getAdditionsChangeStatus} from './utils';
import {ECheckoutViewsTypes} from '@Utils/checkoutViews/checkoutViewsConsts';
import locale from '@Utils/locale';
import {ETextColor, ETextDecoration, ETextSize} from '@Components/common/text/types';
import {isPriceValid} from '@Utils/validate';
import {useSelector} from 'react-redux';
import {getActivityQuestionsStatus} from '@Features/activityQuestions/questionsSelectors';
import {getActivityAdditionsStatus} from '@Features/activityAdditions/additionsSelectors';
import {isProgress} from '@Utils/status';
import {getBasketItemPrice} from '@Utils/basketItemPrice';

const ActivityPreview = ({
  preview,
  isSelected,
  numberOfItems,
  onScroll,
  firstSelectedId,
  activeView
}) => {
  const {items, id: previewId} = preview;
  const basketItemSample = items[0];
  const {activityId} = preview;
  const {
    activity,
    variant,
    selections: {numberOfSeats: selectionNumberOfSeats, numberOfParticipants}
  } = basketItemSample;
  const {numberOfSeats} = variant;
  const [additionsState, setAdditionsState] = useState([]);
  const ticketRef = useRef(null);
  const additionsRef = useRef(null);
  const additions = items.reduce((additions, basketItem) => {
    const itemAdditions = basketItem?.selections?.additions;

    if (itemAdditions?.length) {
      additions.push(...itemAdditions);
    }

    return additions;
  }, []);
  const isSingleItem = numberOfItems === 1 && additions?.length === 0;
  const {name} = activity;
  const basketItemPrice = getBasketItemPrice(basketItemSample);
  const {price: itemPrice, currency: itemCurrency} = {...basketItemPrice};
  const basketItemDiscounted = getBasketItemPrice(basketItemSample, true);
  const {price: itemPriceWithDiscount} = {...basketItemDiscounted};
  const isDiscounted = Boolean(itemPrice !== itemPriceWithDiscount);
  const currencyText = itemCurrency && locale.translate(`${itemCurrency}`);
  const activityQuestionsStatus = useSelector(state => getActivityQuestionsStatus(state, activityId));
  const activityAdditionsStatus = useSelector(state => getActivityAdditionsStatus(state, activityId));
  const isPreviewInProgress = isProgress(activityQuestionsStatus) ||
    isProgress(activityAdditionsStatus);
  const disabled = !isSelected || isPreviewInProgress;

  const scrollEffect = () => {
    if (additionsRef?.current && activeView?.type === ECheckoutViewsTypes.ADDITIONS) {
      const MARGIN = 50;
      const position = additionsRef.current.offsetLeft - MARGIN;

      return onScroll(position);
    }

    if (ticketRef?.current) {
      const MARGIN = 12;
      const position = ticketRef.current.offsetLeft - MARGIN;

      return onScroll(position);
    }
  };

  useEffect(() => {
    if (!activeView && firstSelectedId === previewId) {
      return scrollEffect();
    }

    const hasAdditionsChanged = getAdditionsChangeStatus(additions, additionsState);

    if (hasAdditionsChanged && isSelected) {
      setAdditionsState(additions);
      return scrollEffect();
    }

  }, [additions, firstSelectedId]);

  useEffect(() => {
    if (firstSelectedId === previewId) {
      return scrollEffect();
    }
  }, [activeView]);

  return (
    <>
      <Ticket
        id={`activity-preview-${preview.id}`}
        ref={ticketRef}
        isLoading={isPreviewInProgress}
        topContent={(
          <ActivityPreviewTop
            basketItem={basketItemSample}
          />
        )}
        bottomContent={(
          <ActivityPreviewBottom activityName={name}>
            <PriceCount>
              <Text color={ETextColor.LIGHT}>{items.length} x</Text>
            </PriceCount>

            <PriceDeal>
              <Text size={ETextSize.LARGE} >
                {
                  isPriceValid(itemPrice) ?
                    `${getFormattedPrice(itemPriceWithDiscount)} ${currencyText}` :
                    '--'
                }
                {
                  isDiscounted && (
                    <span>
                      &nbsp;
                      <Text decoration={ETextDecoration.DELETED}>
                        {getFormattedPrice(itemPrice)} {currencyText}
                      </Text >
                    </span>
                  )
                }
              </Text>
            </PriceDeal>
          </ActivityPreviewBottom>
        )}
        className={classNames(
          'l-activity-preview',
          {
            'is-disabled': disabled,
            'l-activity-preview--single': isSingleItem
          }
        )}
      />

      {
        additions?.length ? (
          <ActivityPreviewAdditions
            id={`activity-preview-additions-${preview.id}`}
            previewId={preview.id}
            additions={additions}
            additionsCost={getBasketItemAdditionsPrice(basketItemSample)?.price}
            additionsCurrency={getBasketItemAdditionsPrice(basketItemSample)?.currency}
            numberOfSeats={numberOfParticipants || numberOfSeats || selectionNumberOfSeats}
            activity={activity}
            disabled={disabled}
            additionsRef={additionsRef}
            className={classNames(
              'l-activity-preview',
              'l-activity-preview--additions',
              {
                'is-disabled': disabled
              }
            )}
          />
        ) : null
      }
    </>
  );
};

export default ActivityPreview;
