import React from 'react';
import locale from '@Utils/locale';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

interface ISnackBarInfo {
  isSnackbarOpen: boolean,
  closeSnackBar: () => void,
  isReinitializeOrderError: boolean
}

const SnackBarInfo = ({isSnackbarOpen, closeSnackBar, isReinitializeOrderError}: ISnackBarInfo) => (
  <Snackbar
    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    open={isSnackbarOpen}
    autoHideDuration={5000}
    onClose={closeSnackBar}
  >
    <Alert severity={isReinitializeOrderError ? 'error' : 'success'}>
      {
        isReinitializeOrderError ?
          locale.translate('paymentRepeatError') : locale.translate('paymentRepeatSucces')
      }
    </Alert>
  </Snackbar>
);

export default SnackBarInfo;
