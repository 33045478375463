import React, {useEffect, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useSelector} from 'react-redux';
import {getConfiguration, getIndicatedActivityId} from '@Features/configuration/configurationSelectors';
import locale from '@Utils/locale';
import Text from '@Components/common/text/Text';
import ActivityItem from './activity/ActivityItem';
import PropTypes from 'prop-types';
import './activities.scss';
import ActivitiesList from './ActivitiesList';
import Basket from '@Components/basket/Basket';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {ETextAlign, ETextSize} from '@Components/common/text/types';
import FilterTags from '@Components/filterTags/FilterTags';
import {getActivitiesTags, getActivitiesByPriority, getFilteredActivitiesByTag} from './activitiesListUtils';
import FlexBox from '@Components/common/flexBox/FlexBox';
import {EFlexDirection, EJustifyContent} from '@Components/common/flexBox/types';
import {getFacility} from '@Features/facility/facilitySelectors';
import {parseLanguage} from '@Utils/eventsUtils';
import pubsub from '@Utils/pubsub';
import {EVENT_VIEW_ACTIVITIES} from '@Utils/events';
import {useLocation, useNavigate} from 'react-router-dom';
import {useScroll} from '@Utils/hooks/useScroll';
import {WidgetMode} from '../../widget/types';

const ActivitiesContent = ({activities}) => {
  const indicatedActivityId = useSelector(getIndicatedActivityId);
  const {facilityId, affiliationHash, onlineGroupId, isModal, parentUrl} = useSelector(getConfiguration);
  const restActivities = [...activities];
  const numberOfActivities = activities.length;
  const indicatedActivity = getIndicatedActivity();
  const [listIsVisible, setListVisibility] = useState(false);
  const [activeTag, setActiveTag] = useState(null);
  const basketItems = useSelector(getBasketItems);
  const indicatedActivityListIndex = indicatedActivityId ? 1 : 0;
  const facility = useSelector(getFacility);
  const {name: facilityName, company} = facility;
  const {industry} = company;
  const activitiesByPriority = getActivitiesByPriority(restActivities);
  const facilityTags = getActivitiesTags(activitiesByPriority);
  const {state: locationState} = useLocation();
  const navigate = useNavigate();
  const {scrollToElement} = useScroll();

  function initializeActivitiesEffect() {
    pubsub.trigger(EVENT_VIEW_ACTIVITIES, {
      facilityId,
      facilityName,
      numberOfActivities,
      language: parseLanguage(locale.locale),
      industry,
      affiliationHash,
      onlineGroupId,
      activityId: indicatedActivityId,
      url: parentUrl,
      widgetMode: isModal ? WidgetMode.modal : WidgetMode.inline
    });
  }

  useEffect(() => {
    initializeActivitiesEffect();

    if (locationState?.scrollToActivities) {
      setTimeout(() => {
        const minusButton = document.querySelector('[data-dl-scroll]');

        if (minusButton) {
          scrollToElement(minusButton);
          navigate('', {}); // clear location state to avoid scrolling again
        }
      }, 100);
    }
  }, []);

  function handleListToggle() {
    return setListVisibility(!listIsVisible);
  }

  function getIndicatedActivity() {
    if (indicatedActivityId) {
      const indicatedActivityIndex = activities.findIndex(({id}) => id === indicatedActivityId);

      if (indicatedActivityIndex >= 0) {
        return restActivities.splice(indicatedActivityIndex, 1)[0];
      }

      return null;
    }

    return null;
  }

  return (
    <>
      {
        !!numberOfActivities &&
        !indicatedActivityId &&
        facilityTags &&
          <FilterTags
            setActiveTag={setActiveTag}
            activeTag={activeTag}
            facilityTags={facilityTags}
          />
      }

      <FlexBox
        className="dl-activities"
        justify={EJustifyContent.CENTER}
        direction={EFlexDirection.COLUMN}
      >
        {
          numberOfActivities ? (
            <>
              {
                indicatedActivity && (
                  <div>
                    <ActivityItem
                      activity={indicatedActivity}
                      orderNumber={indicatedActivityListIndex}
                      indicated
                      numberOfActivities={numberOfActivities}
                    />

                    {
                      numberOfActivities > 1 && (
                        <div
                          className="dl-activities__expand-button"
                          onClick={handleListToggle}
                        >
                          <span>
                            {locale.translate(listIsVisible ? 'hideOtherActivities' : 'seeOtherActivities')}
                          </span>
                        </div>
                      )
                    }
                  </div>
                )
              }

              <div id="dl-activities-list" role="listbox">
                {
                  (!indicatedActivity || listIsVisible) &&
                  <ActivitiesList
                    activities={getFilteredActivitiesByTag(activitiesByPriority, activeTag)}
                    indicatedActivityListIndex={indicatedActivityListIndex}
                    numberOfActivities={numberOfActivities}
                  />
                }
              </div>

              {
                basketItems?.length ? <Basket isModal={isModal} /> : null
              }
            </>
          ) : (
            <Text
              size={ETextSize.MEDIUM}
              align={ETextAlign.CENTER}
            >
              {locale.translate('noActivitiesInSales')}
            </Text>
          )
        }
      </FlexBox>
    </>
  );
};

ActivitiesContent.propTypes = {
  activities: PropTypes.array.isRequired
};

export default ActivitiesContent;
