import {SUCCESS, FAILURE, IN_PROGRESS} from '@Consts/status';
import {apiGet} from '@Utils/api/api';
import ResponseError from '@Utils/api/errors/responseError';
import {EVENT_INITIALIZE_ERROR} from '@Utils/events';
import locale from '@Utils/locale';
import pubsub from '@Utils/pubsub';
import {getConfiguration} from '../configuration/configurationSelectors';
import {getFacilityEndpoint} from './facilityUtils';

export const SET_FACILITY_STATUS = 'SET_FACILITY_STATUS';
export const SET_FACILITY_DATA = 'SET_FACILITY_DATA';

export const setFacilityStatus = status => ({
  type: SET_FACILITY_STATUS,
  payload: {status}
});

export const setFacilityData = facility => ({
  type: SET_FACILITY_DATA,
  payload: {facility}
});

export const fetchFacility = () => async (dispatch, getState) => {
  const configuration = getConfiguration(getState());

  dispatch(setFacilityStatus(IN_PROGRESS));

  try {
    const facility = await apiGet(getFacilityEndpoint(configuration), configuration);

    dispatch(setFacilityData(facility));
    dispatch(setFacilityStatus(SUCCESS));

    return facility;
  } catch (error) {
    pubsub.trigger(EVENT_INITIALIZE_ERROR, {message: error.message});

    dispatch(setFacilityStatus(FAILURE));

    throw new ResponseError(locale.translate('widgetDisabled'));
  }
};
