import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import {
  isActivityMultipleActivation,
  isActivityMultipleHotelNarrowed,
  isActivitySingle,
  isActivitySingleHotelNarrowed,
  isActivityWithDates,
  isActivityMultiple,
  getParsedAdmissionDuration,
  getAdmissionSuitableDateDescription
} from '@Utils/activity/activity';
import ActivityAdmissionSingle from './ActivityAdmissionSingle';
import ActivityAdmissionMultiple from './ActivityAdmissionMultiple';
import ActivityAdmissionActivationEnd from './ActivityAdmissionActivationEnd';
import ActivityAdmissionEnd from './ActivityAdmissionEnd';
import ActivityAdmissionFixedDate from './ActivityAdmissionFixedDate';

const ActivityAdmission = ({activity, selections, hideIcon}) => {
  const {passType, admissionPolicy} = activity;
  const {durationInMinutes} = admissionPolicy;
  const suitableDate = getAdmissionSuitableDateDescription(admissionPolicy);
  const parsedDuration = getParsedAdmissionDuration(durationInMinutes);

  if (isActivityWithDates(passType) || isActivitySingleHotelNarrowed(passType)) {
    return (
      <ActivityAdmissionFixedDate
        activity={activity}
        parsedDuration={parsedDuration}
        selections={selections}
        hideIcon={hideIcon}
      />
    );
  }

  return (
    <>
      {
        isActivitySingle(passType) && (
          <ActivityAdmissionSingle
            admissionPolicy={admissionPolicy}
            parsedDuration={parsedDuration}
            hideIcon={hideIcon}
          />
        )
      }

      {
        (isActivityMultiple(passType) || isActivityMultipleHotelNarrowed(passType)) && (
          <ActivityAdmissionMultiple
            admissionPolicy={admissionPolicy}
            parsedDuration={parsedDuration}
            hideIcon={hideIcon}
          />
        )
      }

      {
        isActivityMultipleActivation(passType) ? (
          <ActivityAdmissionActivationEnd
            admissionPolicy={admissionPolicy}
            suitableDate={suitableDate}
            hideIcon={hideIcon}
          />
        ) : (
          <ActivityAdmissionEnd
            admissionPolicy={admissionPolicy}
            suitableDate={suitableDate}
            passType={passType}
            hideIcon={hideIcon}
          />
        )
      }
    </>
  );
};

ActivityAdmission.propTypes = {
  activity: PropTypes.object.isRequired,
  selections: PropTypes.object,
  hideIcon: PropTypes.bool
};

export default ActivityAdmission;
