import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import {useTheme} from '@mui/material/styles';
import locale from '@Utils/locale';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const EmailInfo = () => {
  const theme = useTheme();

  return (
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Typography align="center" sx={{color: theme.palette.text.secondary}}>
        {locale.translate('emailNotGetThrough')}
      </Typography>
      <Tooltip
        title={
          <Typography variant="caption">
            {locale.translate('emailNotGetThroughTooltip')}
          </Typography>
        }
        enterTouchDelay={50}
        placement="top"
        arrow
      >
        <InfoOutlinedIcon sx={{ml: 0.5, width: 20, height: 20, color: theme.palette.text.secondary}} />
      </Tooltip>
    </Box>
  );
};

export default EmailInfo;
