import {getFormattedPrice} from '@Utils/format';
import locale from '@Utils/locale';
import {isVariantGroup} from '@Utils/variantType';
import {isActivityMultipleHotelNarrowed, isActivitySingleGroupEvent} from './activity';

/**
 * Checks if variant is multiday (range of multiple days can be selected on calendar).
 * @param {Variant} variant - variant object
 * @return {boolean} is variant multiday
 */
export const isVariantMultiday = ({numberOfAdmissionDatesMin, numberOfAdmissionDatesMax}) =>
  numberOfAdmissionDatesMin !== numberOfAdmissionDatesMax || numberOfAdmissionDatesMin > 1;

/**
 * Returns variant cost - if variant have dates with costs then returns lowest cost
 * @param {Variant} variant - activity variant
 * @return {Cost | null} default variant cost
 */
export const getVariantDefaultCost = ({cost, datesCostMin}) => {
  if (datesCostMin) {
    return datesCostMin;
  }

  if (cost) {
    return cost;
  }

  return null;
};

/**
 * Price with currency
 * @typedef {Object} PriceWithCurrency
 * @property {string} priceText - price description
 * @property {string} abbr - price abbreviation
 */

/**
 * Returns variant price with currency
 * @param {number} price - variant price
 * @param {string} passType - type of variant activity
 * @param {boolean} pricePerDay - is price per day
 * @param {string} currency - cost currency
 * @return {PriceWithCurrency} object with priceText and abbr fields
 */
export const getVariantPriceWithCurrency = ({cost, passType, pricePerDay, currency, variantType}) => {
  const currencyText = currency && locale.translate(`${currency}`);
  const priceWithCurrency = `${getFormattedPrice(cost)} ${currencyText}`;

  if (isActivitySingleGroupEvent(passType) || isVariantGroup(variantType)) {
    return {priceText: priceWithCurrency, abbr: locale.translate('perPersonAbbr')};
  }

  if (isActivityMultipleHotelNarrowed(passType)) {
    return {priceText: priceWithCurrency, abbr: locale.translate('hotelNight')};
  }

  if (pricePerDay) {
    return {priceText: priceWithCurrency, abbr: locale.translate('day')};
  }

  return {priceText: priceWithCurrency};
};

/**
 * Checks if variant price is per day
 * @param {Variant} variant - variant object
 * @return {boolean} if variant price is per day
 */
export const isVariantPricePerDay = ({
  isPricePerAdmissionDate,
  numberOfAdmissionDatesMin,
  numberOfAdmissionDatesMax
}) =>
  isPricePerAdmissionDate &&
  (numberOfAdmissionDatesMin > 1 || numberOfAdmissionDatesMin !== numberOfAdmissionDatesMax);

/**
 * Checks if variant prices array have the same price (without prices dependent of date)
 * @param {Variant} variant - variant object
 * @return {boolean} if variant price is common
 */
export const isVariantPriceCommon = ({datesCostMin, datesCostMax}) => datesCostMin?.price === datesCostMax?.price;

/**
 * Get activity variant by id
 * @param {Variant[]} variants - array of activity variants
 * @param {number} variantId - variant id
 * @return {Variant} activity variant
 */
export const getVariantById = (variants, variantId) =>
  (variants && variants.find(variant => variant.id === variantId)) || null;
