import {timezoneParse} from '@Utils/date';
import locale from '@Utils/locale';
import {
  isWidgetBusiness,
  isWidgetBusinessPreview,
  isWidgetInternal,
  isWidgetInternalPreview,
  isWidgetOnlineClientPreview
} from '@Utils/widgetType';

export const getActivitiesEndpoint = ({
  facilityId,
  widgetType,
  businessGroupId,
  onlineGroupId,
  internalBuyerFacilityId,
  groupId,
  affiliationHash
}) => {
  const localeParam = `locale=${locale.language}`;

  const params = [localeParam];

  if (isWidgetBusinessPreview(widgetType)) {
    return `company-api/business/facilities/${facilityId}/business_groups/${businessGroupId}/activities/preview?${localeParam}`;// eslint-disable-line max-len
  }

  if (isWidgetBusiness(widgetType)) {
    return `company-api/business/facilities/${facilityId}/activities?${localeParam}`;
  }

  if (isWidgetInternal(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/activities?${localeParam}`;
  }

  if (isWidgetInternalPreview(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/buyer_facilities/${internalBuyerFacilityId}/activities?${localeParam}`;// eslint-disable-line max-len
  }

  if (affiliationHash) {
    params.push(`affiliationHash=${affiliationHash}`);
  }

  const groupRoute = onlineGroupId ? onlineGroupId : groupId ? groupId : 1;

  if (isWidgetOnlineClientPreview(widgetType)) {
    return `company-api/online/facilities/${facilityId}/online_groups/${groupRoute}/activities/preview?${params.join('&')}`;// eslint-disable-line max-len
  }

  return `user-api/facilities/${facilityId}/online_groups/${groupRoute}/activities?${params.join('&')}`;
};

export const getProcessedSalePolicy = (salePolicy, parseDate) => {
  const {showEndDate} = salePolicy;

  return {
    ...salePolicy,
    showEndDate: showEndDate ? parseDate(showEndDate) : null
  };
};

export const getProcessedDates = dates => {
  if (dates) {
    return dates
      .map(date => {
        const {description, numberOfSeats, resourceExists, variants} = date;

        return {
          id: new Date(date.date).getTime(),
          date: date.date,
          originalDate: date.date,
          description,
          numberOfSeats,
          resourceExists,
          variants
        };
      })
      .sort((firstDate, secondDate) => firstDate.id - secondDate.id);
  }

  return [];
};

export const getProcessedAdmissionPolicy = (admissionPolicy, parseDate) => {
  const {startDatetime, endDatetime} = admissionPolicy;

  return {
    ...admissionPolicy,
    dates: [],
    startDatetime: startDatetime ? parseDate(startDatetime) : null,
    endDatetime: endDatetime ? parseDate(endDatetime) : null
  };
};

export const getProcessedVariants = variants => {
  if (variants) {
    return variants.map(variant => ({
      ...variant,
      dates: []
    }));
  }

  return [];
};

export const getProcessedActivities = (activities, timezone, isUpsellActivity) => {
  const parseDate = timezoneParse(timezone);

  return activities.map(activity => ({
    ...activity,
    salePolicy: getProcessedSalePolicy(activity.salePolicy, parseDate),
    admissionPolicy: getProcessedAdmissionPolicy(activity.admissionPolicy, parseDate),
    variants: getProcessedVariants(activity.variants, parseDate),
    isUpsellActivity: !!isUpsellActivity
  }));
};
