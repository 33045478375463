import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '@Utils/locale';
import PropTypes from 'prop-types';
import ActivityVariantPicker from './ActivityVariantPicker';
import {areActivityDatesInvalid} from '@Utils/activity/activity';
import {
  getVariantDefaultCost,
  getVariantPriceWithCurrency,
  isVariantPriceCommon,
  isVariantPricePerDay
} from '@Utils/activity/variant';
import Text from '@Components/common/text/Text';
import {ETextSize, ETextTransform} from '@Components/common/text/types';
import {useSelector} from 'react-redux';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {getCatalogPrice} from '@Utils/variant';
import classNames from 'classnames';
import {useMatch} from 'react-router-dom';
import {CHECKOUT_PATH} from '@Utils/routerUtils';
import Typography from '@mui/material/Typography';

const sortByPriority = (prevObject, nextObject) => nextObject.priority - prevObject.priority;

const ActivityVariants = ({activity, orderNumber}) => {
  const {passType, variants} = activity;
  const variantsByPriority = variants.sort(sortByPriority);
  const {widgetType} = useSelector(getConfiguration);
  const isUpsell = useMatch(CHECKOUT_PATH);

  return variantsByPriority.map(variant => {
    const {id: variantId, type: variantType} = variant;
    const invalidDatesRange = areActivityDatesInvalid(activity);
    const pricePerDay = isVariantPricePerDay(variant);
    const cost = getVariantDefaultCost(variant);
    const {currency} = cost;
    const {priceText, abbr} = getVariantPriceWithCurrency({
      cost: cost?.price, passType, pricePerDay, currency, variantType
    });
    const {catalogPrice, catalogPriceDescription} = getCatalogPrice({cost, variant, widgetType});
    const activityClass = isUpsell ? 'dl-activity-upsell' : 'dl-activity';

    return (
      <div
        className={`${activityClass}__variant-wrapper ${activityClass}__variants`}
        key={variantId}
      >
        <div className={`${activityClass}__variant`}>
          <div className={`${activityClass}__variant-name`}>
            <div className="dl-text dl-text--normal dl-w-100" dl-test="variant-name">
              {variant.name}
            </div>
            <Typography sx={{mt: 0.5, color: 'text.secondary'}} variant="caption" data-testid="variant-description">
              {variant.description}
            </Typography>
          </div>

          <div className={`${activityClass}__price-with-picker`}>
            <div
              className={classNames(
                `${activityClass}__variant-price`,
                {
                  [`${activityClass}__variant-price--with-description`]: catalogPrice
                }
              )}
            >
              {
                !isVariantPriceCommon(variant) && (
                  <Text size={ETextSize.MEDIUM} className="dl-price__label" dl-test="price-from-label">
                    {`${locale.translate('from')}`.toLowerCase()}&nbsp;
                  </Text>
                )
              }
              <Text
                dl-test="price"
                size={ETextSize.MEDIUM}
                transform={ETextTransform.EMPHASIZED}
                className={`${activityClass}__variant-price-text`}
              >
                {priceText}
                {
                  catalogPrice && (
                    <Text
                      dl-test="catalog-price"
                      size={ETextSize.MEDIUM}
                      className="dl-price__catalog-price"
                    >
                      {getVariantPriceWithCurrency({cost: cost?.catalogPrice, currency}).priceText}
                    </Text>
                  )
                }
              </Text>

              {
                abbr && <span dl-test="abbr" className="dl-price__label dl-text--medium">&nbsp;/ {abbr}&nbsp;</span>
              }
            </div>

            {
              catalogPriceDescription ?
                <div className="dl-catalog-price-description dl-catalog-price-description__activity-variant">
                  <Text
                    size={ETextSize.SMALL}
                    dl-test="catalog-price-description"
                  >
                    {catalogPriceDescription}
                  </Text>
                </div> : null
            }

            <ActivityVariantPicker
              activity={activity}
              variantId={variantId}
              invalidDatesRange={invalidDatesRange}
              orderNumber={orderNumber}
            />
          </div>
        </div>
      </div>
    );
  });
};

ActivityVariants.propTypes = {
  activity: PropTypes.shape({
    passType: PropTypes.string,
    variants: PropTypes.array
  }).isRequired,
  orderNumber: PropTypes.number.isRequired
};

export default ActivityVariants;
