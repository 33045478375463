import {EPassType} from '@Consts/apiGlobals';
import {DayOffersProcessed} from '@Features/activityAdmissionDates/types';
import {isActivitySinglePickDate, isActivityWithDateTimes} from '@Utils/activity/activity';
import {getMonth, getTimezoneDate, isSameDate, getDate} from '@Utils/dayjs/dayjsUtils';
import {Dayjs} from 'dayjs';
import {IDatesGroupedByDay} from './types';

export const getCalendarValueMonthAndYear = (date: Dayjs) => ({
  month: date.get('month'),
  year: date.get('year')
});

export const getCalendarValue = (
  timezone: string,
  firstAdmissionDate: string | null,
  selectedDate?: string
) => {
  if (selectedDate) {
    return getTimezoneDate(selectedDate, timezone);
  }

  return getTimezoneDate(firstAdmissionDate, timezone);
};

const isFirstAdmissionDateEqual = (admissionDate: string, firstAdmissionDate: string) =>
  isSameDate(getDate(firstAdmissionDate), getDate(admissionDate), 'date');

export const getShouldSelectDateGroup = (datesGroupedByDay: IDatesGroupedByDay[], firstAdmissionDate: string) => {
  const admissionDate = datesGroupedByDay[0].date;
  const isOutOfLimit = datesGroupedByDay[0].metaData.isOutOfLimit;

  return !isOutOfLimit && isFirstAdmissionDateEqual(admissionDate, firstAdmissionDate);
};

export const getShouldSelectDayOffer = (dayOffers: DayOffersProcessed[], firstAdmissionDate: string) => {
  const admissionDate = dayOffers[0].originalDate;

  return isFirstAdmissionDateEqual(admissionDate, firstAdmissionDate);
};

export const getShouldSelectDefaultDate = (
  firstAdmissionDate: string | null,
  isOneAdmissionDateAvailable: boolean,
  passType: EPassType,
  initialSelectedDate: Dayjs | null,
  datesGroupedByDay?: IDatesGroupedByDay[] | null,
  dayOffers?: DayOffersProcessed[]
) => {
  const isCommonConditionsMeet = isOneAdmissionDateAvailable && firstAdmissionDate && !initialSelectedDate;

  if (!isCommonConditionsMeet) {
    return false;
  }

  if (isActivitySinglePickDate(passType) && datesGroupedByDay?.length) {
    return getShouldSelectDateGroup(datesGroupedByDay, firstAdmissionDate);
  }

  if (isActivityWithDateTimes(passType) && dayOffers) {
    return getShouldSelectDayOffer(dayOffers, firstAdmissionDate);
  }

  return false;
};

const getDatesGroupsFromMonth = (datesGroupedByDay: IDatesGroupedByDay[], month: number) =>
  datesGroupedByDay.filter(groupedDates => getMonth(getDate(groupedDates.date)) === month);

export const updateBargainPrices = (datesGroupedByDay: IDatesGroupedByDay[]) => {
  if (!datesGroupedByDay.length) {
    return datesGroupedByDay;
  }

  const month = getMonth(getDate(datesGroupedByDay[0].date));
  const datesGroupedByDayRestrictedByMonth = getDatesGroupsFromMonth(datesGroupedByDay, month);

  const dateGroupsSortedByTotalPrice =
    [...datesGroupedByDayRestrictedByMonth].sort((a, b) => a.metaData.totalPrice - b.metaData.totalPrice);

  const groupsMinPrice = dateGroupsSortedByTotalPrice[0].metaData.totalPrice;
  const groupsMaxPrice = dateGroupsSortedByTotalPrice[dateGroupsSortedByTotalPrice.length - 1].metaData.totalPrice;

  const isPriceChangePerMonth = groupsMinPrice - groupsMaxPrice < 0;

  return datesGroupedByDay.map(dateGroup => ({
    ...dateGroup,
    metaData: {
      ...dateGroup.metaData,
      isBargainPrice: isPriceChangePerMonth ? dateGroup.metaData.totalPrice === groupsMinPrice : false
    }
  }));
};
