import React, {useState} from 'react';
import {
  CHECKOUT_PATH,
  HOME_PATH,
  INTERNAL_PAYMENT_LINK_PATH,
  SUMMARY_PATH,
  WAITING_FOR_PAYMENT_PATH
} from '@Utils/routerUtils';
import Text from '../common/text/Text';
import locale from '@Utils/locale';
import LanguagePicker from '../languagePicker/LanguagePicker';
import './navigation.scss';
import FlexBox from '@Components/common/flexBox/FlexBox';
import NavigationItem from './NavigationItem';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {useSelector} from 'react-redux';
import {ETextColor, ETextTransform, ETextWeight} from '@Components/common/text/types';
import {EAlignItems, EFlexDirection} from '@Components/common/flexBox/types';
import {getHideLanguagePicker, getIsModal} from '@Features/configuration/configurationSelectors';
import CloseWidget from './CloseWidget';
import {useMatch} from 'react-router-dom';
import {getActivitiesStatus} from '@Features/activities/activitiesSelectors';
import {IN_PROGRESS} from '@Consts/status';
import ClearOrChangeActivitiesConfirmation from '@Components/confirmations/ClearOrChangeActivitiesConfirmation';

const Navigation = () => {
  const isModal = useSelector(getIsModal);
  const numberOfBasketItems = useSelector(getBasketItems).length;
  const activitiesLoading = useSelector(getActivitiesStatus) === IN_PROGRESS;
  const isCheckout = useMatch(CHECKOUT_PATH);
  const isSummary = useMatch(SUMMARY_PATH);
  const isInternalPaymentView = useMatch(INTERNAL_PAYMENT_LINK_PATH);
  const isWaitingForPaymentPage = useMatch(WAITING_FOR_PAYMENT_PATH);
  const isHome = !isCheckout && !isSummary;
  const hideLanguagePicker = useSelector(getHideLanguagePicker);
  const onlyClose = isModal && isSummary;
  const showBasket = isHome && numberOfBasketItems;
  const showNavigation = !onlyClose;
  const showLanguagePicker = !onlyClose && !hideLanguagePicker;
  const canGoToCheckout = numberOfBasketItems > 0 && !activitiesLoading;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if ((isSummary || isInternalPaymentView || isWaitingForPaymentPage) && !isModal) {
    return null;
  }

  return (
    <>
      <FlexBox
        direction={EFlexDirection.ROW}
        align={EAlignItems.CENTER}
        className="dl-navigation"
      >
        {isModal ? <CloseWidget /> : null }
        {
          showNavigation ? (
            <>
              <NavigationItem
                disabled={isHome}
                path={HOME_PATH}
                className={`dl-navigation__step--with-skew-delimiter ${isHome ? '' : 'dl-navigation__link'}`}
                showDialog={() => setIsDialogOpen(true)}
              >
                <Text
                  color={ETextColor.BLACK_SECONDARY}
                  weight={isHome ? ETextWeight.BOLD : undefined}
                >
                  {locale.translate('choosing')}
                </Text>
              </NavigationItem>

              <NavigationItem
                disabled={!canGoToCheckout}
                path={CHECKOUT_PATH}
                className={isCheckout && numberOfBasketItems ? 'dl-cursor-pointer' : ''}
              >
                <>
                  <Text
                    color={ETextColor.BLACK_SECONDARY}
                    weight={isCheckout ? ETextWeight.BOLD : undefined}
                    transform={showBasket ? ETextTransform.UNDERLINE : undefined}
                    testId="navbarBasket"
                  >
                    {locale.translate('menuDetails')}
                  </Text>

                  {
                    showBasket ? (
                      <div className="dl-basket__amount-navbar">
                        {numberOfBasketItems}
                      </div>
                    ) : null
                  }
                </>
              </NavigationItem>
            </>
          ) : null
        }

        {showLanguagePicker && <LanguagePicker />}
      </FlexBox>

      <ClearOrChangeActivitiesConfirmation
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        source="navbar"
      />
    </>
  );
};

export default Navigation;
