import React from 'react';
import Grid from '@mui/material/Grid';

type Props = {
  isTestB: boolean;
};

const TimePickerCellsWrapper = ({
  children,
  isTestB
}: React.PropsWithChildren<Props>) => {
  if (isTestB) {
    return (
      <Grid
        container
        spacing={1}
      >
        {children}
      </Grid>
    );
  }

  return <ul className="dl-time-picker">{children}</ul>;
};

export default TimePickerCellsWrapper;
