import {IFacility} from '@Consts/apiGlobals';
import {getVariantDefaultCost} from '@Utils/activity/variant';
import directFrameMessage from '@Utils/directFrameMessage';
import {GTM_MESSAGE_NAME, EGtmEvents} from './core';

enum EGtmEventKeys {
  GTM_ADD_KEY = 'add',
  GTM_REMOVE_KEY = 'remove'
}

const getEcommerceData = (basketItem: any, eventKey: EGtmEventKeys) => {
  const {activityId, activity, variant} = basketItem;
  const price = getVariantDefaultCost(variant)?.price;
  const isAddToCart = eventKey === EGtmEventKeys.GTM_ADD_KEY;

  return {
    ...isAddToCart && {currencyCode: variant.cost?.currency ?? variant.datesCostMin?.currency},
    [eventKey]: {
      products: [{
        id: activityId,
        name: activity?.name,
        variant: variant?.name,
        price,
        quantity: 1
      }]
    }
  };
};

const getGtmData = (
  basketItem: any,
  event: EGtmEvents,
  eventKey: EGtmEventKeys
) => ({
  event,
  ecommerce: getEcommerceData(basketItem, eventKey)
});

const clearEcommerce = () => {
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: {ecommerce: null}
  });
};

const addToCart = (facility: IFacility, basketItem: any) => {
  const {isGoogleTagManagerEnabled} = facility;

  if (!isGoogleTagManagerEnabled) {
    return;
  }

  clearEcommerce();
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: getGtmData(
      basketItem,
      EGtmEvents.GTM_ADD_TO_CART,
      EGtmEventKeys.GTM_ADD_KEY
    )
  });
};

const removeFromCart = (facility: IFacility, basketItem: any) => {
  const {isGoogleTagManagerEnabled} = facility;

  if (!isGoogleTagManagerEnabled) {
    return;
  }

  clearEcommerce();
  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: getGtmData(
      basketItem,
      EGtmEvents.GTM_REMOVE_FROM_CART,
      EGtmEventKeys.GTM_REMOVE_KEY
    )
  });
};

export default {
  addToCart: (facility: IFacility, basketItem: any) => addToCart(facility, basketItem),
  removeFromCart: (facility: IFacility, basketItem: any) => removeFromCart(facility, basketItem)
};
