import {IUpsellOfferStore} from 'src/js/store/types';
import {EUpsellActionsTypes, TUpsellActions} from './types';

const initialState: IUpsellOfferStore = {upsellActivities: [], pickedUpsellActivities: [], status: undefined};

const upsellOfferReducer = (state = initialState, action: TUpsellActions) => {
  switch (action.type) {
    case EUpsellActionsTypes.SET_UPSELL_STATUS:
      return {...state, status: action.payload};
    case EUpsellActionsTypes.SET_UPSELL_ACTIVITIES:
      return {
        ...state,
        upsellActivities: [
          ...action.payload
        ]
      };
    case EUpsellActionsTypes.SET_PICKED_UPSELL_ACTIVITY: {
      const pickedActivity = action.payload;

      const pickedActivityAlreadyExists = state.pickedUpsellActivities.some(
        storedPickedActivity => storedPickedActivity.id === pickedActivity.id
      );

      if (pickedActivityAlreadyExists) {
        return state;
      }

      return {
        ...state,
        pickedUpsellActivities: [
          ...state.pickedUpsellActivities,
          action.payload
        ]
      };
    }
    case EUpsellActionsTypes.SET_UPSELL_ACTIVITIES_ADDITONS: {
      const activityId = action.payload.activityId;
      const additions = action.payload.additions;
      const upsellActivities = state.upsellActivities;

      return {
        ...state,
        upsellActivities: upsellActivities.map(upsellActivity => (
          upsellActivity.id !== activityId ? upsellActivity : {...upsellActivity, additions}
        ))
      };
    }

    case EUpsellActionsTypes.SET_UPSELL_ACTIVITIES_QUESTIONS: {
      const activityId = action.payload.activityId;
      const questions = action.payload.questions;
      const upsellActivities = state.upsellActivities;

      return {
        ...state,
        upsellActivities: upsellActivities.map(upsellActivity => (
          upsellActivity.id !== activityId ? upsellActivity : {...upsellActivity, questions}
        ))
      };
    }

    case EUpsellActionsTypes.SET_PICKED_UPSELL_ACTIVITIES_ADDITONS: {
      const activityId = action.payload.activityId;
      const additions = action.payload.additions;
      const pickedUpsellActivities = state.pickedUpsellActivities;

      return {
        ...state,
        pickedUpsellActivities: pickedUpsellActivities.map(pickedUpsellActivity => (
          pickedUpsellActivity.id !== activityId ? pickedUpsellActivity : {...pickedUpsellActivity, additions}
        ))
      };
    }

    case EUpsellActionsTypes.SET_PICKED_UPSELL_ACTIVITIES_QUESTIONS: {
      const activityId = action.payload.activityId;
      const questions = action.payload.questions;
      const pickedUpsellActivities = state.pickedUpsellActivities;

      return {
        ...state,
        pickedUpsellActivities: pickedUpsellActivities.map(pickedUpsellActivity => (
          pickedUpsellActivity.id !== activityId ? pickedUpsellActivity : {...pickedUpsellActivity, questions}
        ))
      };
    }

    default:
      return state;
  }
};

export default upsellOfferReducer;
