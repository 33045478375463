import {FAILURE, IN_PROGRESS, SUCCESS} from '@Consts/status';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';
import {apiGet, apiPost} from '@Utils/api/api';
import RequestValidationError from '@Utils/api/errors/requestValidationErrror';
import ResponseError from '@Utils/api/errors/responseError';
import {
  getBasketValue,
  splitBasketItemsByUpsell
} from '@Utils/basket';
import {EVENT_RECIEVED_PAYMENT_LINK, EVENT_TICKET_CREATED, EVENT_TICKET_ERROR, SET_USER_ID} from '@Utils/events';
import facebookPixel from '@Utils/facebookPixel';
import locale from '@Utils/locale';
import pubsub from '@Utils/pubsub';
import {
  getOrdersUrl,
  getOrderUrl,
  findLatestOrder,
  updateLatestOrders,
  shouldOpenPayment
} from '@Features/order/orderUtils';
import {parseLanguage} from '@Utils/eventsUtils';
import {getOrderType} from '@Utils/order';
import {isWidgetInternal, isWidgetPreview} from '@Utils/widgetType';
import {clearQuestionsStatus} from '@Features/activityQuestions/questionsActions';
import {clearAdditionsStatus} from '@Features/activityAdditions/additionsActions';
import {clearShippingMethods} from '@Features/shippingMethods/shippingMethodsActions';
import {clearBasketItemsInStorage} from '@Features/basket/basketUtils';
import {EStorageKeys} from '@Consts/storageConsts';
import {getStorageBasketItems, getStorageLatestOrders} from '@Features/storage/storageSelectors';
import {setStorage, setStorageItemToState} from '@Features/storage/storageActions';
import dayjs from 'dayjs';
import {clearBasket} from '@Features/basket/basketActions';
import {EPaymentMethod} from '@Components/checkout/types';
import {WidgetMode} from '../../widget/types';
import {INTERNAL_PAYMENT_LINK_PATH, SUMMARY_PATH, WAITING_FOR_PAYMENT_PATH} from '@Utils/routerUtils';
import {EOrderState} from '@Consts/apiGlobals';
import directFrameMessage from '@Utils/directFrameMessage';

export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';
export const SET_ORDER = 'SET_ORDER';
export const SET_SUBMIT_ORDER_STATUS = 'SET_SUBMIT_ORDER_STATUS';
export const SET_SUBMIT_ORDER_ERROR = 'SET_SUBMIT_ORDER_ERROR';
export const PENDING = 'pending';
export const SKIPPED = 'skipped';
export const COMPLETED = 'completed';

export const setOrderStatus = status => ({
  type: SET_ORDER_STATUS,
  payload: {status}
});

export const setOrder = order => ({
  type: SET_ORDER,
  payload: {order}
});

export const setSubmitOrderStatus = submitOrderStatus => ({
  type: SET_SUBMIT_ORDER_STATUS,
  payload: {submitOrderStatus}
});

export const setSubmitOrderError = submitOrderError => ({
  type: SET_SUBMIT_ORDER_ERROR,
  payload: {submitOrderError}
});

export const checkIfPaymentUrlIsAvailable = (order, config) => {
  const {facilityId} = config;
  const {tickets, id: orderId} = order;
  const startTime = new Date().getTime();
  const maxTime = 30000;
  const timeoutOffset = 3000;

  return new Promise((resolve, reject) => {
    const timeoutCallback = async () => {
      const nextTime = new Date().getTime();

      if (nextTime - startTime <= maxTime) {
        const code = tickets[0].code;

        try {
          const order = await apiGet(getOrderUrl(config, code, orderId), config);
          const {state, payUrl} = order;
          const isPending = state === PENDING;

          if (isPending) {
            if (payUrl) {
              pubsub.trigger(EVENT_RECIEVED_PAYMENT_LINK, {
                facilityId,
                code,
                order,
                language: parseLanguage(locale.locale)
              });

              return resolve(order);
            }

            return setTimeout(timeoutCallback, timeoutOffset);
          }

          return resolve(order);
        } catch (error) {
          setTimeout(timeoutCallback, timeoutOffset);
        }
      }

      return reject(new ResponseError(
        locale.translate('exceededTimeout'),
        414,
        {type: 'exceededTimeout'}
      ));
    };

    return setTimeout(timeoutCallback, 0);
  });
};

const handleSuccessEvents = ({
  order,
  config,
  facility,
  basketItems,
  invoiceAddress,
  isAgreeMarketingConsents
}) => {
  const {id: facilityId, name: facilityName, company} = facility;
  const {industry} = company;
  const {widgetType, userName, companyName, affiliationHash, companyUserId, isModal} = config;
  const {upsellBasketItems} = splitBasketItemsByUpsell(basketItems);
  const basketItemsValue = getBasketValue(basketItems, true);
  const upsellBasketItemsValue = getBasketValue(upsellBasketItems, true);

  const {
    orderBuyerHash,
    businessBuyerCompanyId,
    businessBuyerCompanyUserId,
    currency,
    paymentMethod
  } = order;

  pubsub.trigger(SET_USER_ID, {
    widgetType,
    orderBuyerHash,
    businessBuyerCompanyId,
    companyUserId: businessBuyerCompanyUserId || companyUserId,
    userName,
    companyName,
    facilityId,
    language: parseLanguage(locale.locale)
  });

  pubsub.trigger(EVENT_TICKET_CREATED, {
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    language: parseLanguage(locale.locale),
    ...invoiceAddress && {invoiceDataSubmitted: true},
    revenueTotal: basketItemsValue,
    revenueFromUpsell: upsellBasketItemsValue,
    currency,
    isAgreeMarketingConsents,
    widgetMode: isModal ? WidgetMode.modal : WidgetMode.inline,
    paymentMethod
  });
};

export const createOrder = data => async (dispatch, getState) => {
  const config = getConfiguration(getState());
  const facility = getFacility(getState());
  const basketItems = getBasketItems(getState());
  const {id: facilityId, name: facilityName, company} = facility;
  const {industry} = company;
  const {affiliationHash} = config;
  const {invoiceAddress, isAgreeMarketingConsents, paymentCompleteUntil, paymentMethod} = data;
  const url = getOrdersUrl(config);

  dispatch(setOrderStatus(IN_PROGRESS));

  try {
    let order = await apiPost(url, {...config, body: data});

    const {state} = order;
    const isPaymentUrlRequired = paymentMethod !== EPaymentMethod.BLIK && !paymentCompleteUntil;

    if (state === EOrderState.PENDING && isPaymentUrlRequired) {
      order = await checkIfPaymentUrlIsAvailable(order, config);
    }

    facebookPixel.postAddPaymentInfo(facility);
    handleSuccessEvents({order, config, facility, basketItems, invoiceAddress, isAgreeMarketingConsents});
    dispatch(setOrderStatus(SUCCESS));

    return order;
  } catch (error) {
    pubsub.trigger(EVENT_TICKET_ERROR, {
      facilityId,
      facilityName,
      industry,
      affiliationHash,
      message: error?.message ? error.message : locale.translate('internalErrorMessage'),
      data: error.data ? JSON.stringify(error.data) : null,
      language: parseLanguage(locale.locale)
    });

    if (error instanceof RequestValidationError) {
      dispatch(setOrderStatus(null));
      throw error;
    } else {
      dispatch(setOrderStatus(FAILURE));
    }

    if (!error?.message) {
      throw new Error(locale.translate('internalErrorMessage'));
    }

    throw new Error(error.message);
  }
};

export const createOrderPreview = data => async (dispatch, getState) => {
  const config = getConfiguration(getState());
  const facility = getFacility(getState());
  const {id: facilityId, name: facilityName, company} = facility;
  const {industry} = company;
  const {affiliationHash} = config;
  const url = getOrdersUrl(config);

  dispatch(setOrderStatus(IN_PROGRESS));

  try {
    await apiPost(url, {...config, body: data});

    dispatch(setOrderStatus(SUCCESS));

    return null;
  } catch (error) {
    if (error instanceof RequestValidationError) {
      dispatch(setOrderStatus(null));
    } else {
      dispatch(setOrderStatus(FAILURE));
    }

    pubsub.trigger(EVENT_TICKET_ERROR, {
      facilityId,
      facilityName,
      industry,
      affiliationHash,
      message: error.message,
      data: error.data ? JSON.stringify(error.data) : null,
      language: parseLanguage(locale.locale)
    });

    throw error;
  }
};

export const checkIfOrderIsAvailable = (code, orderId, config, callback) => {
  const delay = 2000;

  return new Promise((resolve, reject) => {
    let timeoutId = null;

    const timeoutCallback = async () => {
      try {
        const order = await apiGet(getOrderUrl(config, code, orderId), config);

        if (order?.documentAddress) {
          return resolve(order);
        }

        timeoutId = setTimeout(timeoutCallback, delay);
        callback(timeoutId);
      } catch (error) {
        return reject(error);
      }
    };

    timeoutId = setTimeout(timeoutCallback, delay);
    callback(timeoutId);
  });
};

export const fetchOrder = (code, orderId, callback) => async (dispatch, getState) => {
  const state = getState();
  const config = getConfiguration(state);
  const {onlineGroupId, widgetType} = config;
  const latestOrders = getStorageLatestOrders(state);
  const latestOrder = findLatestOrder(latestOrders, onlineGroupId, widgetType);

  dispatch(setOrderStatus(IN_PROGRESS));

  try {
    const order = await checkIfOrderIsAvailable(code, orderId, config, callback);
    const {documentAddress, tickets, price, state, completedAt, tax, currency} = order;
    const updatedOrder = {
      ...latestOrder,
      documentAddress, tickets, price, state, completedAt, tax, currency
    };
    const updatedOrders = updateLatestOrders(latestOrders, onlineGroupId, widgetType, updatedOrder);

    dispatch(setStorageItemToState(EStorageKeys.LATEST_ORDERS, updatedOrders));
    dispatch(setOrderStatus(SUCCESS));

    return order;
  } catch (error) {
    dispatch(setOrderStatus(FAILURE));

    throw error;
  }
};

export const fetchOrderSubmit = ({
  data,
  basketItems,
  handleNavigate
}) => async (dispatch, getState) => {
  const state = getState();
  const {email, paymentMethod, blikCode} = data;
  const {facilityId, widgetType, onlineGroupId} = getConfiguration(state);
  const storageBasketItems = getStorageBasketItems(state) || [];
  const latestOrders = getStorageLatestOrders(state);
  const isStoredLatestOrder = Boolean(findLatestOrder(latestOrders, onlineGroupId, widgetType));
  const widgetIsInternal = isWidgetInternal(widgetType);

  dispatch(setSubmitOrderStatus(IN_PROGRESS));

  try {
    const order = isWidgetPreview(widgetType) ?
      await dispatch(createOrderPreview(data)) :
      await dispatch(createOrder(data));

    dispatch(setSubmitOrderStatus(SUCCESS));

    const latestOrder = {
      facilityId,
      orderType: getOrderType(order, widgetType),
      basket: basketItems,
      onlineGroupId,
      widgetType,
      timestamp: dayjs().valueOf()
    };

    const isVivaWalletPayment =
      widgetIsInternal &&
      order?.price > 0 &&
      order?.state !== SKIPPED &&
      !!order?.paymentViewUrl &&
      paymentMethod === EPaymentMethod.PAYMENT_CART;

    if (order) {
      const {id: orderId, payUrl, tickets, state, paymentViewUrl} = order;
      const {code} = tickets[0];

      const openPayment = shouldOpenPayment(
        order, widgetType, state, isVivaWalletPayment, paymentViewUrl, blikCode
      );

      const updatedOrder = {
        ...latestOrder,
        code,
        orderId,
        paymentViewUrl: isVivaWalletPayment ? null : paymentViewUrl,
        isVivaWalletPayment
      };
      const updatedOrders = updateLatestOrders(
        latestOrders, onlineGroupId, widgetType, updatedOrder, isStoredLatestOrder
      );

      dispatch(setStorage(EStorageKeys.LATEST_ORDERS, updatedOrders, true));
      dispatch(clearBasketItemsInStorage(onlineGroupId, storageBasketItems));

      if (openPayment && !isVivaWalletPayment) {
        return directFrameMessage({
          name: 'payment',
          href: payUrl
        });
      }
    }

    if (!order) { //widget preview
      const previewLatestOrder = {
        ...latestOrder,
        email
      };

      const updatedOrders = updateLatestOrders(
        latestOrders, onlineGroupId, widgetType, previewLatestOrder, isStoredLatestOrder
      );

      dispatch(setStorage(EStorageKeys.LATEST_ORDERS, updatedOrders, true));
      dispatch(clearBasketItemsInStorage(onlineGroupId, storageBasketItems));
    }

    dispatch(clearQuestionsStatus());
    dispatch(clearAdditionsStatus());
    dispatch(clearShippingMethods());
    dispatch(clearBasket());

    if (isVivaWalletPayment) {
      return handleNavigate(WAITING_FOR_PAYMENT_PATH);
    }

    if (blikCode) {
      return handleNavigate(SUMMARY_PATH);
    }

    if (order?.paymentViewUrl) {
      return handleNavigate(INTERNAL_PAYMENT_LINK_PATH);
    } else {
      return handleNavigate(SUMMARY_PATH);
    }

  } catch (error) {
    if (error instanceof RequestValidationError) {
      dispatch(setSubmitOrderStatus(null));
      throw error;
    } else {
      dispatch(setSubmitOrderError(error.message));
      dispatch(setSubmitOrderStatus(FAILURE));
    }
  }
};
