import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {findLatestOrder, updateLatestOrders} from '@Features/order/orderUtils';
import {getStorageLatestOrders} from '@Features/storage/storageSelectors';
import Stack from '@mui/material/Stack';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useFetchWaitingForOrderCompleted} from '../hooks/fetchHooks/useFetchWaitingForOrderCompleted';
import {useNavigate} from 'react-router-dom';
import {HOME_PATH, SUMMARY_PATH} from '@Utils/routerUtils';
import {useSendCancelWaitingForPayment} from '../hooks/fetchHooks/useSendCancelWaitingForPayment';
import {useSendReinitializeOrderPayment} from '../hooks/fetchHooks/useSendReinitializeOrderPayment';
import {EStorageKeys} from '@Consts/storageConsts';
import {useBackToActivities} from '../hooks/useBackToActivities';
import {setStorage} from '@Features/storage/storageActions';
import WaitingDescription from '@Components/waitingForPayment/WaitingDescription';
import Buttons from '@Components/waitingForPayment/Buttons';
import SnackBarInfo from '@Components/waitingForPayment/SnackBarInfo';

const WaitingForPaymentPage = () => {
  const dispatch = useDispatch();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [reinitializeIsDisabled, setReinitializeIsDisabled] = useState(false);
  const navigate = useNavigate();
  const latestOrders = useSelector(getStorageLatestOrders);
  const {onlineGroupId, widgetType} = useSelector(getConfiguration);
  const latestOrder = findLatestOrder(latestOrders, onlineGroupId, widgetType);
  const isStoredLatestOrder = Boolean(findLatestOrder(latestOrders, onlineGroupId, widgetType));
  const {clearLatestOrder} = useBackToActivities();

  useEffect(() => {
    if (!latestOrder) {
      navigate(SUMMARY_PATH);
    }
  }, []);

  if (latestOrder) {
    const {code, orderId, facilityId} = latestOrder;

    const {
      orderIsCompleted,
      isError: isFetchingOrderError,
      orderIsNotFound
    } = useFetchWaitingForOrderCompleted({code, orderId});

    const {
      sendCancelWaitingForPayment,
      sendCancelIsSuccess,
      sendCancelIsError
    } = useSendCancelWaitingForPayment({orderId, facilityId});

    const {
      sendReinitializeOrderPayment,
      isError: isReinitializeOrderError
    } = useSendReinitializeOrderPayment({orderId, facilityId});

    const blockingSendBtn = () => {
      setReinitializeIsDisabled(true);
      setTimeout(() => {
        setReinitializeIsDisabled(false);
      }, 5000);
    };

    const handleReinitializeOrderPayment = () => {
      setIsSnackbarOpen(true);
      sendReinitializeOrderPayment();
      blockingSendBtn();
    };

    const repeatBtnIsDisabled = reinitializeIsDisabled || orderIsNotFound;
    const closeSnackBar = () => setIsSnackbarOpen(false);
    const isEndSendCancel = sendCancelIsSuccess || sendCancelIsError;

    useEffect(() => {
      if (orderIsCompleted) {
        const updatedOrder = {
          ...latestOrder,
          isVivaWalletPayment: false
        };
        const updatedOrders = updateLatestOrders(
          latestOrders, onlineGroupId, widgetType, updatedOrder, isStoredLatestOrder
        );

        dispatch(setStorage(EStorageKeys.LATEST_ORDERS, updatedOrders, false));
        navigate(SUMMARY_PATH);
      }
    }, [orderIsCompleted]);

    const handleCancel = async () => {
      sendCancelWaitingForPayment();
    };

    useEffect(() => {
      if (isEndSendCancel) {
        clearLatestOrder();
        navigate(HOME_PATH);
      }
    }, [isEndSendCancel]);

    return (
      <Stack
        sx={{
          bgcolor: 'background.main',
          flexDirection: 'column',
          alignItems: 'center',
          width: 1,
          minHeight: 470,
          justifyContent: 'center',
          mx: 'auto',
          px: 1.5
        }}
      >
        <WaitingDescription isFetchingOrderError={isFetchingOrderError} />
        <Buttons
          isFetchingOrderError={isFetchingOrderError}
          handleCancel={handleCancel}
          handleReinitializeOrderPayment={handleReinitializeOrderPayment}
          repeatBtnIsDisabled={repeatBtnIsDisabled}
        />
        <SnackBarInfo
          isSnackbarOpen={isSnackbarOpen}
          closeSnackBar={closeSnackBar}
          isReinitializeOrderError={isReinitializeOrderError}
        />
      </Stack>
    );
  }

  return null;
};

export default WaitingForPaymentPage;
