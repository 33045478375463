import dayjs, {ConfigType, Dayjs, ManipulateType, OpUnitType} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timeZonePlugin from 'dayjs/plugin/timezone';
import isTodayPlugin from 'dayjs/plugin/isToday';
import isTomorrowPlugin from 'dayjs/plugin/isTomorrow';
import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import 'dayjs/locale/cs';
import 'dayjs/locale/sk';
import 'dayjs/locale/it';
import 'dayjs/locale/uk';
import 'dayjs/locale/de';
import 'dayjs/locale/es';

dayjs.extend(utc);
dayjs.extend(timeZonePlugin);
dayjs.extend(isTodayPlugin);
dayjs.extend(isTomorrowPlugin);

export const getDate = (date: ConfigType) => dayjs(date);

export const getTimezoneDate = (date: Dayjs | Date | string | null, timezone: string, keepLocalTime?: boolean) => {
  if (typeof date === 'string') {
    return dayjs(date).tz(timezone, keepLocalTime);
  }

  if (date instanceof Date) {
    return dayjs(date).tz(timezone, keepLocalTime);
  }

  if (date) {
    return date.tz(timezone, keepLocalTime);
  }

  return dayjs().tz(timezone, keepLocalTime);
};

export const getTimezoneDateOrNull = (date: Dayjs | Date | string | null | undefined, timezone: string) => {
  if (typeof date === 'string') {
    return dayjs(date).tz(timezone);
  }

  if (date instanceof Date) {
    return dayjs(date).tz(timezone);
  }

  if (date) {
    return date.tz(timezone);
  }

  return null;
};

export const isTomorrow = (date: Dayjs) => date.isTomorrow();

export const isToday = (date: Dayjs, timezone: string) =>
  date.format('YYYY-MM-DD') === dayjs().tz(timezone).format('YYYY-MM-DD');

export const isSameDate = (firstDate: Dayjs, secondDate: ConfigType, unit: OpUnitType = 'day') =>
  firstDate.isSame(secondDate, unit);

export const getMonth = (date: Dayjs) => date.month();

export const getFormattedDate = (date: Dayjs, template?: string) => date.format(template);

export const getDaysInMonth = (calendarDay?: Dayjs | null) => (calendarDay ? calendarDay.daysInMonth() : null);

export const getFirstOfMonth = (date: Dayjs) => date.startOf('month');

export const addToDate = (date: Dayjs, amount: number, unit: ManipulateType) => date.add(amount, unit);

export const substructFromDate = (date: Dayjs, amount: number, unit: ManipulateType) => date.subtract(amount, unit);

export const getStartOfYesterday = (timezone: string) => dayjs().tz(timezone).subtract(1, 'day').startOf('day');

export const isAfter = (date: Dayjs, dateToCompare: ConfigType, unit: OpUnitType = 'day') =>
  date.isAfter(dateToCompare, unit);

export const isBefore = (date: Dayjs, dateToCompare: ConfigType, unit: OpUnitType = 'day') =>
  date.isBefore(dateToCompare, unit);
