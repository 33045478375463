import {CellData} from '@Components/calendar/CalendarCell';
import {ECheckoutViewsTypes} from '@Utils/checkoutViews/checkoutViewsConsts';
import {isDayOffer, isGroupOfDates, isHotelNarrowDate} from '@Utils/typeGuards';
import {getTimezoneDate, isToday} from '@Utils/dayjs/dayjsUtils';
import {useSelector} from 'react-redux';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {ECurrency} from '@Consts/apiGlobals';
import {IDatesGroupMetaData} from '@Components/checkoutPages/admissionRealization/types';
import {Dayjs} from 'dayjs';

type Props = {
  cellData?: CellData;
  calendarDay: Dayjs | null;
  isCommonPrice?: boolean;
  showPrices?: boolean;
}

type CalendarCell = {
  isSelectedStart?: boolean;
  isSelectedMiddle?: boolean;
  isSelectedEnd?: boolean;
  isDisabled: boolean;
  isSelected: boolean;
  isToday: boolean;
  date: string;
  showPrices?: boolean;
  isBargainPrice?: boolean;
  isPriceNotFixed?: boolean;
  totalPrice?: number;
  currency?: ECurrency;
  showAvailableSeats?: boolean;
  availableSeats?: number | null;
  isHotelNarrowDate?: boolean;
}

export type CalendarCellData = CalendarCell | (CalendarCell & IDatesGroupMetaData) | undefined;

export const useCalendarCell = ({cellData, calendarDay, showPrices}: Props): CalendarCellData => {
  const timezone = useSelector(getFacilityTimezone);

  if (!cellData || !calendarDay || !timezone) {
    return;
  }

  if (isDayOffer(cellData)) {
    return {
      isToday: isToday(calendarDay, timezone),
      isDisabled: !cellData.isAvailable,
      date: cellData.originalDate,
      isBargainPrice: cellData.isBargainPrice,
      isPriceNotFixed: !cellData.isCommonVariantsPrice,
      currency: cellData.currency,
      isSelected: cellData.isSelected,
      showPrices: showPrices && cellData.isAvailable,
      totalPrice: cellData.totalPrice
    };
  }

  if (isGroupOfDates(cellData)) {
    const {
      selectionRangeStart,
      selectionRangeMiddle,
      selectionRangeEnd,
      canShowItemsLeft,
      isOutOfLimit = true,
      variantsCostsAreValid,
      type
    } = cellData.metaData;

    const isDisabled = isOutOfLimit || !variantsCostsAreValid;
    const showAvailableSeats = canShowItemsLeft && !isDisabled && type !== ECheckoutViewsTypes.TIMES;

    return {
      ...cellData.metaData,
      showPrices: !cellData.metaData.isCommonPrice && !isDisabled,
      isToday: isToday(calendarDay, timezone),
      date: cellData.date,
      isDisabled,
      isSelected: !!(selectionRangeStart || selectionRangeMiddle || selectionRangeEnd),
      showAvailableSeats,
      isSelectedStart: !!(selectionRangeStart && !selectionRangeEnd),
      isSelectedMiddle: !!selectionRangeMiddle,
      isSelectedEnd: !!(selectionRangeEnd && !selectionRangeStart)
    };

  }
  if (isHotelNarrowDate(cellData)) {
    const day = getTimezoneDate(cellData.date, timezone, true);

    return {
      isDisabled: !cellData.isAvaliable,
      isSelected: cellData.isSelected,
      isToday: day ? isToday(day, timezone) : false,
      date: cellData.date,
      isHotelNarrowDate: cellData.isHotelNarrowDate,
      showPrices: false
    };
  }
};
