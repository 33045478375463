import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import locale from '@Utils/locale';
import Select from '@Components/common/form/Select';
import Header from '@Components/common/header/Header';
import {createRange} from '@Utils/utils';
import {getNumberOfDaysFormatted} from '@Utils/format';

const NumberOfDaysSection = ({basketItems, variant, className}) => {
  const dispatch = useDispatch();
  const {numberOfDays} = basketItems[0].selections;
  const {
    numberOfAdmissionDatesMin: min,
    numberOfAdmissionDatesMax: max
  } = variant;

  const options = createRange(min, max)
    .map((number, index) => ({
      value: index + min,
      text: getNumberOfDaysFormatted(index + min)
    }));

  function handleChange({target}) {
    const numberOfDays = parseInt(target.value, 10);

    return dispatch(updateBasketItemsSelections(
      basketItems, {dates: null, numberOfDays}
    ));
  }

  if (options?.length === 1) {
    return null;
  }

  return (
    <div className={className}>
      <Header>
        {locale.translate('chooseNumberOfDays')}
      </Header>

      <div className="dl-mt-2">
        <Select
          name="nuberOfDays"
          onChange={handleChange}
          options={options}
          selected={numberOfDays}
        />
      </div>
    </div>
  );
};

NumberOfDaysSection.propTypes = {
  basketItems: PropTypes.array.isRequired,
  variant: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default NumberOfDaysSection;
