import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import TimePickerOption from './TimePickerOption';
import './time-picker.scss';
import TimePickerCellsWrapper from './TimePickerCellsWrapper';

const TimePicker = ({
  options, onChange, value, canShowItemsLeft, showPrices, isTestB
}) => {
  let buttonHeight = 'auto';
  const isWithAllAdditionalInformation = canShowItemsLeft && showPrices;
  const isWithAdditionalInformation = canShowItemsLeft || showPrices;

  if (isWithAllAdditionalInformation) {
    buttonHeight = 76;
  } else if (isWithAdditionalInformation) {
    buttonHeight = 62;
  } else {
    buttonHeight = 54;
  }
  return (
    <TimePickerCellsWrapper showPrices={showPrices} canShowItemsLeft={canShowItemsLeft} isTestB={isTestB}>
      {
        options.map(option => (
          <TimePickerOption
            key={option.originalDate}
            option={option}
            value={value}
            onChange={onChange}
            canShowItemsLeft={canShowItemsLeft}
            showPrices={showPrices}
            buttonHeight={buttonHeight}
            isWithAllAdditionalInformation={isWithAllAdditionalInformation}
            isWithAdditionalInformation={isWithAdditionalInformation}
          />
        ))
      }
    </TimePickerCellsWrapper>
  );
};

TimePicker.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  canShowItemsLeft: PropTypes.bool,
  showPrices: PropTypes.bool,
  isTestB: PropTypes.bool
};

export default TimePicker;
