import React from 'react';
import locale from '@Utils/locale';
import Box from '@mui/material/Box';
import {DefaultTimeText, TextSoldOut} from './TimePickerComponents';
import {getFormattedDate} from '@Utils/dayjs/dayjsUtils';
import {COLON_HOUR_FORMAT} from '@Utils/dayjs/const';
import {Dayjs} from 'dayjs';

type Props = {
    dateWithTimezone: Dayjs;
    isSoldOut: boolean,
    isOutOfLimit: boolean
}

const DefaultTimePickerCell = ({dateWithTimezone, isSoldOut, isOutOfLimit}: Props) => (
  <Box>
    <DefaultTimeText isDisabled={isOutOfLimit}>
      {getFormattedDate(dateWithTimezone, COLON_HOUR_FORMAT)}
    </DefaultTimeText>
    {
      isSoldOut && (
        <TextSoldOut>
          {locale.translate('isSoldOut')}
        </TextSoldOut>
      )
    }
  </Box>
);

export default DefaultTimePickerCell;
