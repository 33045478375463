import {EPaymentMethod} from '@Components/checkout/types';
import {IShippingMethod, PaymentType} from '@Consts/apiGlobals';
import {EWidgetType} from '@Consts/globals';
import {IBasketItem} from '@Consts/types';
import {getBasketValue} from './basket';
import {getBasketItemPrice} from './basketItemPrice';
import {getFormattedPrice} from './format';
import locale from './locale';
import {isWidgetBusiness, isWidgetInternal, isWidgetInternalPreview, isWidgetPreview} from './widgetType';

export const getCheckoutButtonLabel = (
  basketItems: IBasketItem[],
  widgetType: EWidgetType,
  paymentType?: PaymentType | null,
  shippingMethod?: IShippingMethod | null,
  paymentMethod?: EPaymentMethod
) => {
  const basketValue = getBasketValue(basketItems, true);
  const basketItemPrice = getBasketItemPrice(basketItems[0], false);
  const basketCurrency = basketItemPrice.currency;
  const withoutAmount = !basketValue || paymentType === 'for_used';
  const shippingPrice = shippingMethod?.price?.amount || 0;

  const amountToPay = (!withoutAmount || shippingPrice) && basketCurrency ?
  `${getFormattedPrice(basketValue + shippingPrice)} ${locale.translate(`${basketCurrency}`)}` : null;

  if (paymentMethod === EPaymentMethod.BLIK && amountToPay) {
    return `${locale.translate('paymentWithBlik')} (${amountToPay})`;
  }

  const defaultText = amountToPay ?
    `${locale.translate('goToThePayment')} (${amountToPay})` :
    locale.translate('realiseOrder');

  if (isWidgetInternal(widgetType) || isWidgetInternalPreview(widgetType)) {
    return amountToPay ? locale.translate('generateWithAmount', {amountToPay}) :
      locale.translate('generate');
  }

  if (isWidgetPreview(widgetType)) {
    return locale.translate('generateExampleOrder');
  }

  if (isWidgetBusiness(widgetType)) {
    if (paymentType === 'delayed') {
      const text = locale.translate('buyWithoutPayment');

      return amountToPay ? `${text} (${amountToPay})` : text;
    }

    if (paymentType === 'for_used') {
      return locale.translate('doOrder');
    }

    if (paymentType === 'on_site') {
      return locale.translate('doReservation');
    }

    return defaultText;
  }

  return defaultText;
};
