import React from 'react';
import {Dayjs} from 'dayjs';
import {CalendarCellData} from '@Utils/hooks/useCalendarCell';
import {DefaultTextDay, TextSoldOut} from './CalendarComponents';
import locale from '@Utils/locale';
import Box from '@mui/material/Box';

type Props = {
    calendarDay: Dayjs,
    isDisabled: boolean,
    data: CalendarCellData
}

const DefaultCalendarCell = ({calendarDay, isDisabled, data}: Props) => (
  <Box>
    <DefaultTextDay isDisabled={isDisabled} isToday={data?.isToday}>
      {calendarDay.date()}
    </DefaultTextDay>
    {
      !!data && isDisabled && (
        <TextSoldOut>
          {locale.translate('isSoldOut')}
        </TextSoldOut>
      )
    }
  </Box>
);

export default DefaultCalendarCell;
