import {FAILURE, IN_PROGRESS, SUCCESS} from '@Consts/status';
import {getFacility} from '@Features/facility/facilitySelectors';
import {apiGet} from '@Utils/api/api';
import locale from '@Utils/locale';
import {getConfiguration} from '../configuration/configurationSelectors';
import {getActivitiesEndpoint, getProcessedActivities} from './activitiesUtils';

export const SET_ACTIVITIES_STATUS = 'SET_ACTIVITIES_STATUS';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const SET_ACTIVITIES_ADDITIONS = 'SET_ACTIVITIES_ADDITIONS';
export const SET_ACTIVITIES_QUESTIONS = 'SET_ACTIVITIES_QUESTIONS';
export const SET_ACTIVITIES_LOCALIZATION = 'SET_ACTIVITIES_LOCALIZATION';

export const setActivitiesLocalization = locale => ({
  type: SET_ACTIVITIES_LOCALIZATION,
  payload: {locale}
});

export const setActivitiesStatus = status => ({
  type: SET_ACTIVITIES_STATUS,
  payload: {status}
});

export const setActivities = activities => ({
  type: SET_ACTIVITIES,
  payload: {activities}
});

export const setActivityAdditions = (activityId, additions) => ({
  type: SET_ACTIVITIES_ADDITIONS,
  payload: {activityId, additions}
});

export const setActivityQuestions = (activityId, questions) => ({
  type: SET_ACTIVITIES_QUESTIONS,
  payload: {activityId, questions}
});

export const fetchActivities = () => async (dispatch, getState) => {
  const configuration = getConfiguration(getState());
  const facility = getFacility(getState());
  const {timezone} = facility;

  dispatch(setActivitiesStatus(IN_PROGRESS));

  try {
    const activities = await apiGet(getActivitiesEndpoint(configuration), configuration);
    const processedActivities = getProcessedActivities(activities, timezone);

    dispatch(setActivitiesLocalization(locale.language));
    dispatch(setActivities(processedActivities));
    dispatch(setActivitiesStatus(SUCCESS));

    return processedActivities;
  } catch (error) {
    dispatch(setActivitiesStatus(FAILURE));

    throw error;
  }
};
