import React, {useEffect, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import ActivitiesView from './views/ActivitiesView';
import Footer from './components/footer/Footer';
import BusinessFooter from './components/footer/BusinessFooter';
import Navigation from './components/navigation/Navigation';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {isWidgetBusiness, isWidgetOnlineClient} from './utils/widgetType';
import pubsub from './utils/pubsub';
import {EVENT_INITIALIZE, EVENT_LOADED_WITH_GROUP_ID} from './utils/events';
import {applyStyles} from './utils/styles';
import {
  CHECKOUT_PATH,
  HOME_PATH, SUMMARY_PATH,
  INTERNAL_PAYMENT_LINK_PATH,
  WAITING_FOR_PAYMENT_PATH
} from './utils/routerUtils';
import facebookPixel from './utils/facebookPixel';
import CheckoutView from './views/CheckoutView';
import {parseLanguage} from '@Utils/eventsUtils';
import locale from '@Utils/locale';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import InternalPaymentView from '@Components/linkToPayment/InternalPaymentView';

import {
  RouterProvider,
  Route,
  createRoutesFromElements,
  createHashRouter,
  Outlet
} from 'react-router-dom';
import EntryHandler from '@Components/handlers/EntryHandler';
import SummaryPage from './pages/summaryPage/SummaryPage';
import {SummaryDataProvider} from '@Contexts/SummaryDataProvider';
import WaitingForPaymentPage from './pages/WaitingForPaymentPage';
import {ErrorBoundary} from '@Components/errorBoundary';

const Entry = ({facility}) => {
  useEffect(stylesEffect, []);
  useEffect(initializePixelEventsEffect, []);
  useEffect(initializeAmplitudeEventsEffect, []);
  const [theme, setTheme] = useState(createTheme({}));
  const {
    facilityId,
    widgetType,
    parentUrl,
    groupId,
    companyUserId,
    companyId,
    userName,
    companyName,
    isModal,
    parentReferrer
  } = useSelector(getConfiguration);
  const {name: facilityName, widgetCustomization} = facility;

  useEffect(() => {
    if (!isModal) {
      document.body.style.overflow = 'hidden';
    }
  }, [isModal]);

  function handleThemeChange(newTheme) {
    setTheme(newTheme);
  }

  function stylesEffect() {
    pubsub.trigger(EVENT_INITIALIZE, {
      widgetType,
      facilityId,
      facilityName,
      url: parentUrl,
      companyUserId,
      companyId,
      userName,
      companyName,
      language: parseLanguage(locale.locale),
      parentReferrer
    });

    applyStyles(widgetCustomization, handleThemeChange);
  }

  function initializePixelEventsEffect() {
    facebookPixel.postViewContent(facility);
  }

  function initializeAmplitudeEventsEffect() {
    if (groupId) {
      pubsub.trigger(
        EVENT_LOADED_WITH_GROUP_ID,
        {
          facilityId,
          facilityName,
          groupId
        }
      );
    }
  }

  const router = createHashRouter(createRoutesFromElements(
    <>
      <Route
        element={
          <>
            <Navigation />
            <EntryHandler />
            <Outlet />
          </>}
        errorElement={<ErrorBoundary />}
      >

        {/* todo(mp): merge routes after moving to react-router */}
        <Route index element={<ActivitiesView />} />
        <Route path={HOME_PATH} element={<ActivitiesView />} />
        <Route path={CHECKOUT_PATH} element={<CheckoutView />} />
        <Route path={WAITING_FOR_PAYMENT_PATH} element={<WaitingForPaymentPage />} />
        <Route
          path={SUMMARY_PATH}
          element={
            <SummaryDataProvider>
              <SummaryPage />
            </SummaryDataProvider>
          }
        />
        <Route path={INTERNAL_PAYMENT_LINK_PATH} element={<InternalPaymentView />} />
      </Route>
    </>
  ));

  return (
    <ThemeProvider theme={theme}>
      <div style={{position: 'relative'}}>
        <RouterProvider router={router} />
      </div>

      {
        isWidgetOnlineClient(widgetType) &&
          <Footer facility={facility} />
      }

      {
        isWidgetBusiness(widgetType) &&
          <BusinessFooter facility={facility} />
      }
    </ThemeProvider>
  );
};

Entry.propTypes = {
  facility: PropTypes.object.isRequired
};

export default Entry;
