import dayjs from 'dayjs';
import locale from '@Utils/locale';
import {DASH_DAY_FORMAT, DOT_DAY_FORMAT_REVERSED} from '@Utils/dayjs/const';
import {ECheckoutViewsTypes} from '@Utils/checkoutViews/checkoutViewsConsts';
import {isVariantSingle} from '@Utils/variantType';
import {validateVariantsCosts} from '@Utils/validate';
import {isDateOutOfLimit, dateVariantsHaveResources} from './admissionDatesUtils';
import {updateBargainPrices} from './utils';
import {getFormattedDate, getTimezoneDate} from '@Utils/dayjs/dayjsUtils';

export const validSelectedMonthAndYear = (month, year) =>
  Number.isInteger(month) &&
  Number.isInteger(year) &&
  month >= 0 && month <= 11 &&
  year >= 0;

export const getCurrency = dates => {
  const {variants} = {...dates[0]};

  return variants?.length ? variants[0]?.cost?.currency || variants[0]?.costMin?.currency : null;
};

export const getTotalPrice = (date, itemsVariantsList, itemsData) => {
  const {variants} = date;
  const variantsCosts = itemsVariantsList.map(
    itemVariantId => variants.find(({variantId}) => variantId === itemVariantId)
  );

  return variantsCosts.reduce((totalPrice, variantsCost) => {
    if (!variantsCost) {
      return 0;
    }

    const participantsAmount = itemsData.find(itemData =>
      itemData.variantId === variantsCost.variantId
    )?.participantsAmount;

    const {cost} = variantsCost;

    return totalPrice += cost?.price * participantsAmount;
  }, 0);
};

export const getTakenSeats = (items, itemsVariantsList) =>
  itemsVariantsList.reduce((takenSeats, itemVariant) => {
    const relevantVariantItem = items.find(({variantId}) => variantId === itemVariant);
    const {variant, selections} = relevantVariantItem;
    const {numberOfSeats, type} = variant;

    return isVariantSingle(type) ? takenSeats += numberOfSeats : takenSeats += selections?.numberOfSeats;
  }, 0);

export const getDatesExtended = (
  dates,
  items,
  itemsVariantsList,
  isCommonPrice,
  currency
) => {
  const itemsData = items.map(item => ({
    participantsAmount: (item.selections.numberOfSeats || item.selections.numberOfParticipants) ?? 1,
    variantId: item.variantId
  }));

  const takenSeats = getTakenSeats(items, itemsVariantsList);

  return dates.map(
    date => {
      const {variants, numberOfSeats} = date;
      const totalPrice = getTotalPrice(date, itemsVariantsList, itemsData);

      const isOutOfLimit = isDateOutOfLimit(
        variants, takenSeats, numberOfSeats, items
      );

      return (
        {
          ...date,
          takenSeats,
          totalPrice,
          currency,
          isCommonPrice,
          numberOfSeats,
          isOutOfLimit,
          variants,
          variantsCostsAreValid: validateVariantsCosts(itemsVariantsList, variants)
        }
      );
    }
  );
};

export const getGroupMetaData = (
  group,
  selectionRangeType,
  isCommonPrice,
  currency,
  canShowItemsLeft,
  type
) =>
  group.reduce((metaData, dateExtended, id) => {
    const {
      totalPrice,
      numberOfSeats,
      description,
      variants,
      isOutOfLimit,
      variantsCostsAreValid
    } = dateExtended;
    let availableSeats = metaData?.availableSeats || 0;

    availableSeats += numberOfSeats;

    return {
      ...metaData,
      ...selectionRangeType,
      availableSeats,
      totalPrice: !id || !metaData?.totalPrice || metaData?.totalPrice > totalPrice ? totalPrice : metaData?.totalPrice,
      currency,
      isCommonPrice,
      canShowItemsLeft: dateVariantsHaveResources(variants) ? false : canShowItemsLeft,
      description,
      variants,
      variantsCostsAreValid,
      isOutOfLimit: id && !metaData?.isOutOfLimit ? false : isOutOfLimit,
      isPriceNotFixed: id && (metaData?.isPriceNotFixed || metaData?.totalPrice !== totalPrice) ? true : false,
      type
    };
  }, {});

export const convertGroupsToArray = datesGroups =>
  Object.keys(datesGroups).map(
    key =>
      ({
        date: key,
        group: datesGroups[key].group,
        metaData: datesGroups[key].metaData
      })
  );

export const getSelectionRangeType = (dayKey, selectedDates) => {
  const startDate = dayjs(selectedDates[0]?.date);
  const endDate = dayjs(selectedDates[selectedDates.length - 1]?.date);
  const selectionRangeStart = dayjs(dayKey).isSame(startDate);
  const selectionRangeEnd = dayjs(dayKey).isSame(endDate);
  const selectionRangeMiddle = dayjs(dayKey).isAfter(startDate) && dayjs(dayKey).isBefore(endDate);

  return {
    selectionRangeStart,
    selectionRangeMiddle,
    selectionRangeEnd
  };
};

export const getDatesGroupedByDay = (
  datesExtended,
  selectedDates,
  isCommonPrice,
  currency,
  canShowItemsLeft,
  type,
  timezone
) => {
  const daysKeys = datesExtended.map(({date}) =>
    getFormattedDate(getTimezoneDate(date, timezone), DASH_DAY_FORMAT)
  );
  const daysKeysSet = [...new Set(daysKeys)];
  const datesGrouped = daysKeysSet.reduce((datesGrouped, dayKey) => {
    const group = datesExtended.filter(
      ({date}) =>
        getFormattedDate(getTimezoneDate(date, timezone), DASH_DAY_FORMAT) === dayKey
    );

    const selectionRangeType = selectedDates?.length && getSelectionRangeType(dayKey, selectedDates);
    const metaData = getGroupMetaData(group, selectionRangeType, isCommonPrice, currency, canShowItemsLeft, type);

    datesGrouped[dayKey] = {group, metaData};

    return datesGrouped;
  }, {});

  return updateBargainPrices(convertGroupsToArray(datesGrouped));
};

export const validMultidayRange = validationData => {
  const {datesGroupedByDay, selectedDatesGroupIndex, numberOfDays} = validationData;
  const datesGroupedChunk = datesGroupedByDay.slice(
    selectedDatesGroupIndex, selectedDatesGroupIndex + numberOfDays
  );

  if (datesGroupedChunk.length < numberOfDays) {
    return false;
  }

  const isMultidayValid = datesGroupedChunk.reduce((isMultidayValid, dayGroup, index) => {
    const {metaData} = dayGroup;
    const {isOutOfLimit, variantsCostsAreValid} = metaData;

    if (isOutOfLimit || !variantsCostsAreValid) {
      return false;
    }

    if (index > 0) {
      const {date: currentGroupDate} = dayGroup;
      const {date: previousGroupDate} = datesGroupedChunk[index - 1];
      const expectedPreviousGroupDate = dayjs(currentGroupDate).subtract(1, 'days');
      const isPreviousDateValid = expectedPreviousGroupDate.isSame(dayjs(previousGroupDate), 'day');

      if (!isPreviousDateValid) {
        isMultidayValid = false;
      }
    }

    return isMultidayValid;
  }, true);

  return isMultidayValid;
};

export const validateSelection = validationData => {
  const {
    selectedDatesGroupIndex,
    numberOfDays,
    selectedDate,
    selectedDatesGroupedByDay
  } = validationData;

  const date = selectedDate?.format(DOT_DAY_FORMAT_REVERSED);
  const notAvaliableError = locale.translate('selectedDateIsNotAvailable', {date});
  const noSeatsError = locale.translate('hasNoSeatsForGivenDate', {date});
  const soldOutError = locale.translate('givenDateIsSoldOut', {date});
  const multidayRangeError = locale.translate('multidayRangeInvalid');

  if (selectedDatesGroupIndex === -1) {
    return {validationError: notAvaliableError};
  }

  const {metaData} = selectedDatesGroupedByDay;
  const {availableSeats, isOutOfLimit, type, variantsCostsAreValid} = metaData;

  if (!variantsCostsAreValid) {
    return {validationError: notAvaliableError};
  }

  if (type !== ECheckoutViewsTypes.TIMES && isOutOfLimit) {
    if (availableSeats > 0) {
      return {validationError: noSeatsError};
    }
    return {validationError: soldOutError};
  }

  if (numberOfDays > 1) {
    const isMultidayRangeValid = validMultidayRange(validationData);

    if (!isMultidayRangeValid) {
      return {validationError: multidayRangeError};
    }
  }

  return {
    validationError: null
  };
};

export const getDatesIdxWithMinPrice = dates => dates.reduce((datesWithMinPrice, date, index) => {
  const {totalPrice, isOutOfLimit} = date;
  const minPrice = datesWithMinPrice[0]?.totalPrice;

  if (isOutOfLimit) {
    return datesWithMinPrice;
  }

  if (datesWithMinPrice.length < 1 || totalPrice === minPrice) {
    return datesWithMinPrice.concat({index, totalPrice});
  }

  if (totalPrice < minPrice) {
    return [{index, totalPrice}];
  }

  return datesWithMinPrice;
}, []).map(({index}) => index);
