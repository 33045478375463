import React, {SyntheticEvent} from 'react';
import {InvoiceTogglerProps} from './types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import locale from '@Utils/locale';
import {useDispatch, useSelector} from 'react-redux';
import {isWidgetInternal, isWidgetInternalPreview} from '@Utils/widgetType';
import {getWidgetType} from '@Features/configuration/configurationSelectors';
import {setStorage} from '@Features/storage/storageActions';
import {useFormContext} from 'react-hook-form';

const InvoiceToggler = ({sendInvoice, saveInStorage, styles}: InvoiceTogglerProps) => {
  const widgetType = useSelector(getWidgetType);
  const {setValue} = useFormContext();
  const togglerLabel = isWidgetInternal(widgetType) || isWidgetInternalPreview(widgetType) ?
    locale.translate('issueAnInvoice') : locale.translate('iWantInvoice');

  const dispatch = useDispatch();

  function handleFormToggle(event: SyntheticEvent<Element, Event>, checked: boolean) {
    setValue('sendInvoice', checked);

    if (saveInStorage) {
      dispatch(setStorage('sendInvoice', checked, true));
    }
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={styles}
    >
      <FormControlLabel
        onChange={handleFormToggle}
        control={<Switch checked={sendInvoice} />}
        label={togglerLabel}
      />
    </Stack>
  );
};

export default InvoiceToggler;
